import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Button, Form, Input, InputNumber, notification, Collapse } from "antd";
import {
  getAllMentorDetails,
  getAllBankDetails,
  createMentorPaymentDetail,
  updateMentorPaymentDetail,
} from "../../../redux/actions/mentorAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useForm } from "antd/lib/form/Form";

const { Panel } = Collapse;

const AddButton = styled(Button)`
  background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 3px;
  color: white;
  width: 100%;
  height: 35px;
  margin-top: 30px;
  &:hover {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat;
    color: white;
  }
`;
const StyledInput = styled(Input)`
  font-size: 13px;
  margin-bottom: 5px;
`;
const StyledInputNumber = styled(InputNumber)`
  font-size: 13px;
  margin-bottom: 5px;
  width: 100%;
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input-wrap {
    height: 27px;
  }
`;

const StyledForm = styled(Form)``;

const AddBankingDetails = (props: any) => {
  const { allMentors, editItem, setVisible } = props;
  const dispatch = useDispatch();
  const { banks, mentorIdForPayment } = useSelector((state: RootState) => state.mentors);
  const [form] = useForm();

  useEffect(() => {
    dispatch(getAllBankDetails({}));
  }, []);

  const handleAddPaymentDetails = (values: any) => {
    const data = values;
    data["mentorId"] = mentorIdForPayment ? mentorIdForPayment.id : editItem.id;

    dispatch(
      createMentorPaymentDetail({
        data,
        onSuccess: () => {
          setVisible(false);
          notification.success({
            message: "Success",
            description: "Payment Detail has been successfully created.",
            duration: 2,
          });
          dispatch(
            getAllMentorDetails({
              params: {
                PageNumber: allMentors.data.pageNumber,
                pageSize: allMentors.data.pageSize,
              },
            })
          );
        },
      })
    );
  };

  const handleUpdateBankingDetails = (values: any) => {
    const data = values;

    if (editItem && editItem.paymentDetails && editItem.paymentDetails.id) {
      data["id"] = editItem.paymentDetails.id;
      data["paypalURL"] = editItem.paymentDetails.paypalURL || null;
      dispatch(
        updateMentorPaymentDetail({
          data,
          onSuccess: () => {
            setVisible(false);
            notification.success({
              message: "Success",
              description: "Payment Detail has been successfully updated.",
              duration: 2,
            });
            dispatch(
              getAllMentorDetails({
                params: {
                  PageNumber: allMentors.data.pageNumber,
                  pageSize: allMentors.data.pageSize,
                },
              })
            );
          },
        })
      );
    } else {
      handleAddPaymentDetails(values);
    }
  };

  const handleUpdatePayPalDetails = (values: any) => {
    const data = values;

    if (editItem && editItem.paymentDetails && editItem.paymentDetails.id) {
      data["id"] = editItem.paymentDetails.id || null;
      data["bank"] = editItem.paymentDetails.bank || null;
      data["accountNumber"] = editItem.paymentDetails.accountNumber || null;
      data["accountName"] = editItem.paymentDetails.accountName || null;
      data["ifscCode"] = editItem.paymentDetails.ifscCode || null;
      data["branchCode"] = editItem.paymentDetails.branchCode || null;
      data["branch"] = editItem.paymentDetails.branch || null;
      dispatch(
        updateMentorPaymentDetail({
          data,
          onSuccess: () => {
            setVisible(false);
            notification.success({
              message: "Success",
              description: "Payment Detail has been successfully updated.",
              duration: 2,
            });
            dispatch(
              getAllMentorDetails({
                params: {
                  PageNumber: allMentors.data.pageNumber,
                  pageSize: allMentors.data.pageSize,
                },
              })
            );
          },
        })
      );
    } else {
      handleAddPaymentDetails(values);
    }
  };

  return (
    <Collapse accordion>
      <Panel header="Banking Details" key="1">
        <StyledForm
          name="basic"
          layout="vertical"
          form={form}
          onFinish={editItem ? handleUpdateBankingDetails : handleAddPaymentDetails}
        >
          <Form.Item
            name="accountName"
            label="Account Name"
            initialValue={
              editItem && editItem.paymentDetails ? editItem.paymentDetails.accountName : ""
            }
          >
            <StyledInput placeholder="Account Name" />
          </Form.Item>

          <Form.Item
            name="accountNumber"
            label="Account Number"
            initialValue={
              editItem && editItem.paymentDetails ? editItem.paymentDetails.accountNumber : ""
            }
          >
            <StyledInput placeholder="Account Number" />
          </Form.Item>

          <Form.Item
            name="bank"
            label="Bank Name"
            initialValue={editItem && editItem.paymentDetails ? editItem.paymentDetails.bank : ""}
          >
            <StyledInput placeholder="Bank Name" />
          </Form.Item>

          <Form.Item
            name="branch"
            label="Branch"
            initialValue={editItem && editItem.paymentDetails ? editItem.paymentDetails.branch : ""}
          >
            <StyledInput placeholder="Branch" />
          </Form.Item>

          <Form.Item
            name="branchCode"
            label="Branch Code"
            initialValue={
              editItem && editItem.paymentDetails ? editItem.paymentDetails.branchCode : ""
            }
          >
            <StyledInput placeholder="Branch Code" />
          </Form.Item>

          <Form.Item
            name="ifscCode"
            label="IFSC Code"
            initialValue={
              editItem && editItem.paymentDetails ? editItem.paymentDetails.ifscCode : ""
            }
          >
            <StyledInput placeholder="IFSC Code" />
          </Form.Item>
          <AddButton htmlType="submit">{editItem ? "Update" : "Create"}</AddButton>
        </StyledForm>
      </Panel>
      <Panel header="PayPal Details" key="2">
        <StyledForm
          name="basic"
          layout="vertical"
          form={form}
          onFinish={editItem ? handleUpdatePayPalDetails : handleAddPaymentDetails}
        >
          <Form.Item
            name="paypalURL"
            label="PayPal URL"
            initialValue={
              editItem && editItem.paymentDetails ? editItem.paymentDetails.paypalURL : ""
            }
          >
            <StyledInput placeholder="PayPal URL" />
          </Form.Item>
          <AddButton htmlType="submit">{editItem ? "Update" : "Create"}</AddButton>
        </StyledForm>
      </Panel>
    </Collapse>
  );
};
export default AddBankingDetails;
