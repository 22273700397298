import React from 'react';
import ReactApexChart from 'react-apexcharts';

function ChartComponent() {
    const state = {
        options: {
            chart: {
                id: 'Days',
            },
            xaxis: {
                categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
            },
        },
        series: [
            {
                name: 'Total Project',
                data: [50, 40, 20, 30, 40, 40],
            },
        ],
    };

    return (
        <div style={{ width: '100%', overflow:'hidden' }}>
            <ReactApexChart key="area-chart" options={state.options} series={state.series} type="area" width="100%" height="430" />
        </div>
    );
}

export default ChartComponent;
