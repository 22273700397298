export const timesheetTypes = {
  GETALL_TIMESHEET: "GETALL_TIMESHEET",
  GETALL_TIMESHEET_SUCCESS: "GETALL_TIMESHEET_SUCCESS",

  GETALL_STATUS: "GETALL_STATUS",
  GETALL_STATUS_SUCCESS: "GETALL_STATUS_SUCCESS",

  CREATE_TIMESHEET: "CREATE_TIMESHEET",
  CREATE_TIMESHEET_SUCCESS: "CREATE_TIMESHEET_SUCCESS",

  DELETE_TIMESHEET: "DELETE_TIMESHEET",
  DELETE_TIMESHEET_SUCCESS: "DELETE_TIMESHEET_SUCCESS",

  APPROVE_TIMESHEET: "APPROVE_TIMESHEET",
  APPROVE_TIMESHEET_SUCCESS: "APPROVE_TIMESHEET_SUCCESS",

  TIMESHEET_REQUEST_ERROR: "TIMESHEET_REQUEST_ERROR",
};
