import React, { useContext } from "react";
import { Button, DatePicker, Input, Typography, Form, notification, Popover } from "antd";
import { useForm } from "antd/lib/form/Form";
import styled from "@emotion/styled";
import { approveHourLog, getAllMentorHourLogs } from "../../../redux/actions/mentorHourLogAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useState } from "react";
import { ProjectHourLogContext } from "../../../components/ContextComponent";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const Wrapper = styled.div`
  width: 260px;
`;
const HeaderContainer = styled.div`
  height: 45px;
  background-color: #099dff;
  padding: 10px 0px 0px 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
const BodyContainer = styled.div`
  padding: 15px 15px 15px 15px;
`;

const ApproveButton = styled(Button)`
  background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 3px;
  color: white;
  width: 100%;
  &:hover {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat;
    color: white;
  }
  border: none;
`;
const ApproveHoursPopover = styled(Popover)`
  &.ant-popover-title {
    background: #099dff 0% 0% no-repeat padding-box;
    padding: 10px 16px !important;
    color: #ffffff !important;
  }
`;

const ApproveHours = (props: any) => {
  const { projectId } = props;
  const dispatch = useDispatch();
  const [form] = useForm();
  const [visible, setVisible] = useState(false);
  const { startDate, endDate, searchValue } = useContext(ProjectHourLogContext);

  const approveHours = (values: any) => {
    let data: any = {};
    data["startDate"] = values.date[0].format("YYYY-MM-DD");
    data["endDate"] = values.date[1].format("YYYY-MM-DD");
    data["projectId"] = projectId;
    data["validateCandidate"] = true;
    data["hours"] = 0;

    dispatch(
      approveHourLog({
        data,
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "HourLog has been approved.",
            duration: 2,
          });

          dispatch(
            getAllMentorHourLogs({
              params: {
                StartDate: startDate,
                EndDate: endDate,
                MentorOrCandidateName: searchValue,
              },
            })
          );
          setVisible(false);
          form.resetFields();
        },
      })
    );
  };

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  const content = (
    <Wrapper>
      <HeaderContainer>
        <Text style={{ color: "white" }}>Approve Hours</Text>
      </HeaderContainer>
      <BodyContainer>
        <Form form={form} hideRequiredMark onFinish={approveHours} layout="vertical">
          <Form.Item name="date" label="Select date">
            <RangePicker  format="MM-DD-YYYY"/>
          </Form.Item>
          <ApproveButton htmlType="submit">Approve</ApproveButton>
        </Form>
      </BodyContainer>
    </Wrapper>
  );
  return (
    <ApproveHoursPopover
      overlayClassName="projecthourlog"
      placement="left"
      title=""
      content={content}
      visible={visible}
      onVisibleChange={handleVisibleChange}
      trigger="click"
    >
      <Button
        style={{
          backgroundColor: "#1AA6FF",
          width: "70px",
          color: "white",
          fontSize: "10px",
          height: "26px",
          border: "none",
        }}
        onClick={() => setVisible(true)}
      >
        Approve
      </Button>
    </ApproveHoursPopover>
  );
};
export default ApproveHours;
