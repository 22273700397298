import React from "react";
import styled from "@emotion/styled";
import { Typography, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";

const { Text } = Typography;

const Header = styled.div`
  padding: 30px 35px;
  background: #f9fcff 0% 0% no-repeat padding-box;
`;
const BodyContainer = styled.div`
  padding: 30px 35px;
`;
const StyledInput = styled(Input)`
  height: 35px;
  background: #f9fcff 0% 0% no-repeat padding-box;
  border: 1px solid #d2d2d2;
  &.ant-input[disabled] {
    background: #f9fcff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;
    color: black;
  }
`;

const BankingDetails = (props: any) => {
  const { paymentDetails } = props;
  const [form] = useForm();

  React.useEffect(() => {
    form.setFieldsValue({ accountName: paymentDetails.accountName });
    form.setFieldsValue({ accountNumber: paymentDetails.accountNumber });
    form.setFieldsValue({ bankName: paymentDetails.bankName });
    form.setFieldsValue({ branchName: paymentDetails.branch });
    form.setFieldsValue({ branchCode: paymentDetails.branchCode });
    form.setFieldsValue({ ifscCode: paymentDetails.ifscCode });
  }, [paymentDetails]);

  return (
    <React.Fragment>
      <Header>
        <Text style={{ fontSize: "20px", fontWeight: 500 }}>
          Banking Details
        </Text>
      </Header>
      <BodyContainer>
        <Form layout="vertical" form={form}>
          <Form.Item label="Account Name" name="accountName">
            <StyledInput
              defaultValue={paymentDetails.accountName}
              disabled={true}
            />
          </Form.Item>
          <Form.Item label="Account Number" name="accountNumber">
            <StyledInput
              defaultValue={paymentDetails.accountNumber}
              disabled={true}
            />
          </Form.Item>
          <Form.Item label="Bank Name" name="bankName">
            <StyledInput
              defaultValue={paymentDetails.bankName}
              disabled={true}
            />
          </Form.Item>
          <Form.Item label="Branch Name" name="branchName">
            <StyledInput defaultValue={paymentDetails.branch} disabled={true} />
          </Form.Item>

          <Form.Item label="Branch Code" name="branchCode">
            <StyledInput
              defaultValue={paymentDetails.branchCode}
              disabled={true}
            />
          </Form.Item>
          <Form.Item label="IFSC Code" name="ifscCode">
            <StyledInput
              defaultValue={paymentDetails.ifscCode}
              disabled={true}
            />
          </Form.Item>
        </Form>
      </BodyContainer>
    </React.Fragment>
  );
};
export default BankingDetails;
