import {
  timesheetDetailsError,
  createTimesheetSuccess,
  getAllTimesheetSuccess,
  getAllStatusSuccess,
  approveTimesheetSuccess,
  deleteTimesheetSuccess,
} from "../actions/timesheetAction";
import types from "../types";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import instance from "../../lib/axios/axiosInstance";
import { AxiosResponse } from "axios";
import { notification } from "antd";

function* fetchTimesheets(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/Timesheet/GetAll", action.payload)
    );
    yield put(getAllTimesheetSuccess(response.data));
  } catch (error) {
    yield put(timesheetDetailsError(error));
  }
}

function* fetchStatus(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/Timesheet/GetStatus", action.payload)
    );
    yield put(getAllStatusSuccess(response.data));
  } catch (error) {
    yield put(timesheetDetailsError(error));
  }
}

function* createTimesheet(action: any) {
  try {
    yield call(() => instance.post("/api/v1.1/Timesheet/Create", action.payload.data));
    yield put(createTimesheetSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(timesheetDetailsError(error));
    notification.error({
      message: "Create Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* deleteTimesheet(action: any) {
  try {
    yield call(() => instance.delete("/api/v1.1/Timesheet/Delete", action.payload));
    yield put(deleteTimesheetSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(timesheetDetailsError(error));
    notification.error({
      message: "Delete Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* approveTimesheet(action: any) {
  try {
    yield call(() => instance.get("/api/v1.1/Timesheet/Approve", action.payload));
    yield put(approveTimesheetSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(timesheetDetailsError(error));
    notification.error({
      message: "Approve Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

export function* timesheetSaga() {
  yield takeEvery(types.GETALL_TIMESHEET, fetchTimesheets);
  yield takeEvery(types.GETALL_STATUS, fetchStatus);
  yield takeEvery(types.CREATE_TIMESHEET, createTimesheet);
  yield takeEvery(types.DELETE_TIMESHEET, deleteTimesheet);
  yield takeEvery(types.APPROVE_TIMESHEET, approveTimesheet);
}
