import types from '../types';

const initialState = {
  loading: false,
  allCandidates: [],
  error: null,
  allcandidateMentorDetails:[]
};

const candidateReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.UPDATE_CANDIDATE:
    case types.DELETE_CANDIDATE:
    case types.CREATE_CANDIDATE:
    case types.GETALL_CANDIDATES:
    case types.CANDIDATE_MENTOR_DETAILS:
    case types.CANDIDATE_STATUS_CHANGE:
      return {
        ...state,
        loading: true,
      };
    case types.GETALL_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        allCandidates: action.payload,
        error: null,
      };
    case types.CANDIDATE_MENTOR_DETAILS_SUCCESS:
      return{
        ...state,
        loading:false,
        allcandidateMentorDetails:action.payload,
        error:null,
      };
    case types.CREATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.DELETE_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.UPDATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
          case types.CANDIDATE_STATUSCHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.CANDIDATE_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default candidateReducer;
