import types from "../types";

export function getAllProjects(data: any) {
  return {
    type: types.GETALL_PROJECTS,
    payload: data,
  };
}

export function getAllProjectsSuccess(projects: any) {
  return {
    type: types.GETALL_PROJECTS_SUCCESS,
    payload: projects,
  };
}

export function getAllProjectsByMentorId(data: any) {
  return {
    type: types.GET_PROJECTS_BY_MENTORID,
    payload: data,
  };
}

export function getAllProjectsBymentorIdSuccess(projects: any) {
  return {
    type: types.GET_PROJECTS_BY_MENTORID_SUCCESS,
    payload: projects,
  };
}

export function getProjectStatus(data: any) {
  return {
    type: types.GET_PROJECT_STATUS,
    payload: data,
  };
}

export function getAllProjectStatusSuccess(projects: any) {
  return {
    type: types.GET_PROJECTSTATUS_SUCCESS,
    payload: projects,
  };
}

export function updateProjectStatus(data: any) {
  return {
    type: types.UPDATE_PROJECT_STATUS,
    payload: data,
  };
}

export function updatelProjectStatusSuccess() {
  return {
    type: types.UPDATE_PROJECTSTATUS_SUCCESS,
  };
}

export function getProjectsById(data: any) {
  return {
    type: types.GET_PROJECTS_BYID,
    payload: data,
  };
}

export function getProjectByIdSuccess(projects: any) {
  return {
    type: types.GET_PROJECTSBYID_SUCCESS,
    payload: projects,
  };
}

export function createProject(data: any) {
  return {
    type: types.CREATE_PROJECT,
    payload: data,
  };
}

export function createProjectSuccess() {
  return {
    type: types.CREATE_PROJECT_SUCCESS,
  };
}

export function updateProject(data:any) {
  return {
    type: types.UPDATE_PROJECT,
    payload: data,
  };
}

export function updateProjectSuccess() {
  return {
    type: types.UPDATE_PROJECT_SUCCESS,
  };
}

export function deleteProject(data: any) {
  return {
    type: types.DELETE_PROJECT,
    payload: data,
  };
}

export function deleteProjectSuccess() {
  return {
    type: types.DELETE_PROJECT_SUCCESS,
  };
}

export function projectRequestError(data: any) {
  return {
    type: types.PROJECT_REQUEST_ERROR,
    payload: data,
  };
}
