import axios, { AxiosInstance } from 'axios';

const instance: AxiosInstance = axios.create({
    baseURL: '',
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
});

export default instance;
