import types from "../types";

export function getAllTimesheet(data: any) {
  return {
    type: types.GETALL_TIMESHEET,
    payload: data,
  };
}

export function getAllTimesheetSuccess(data: any) {
  return {
    type: types.GETALL_TIMESHEET_SUCCESS,
    payload: data,
  };
}

export function getAllStatus(data: any) {
  return {
    type: types.GETALL_STATUS,
    payload: data,
  };
}

export function getAllStatusSuccess(data: any) {
  return {
    type: types.GETALL_STATUS_SUCCESS,
    payload: data,
  };
}

export function createTimesheet(data: any) {
  return {
    type: types.CREATE_TIMESHEET,
    payload: data,
  };
}

export function createTimesheetSuccess() {
  return {
    type: types.CREATE_TIMESHEET_SUCCESS,
  };
}

export function deleteTimesheet(data: any) {
  return {
    type: types.DELETE_TIMESHEET,
    payload: data,
  };
}

export function deleteTimesheetSuccess() {
  return {
    type: types.DELETE_TIMESHEET_SUCCESS,
  };
}

export function approveTimesheet(data: any) {
  return {
    type: types.APPROVE_TIMESHEET,
    payload: data,
  };
}

export function approveTimesheetSuccess() {
  return {
    type: types.APPROVE_TIMESHEET_SUCCESS,
  };
}

export function timesheetDetailsError(data: any) {
  return {
    type: types.TIMESHEET_REQUEST_ERROR,
    payload: data,
  };
}
