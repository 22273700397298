import styled from "@emotion/styled";
import { AxiosRequestConfig } from "axios";
import { Moment } from "moment";
import React from "react";
import { MentorLogProps } from "../interface";
import CellPopover from "./CellPopover";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import "./contextmenustyle.css";

const ColWrap = styled.div`
  border: 0.5px solid rgba(0, 0, 0, 0.16);
  // border-right: 0.5px solid rgba(0, 0, 0, 0.16);
  padding: 0px;
`;

interface TableColProps {
  mentorLog: Array<MentorLogProps>;
  date: Moment;
}

const TableCol = (props: TableColProps) => {
  const { mentorLog, date } = props;

  return (
    <ColWrap>
      {mentorLog.map((log, index) => (
        <CellPopover key={index} log={log} date={date} />
      ))}
    </ColWrap>
  );
};

export default TableCol;
