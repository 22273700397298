import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getAllUsers } from "../../redux/actions/usersAction";
import { RootState } from "../../redux/store";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Button,
  Input,
  Modal,
  notification,
  Pagination,
  Popconfirm,
  Table,
  Typography,
} from "antd";

import StatusPopover from "./components/StatusPopover";
import UserForm from "./components/UserForm";
import RightHeader from "../../components/RightHeader";
import CustomPagination from "../../components/CustomPagination";
import CustomDrawer from "../../components/CustomDrawer";

const { Text } = Typography;
const { Search } = Input;

const Container: any = styled.div`
  padding: 25px 50px 25px 50px;
`;
const HeaderContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BodyContainer: any = styled.div`
  margin-top: 35px;
  padding: 15px 0px 0px 0px;
`;
const LeftHeader: any = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSearch = styled(Search)`
  .ant-input-wrapper {
    box-shadow: 0px 8px 10px #008ce914;
    width: 280px;
    height: 30px;
    border-radius: 6px;
  }
  .ant-input {
    border: 1px solid #008ce940;
    border-radius: 6px;
    padding-top: 6px;
    color: #434343;
    height: 40px;
  }
  .ant-btn,
  .ant-btn-icon-only,
  .ant-input-search-button {
    border: none;
    box-shadow: none;
    margin-left: 2px;
  }

  .ant-input-group-addon {
    border: 1px solid #008ce940;
    border-left: none;
    border-radius: 6px;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #008ce940 !important ;
    border-left: none !important;
    background-color: white !important;
  }
  .ant-input-group input::placeholder {
    color: #434343;
    opacity: 0.7;
  }
`;
const AddUserButton = styled(Button)`
  background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 8px 10px #008ce914;
  border-radius: 6px;
  height: 40px;
  width: 175px;
  border: none;
  color: white;
  &:hover {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
`;
const EditContainer = styled(FormOutlined)`
  background: #e3f4ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 18px #008ce912;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #008ce9;
  cursor: pointer;
`;
const DeleteContainer = styled(DeleteOutlined)`
  background: #ffe9e9 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 7px #ff00000d;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000;
  cursor: pointer;
`;

const StyledTable = styled(Table)`
  .ant-table-row,
  .ant-table-row-level-0 {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000008;
    // border: 1px solid #008ce933;
  }
  .ant-table-thead > tr > th {
    background: #f9fcff 0% 0% no-repeat padding-box;
    font-size: 16px;
  }
  .ant-table-tbody > tr > td {
    // border-bottom: 1px solid #008ce930;
  }
  .ant-table-row {
    box-shadow: 1px 1px 10px #1c3faa1a !important;
  }
  .ant-table-tbody > tr:last-child {
    & > td {
      border-bottom: none;
    }
  }
`;

function User() {
  const [visible, setVisible] = useState(false);
  const [editData, seteditData] = useState<any>(null);
  const [searchData, setSearchData] = useState<any>("");

  const dispatch = useDispatch();
  const { users, loading } = useSelector((state: RootState) => state.users);

  const onChangeSearch = (e: any) => {
    setSearchData(e.target.value);
  };

  function onConfirm(id: any) {
    const isLastElement = users.data.results.length === 1;
    if (isLastElement && users.data.totalCount === 1) {
      setSearchData("");
    }
    dispatch(
      deleteUser({
        params: { id },
        onSuccess: () => {
          setVisible(false);
          notification.success({
            message: "Success",
            description: "User has been successfully deleted.",
            duration: 2,
          });
          dispatch(
            getAllUsers({
              params: {
                PageNumber:
                  isLastElement && users.data.totalCount > 1
                    ? users.data.pageNumber - 1
                    : users.data.pageNumber,
                pageSize: users.data.pageSize,
                firstName: users.data.totalCount === 1 ? null : searchData,
              },
            })
          );
        },
      })
    );
  }

  const handleTableChange = (current: any, pageSize: any) => {
    dispatch(
      getAllUsers({ params: { pageNumber: current, pageSize: pageSize, Name: searchData } })
    );
  };

  const onSearch = (value: any) => {
    dispatch(getAllUsers({ params: { PageNumber: 1, pageSize: 10, Name: value } }));
  };

  useEffect(() => {
    dispatch(getAllUsers({ params: { PageNumber: 1, pageSize: 10 } }));
  }, [dispatch]);

  useEffect(() => {
    if (searchData === "") {
      dispatch(getAllUsers({ params: { PageNumber: 1, pageSize: 10 } }));
    }
  }, [searchData]);

  const columns = [
    {
      title: "User",
      dataIndex: "firstName",
      key: "name",
      render: (item: any, record: any) => (
        <span>
          {item} {record.middleName} {record.lastName}
        </span>
      ),
    },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone Number", dataIndex: "phone", key: "phone" },
    { title: "Role", dataIndex: "role", key: "role" },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (item: any, record: any) => (
        <div>
          <StatusPopover item={item} record={record} searchData={searchData} />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (item: any, record: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <EditContainer
            onClick={() => {
              setVisible(true);
              seteditData(record);
            }}
          >
            <FormOutlined style={{ color: "red" }} />
          </EditContainer>
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => onConfirm(item)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteContainer>
              <DeleteOutlined style={{ color: "blue" }} />
            </DeleteContainer>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const Today = new Date();
  const StringToday = Today.toDateString();

  return (
    <Container>
      <HeaderContainer>
        <LeftHeader>
          <Text style={{ fontSize: "28px", fontWeight: 600, color: "#434343" }}>User</Text>
          <Text style={{ color: "#434343" }}>{StringToday}</Text>
        </LeftHeader>
        <RightHeader />
      </HeaderContainer>
      <BodyContainer>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <StyledSearch
            name="search"
            placeholder="Search by first name"
            onChange={onChangeSearch}
            onSearch={onSearch}
          />
          <AddUserButton
            style={{ paddingLeft: "18px" }}
            onClick={() => {
              setVisible(!visible);
              seteditData(null);
            }}
          >
            + Add User
          </AddUserButton>
        </div>
        <div
          style={{
            border: "1px solid #008ce930",
            boxShadow: "0px 20px 22px #008ce905",
            marginTop: "25px",
          }}
        >
          <StyledTable
            columns={columns}
            dataSource={users.data && users.data && users.data.results}
            pagination={false}
            loading={loading}
          />
        </div>
        <CustomPagination
          size="small"
          total={users && users.data && users.data.totalCount}
          current={users && users.data && users.data.pageNumber}
          defaultPageSize={10}
          defaultCurrent={1}
          showSizeChanger={true}
          onChange={handleTableChange}
        />

        <CustomDrawer
          title=""
          placement="right"
          mask={false}
          onClose={() => setVisible(false)}
          visible={visible}
          width={470}
          destroyOnClose={true}
        >
          <UserForm editData={editData} setVisible={setVisible} searchData={searchData} />
        </CustomDrawer>
      </BodyContainer>
    </Container>
  );
}
export default User;
