export const mentorTypes = {
  GETALL_MENTORS: "GETALL_MENTORS",
  GETALL_MENTORS_SUCCESS: "GETALL_MENTORS_SUCCESS",

  GETALL_BANKS: "GETALL_BANKS",
  GETALL_BANKS_SUCCESS: "GETALL_BANKS_SUCCESS",

  CREATE_MENTOR: "CREATE_MENTOR",
  CREATE_MENTOR_SUCCESS: "CREATE_MENTOR_SUCCESS",

  CREATE_PAYMENT_DETAILS: "CREATE_PAYMENT_DETAILS",
  CREATE_PAYMENTDETAILS_SUCCESS: "CREATE_PAYMENTDETAILS_SUCCESS",

  UPDATE_PAYMENT_DETAILS: "UPDATE_PAYMENT_DETAILS",
  UPDATE_PAYMENTDETAILS_SUCCESS: "UPDATE_PAYMENTDETAILS_SUCCESS",

  UPDATE_MENTOR: "UPDATE_MENTOR",
  UPDATE_MENTOR_SUCCESS: "UPDATE_MENTOR_SUCCESS",

  DELETE_MENTOR: "DELETE_MENTOR",
  DELETE_MENTOR_SUCCESS: "DELETE_MENTOR_SUCCESS",

  MENTOR_REQUEST_ERROR: "MENTOR_REQUEST_ERROR",

  MENTOR_STATUS_CHANGE: "MENTOR_STATUS_CHANGE",
  MENTOR_STATUSCHANGE_SUCCESS: "MENTOR_STATUSCHANGE_SUCCESS",
};
