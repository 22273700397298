import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Typography, Button, Row, Col } from "antd";
import { RootState } from "../../../redux/store";
import { getInvoiceById } from "../../../redux/actions/invoiceAction";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../components/LoadingSpinner";

const { Title, Text } = Typography;

const HeaderWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 10px;
`;
const DetailContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;
const CalculationContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;
const UnderLine = styled("div")`
  height: 2px;
  background: #bebebe 0% 0% no-repeat padding-box;
`;

const InvoiceDetail = (props: any) => {
  const { invoiceID } = props;
  const dispatch = useDispatch();
  const { invoiceByID } = useSelector((state: RootState) => state.invoices);


  useEffect(() => {
    dispatch(getInvoiceById({ params: { id: invoiceID } }));
  }, [invoiceID]);

  return invoiceByID.length === 0 ? (
    <LoadingSpinner margin={400} />
  ) : (
    <React.Fragment>
      <div style={{ padding: "60px 40px 40px 40px", backgroundColor: "#F9FCFF" }}>
        <HeaderWrapper>
          <Title style={{ fontSize: "22px", fontWeight: 600 }}>Invoice</Title>
        </HeaderWrapper>
      </div>

      <Row style={{ padding: "20px 40px" }}>
        <Col span={8}>
          <Wrapper>
            <Text style={{ fontWeight: 600, fontSize: "16px" }}>Mentor</Text>
            <Text>{invoiceByID.data.mentorName}</Text>
          </Wrapper>
        </Col>
        <Col span={9}>
          <Wrapper>
            <Text style={{ fontWeight: 600, fontSize: "16px" }}>Email</Text>
            <Text>{invoiceByID.data.mentorEmail}</Text>
          </Wrapper>
        </Col>
        <Col span={7}>
          <Wrapper>
            <Text style={{ fontWeight: 600, fontSize: "16px" }}>Company</Text>
            <Text>{invoiceByID.data.company}</Text>
          </Wrapper>
        </Col>
      </Row>
      <Row style={{ padding: "20px 40px" }}>
        <Col span={8}>
          <Wrapper>
            <Text style={{ fontWeight: 600, fontSize: "16px" }}>Candidate</Text>
            <Text>{invoiceByID.data.candidateName}</Text>
          </Wrapper>
        </Col>
        <Col span={9}>
          <Wrapper>
            <Text style={{ fontWeight: 600, fontSize: "16px" }}>Invoice Date</Text>
            <Text>{new Date(invoiceByID.data.generateDate).toLocaleDateString()}</Text>
          </Wrapper>
        </Col>
      </Row>
      <div style={{ backgroundColor: "#F9FCFF" }}>
        <Row style={{ padding: "20px 40px" }}>
          <Col span={8}>
            <Text style={{ fontWeight: 600, fontSize: "16px" }}>Service</Text>
          </Col>
          <Col span={8}>
            <Text style={{ fontWeight: 600, fontSize: "16px" }}>Description</Text>
          </Col>
          <Col span={8}>
            <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "10px" }}>
              <Text style={{ fontWeight: 600, fontSize: "16px" }}>HOURS</Text>
              <Text style={{ fontWeight: 600, fontSize: "16px" }}>RATE</Text>
              <Text style={{ fontWeight: 600, fontSize: "16px" }}>AMOUNT</Text>
            </div>
          </Col>
        </Row>
      </div>
      <Row style={{ padding: "20px 40px" }}>
        <Col span={8}>
          <Text>Support</Text>
        </Col>
        <Col span={8}>
          <Text style={{ paddingRight: "25px" }}></Text>
        </Col>
        <Col span={8}>
          <Container>
            <DetailContainer>
              <Text>{invoiceByID.data.totalHours}</Text>
              <Text>${invoiceByID.data.rate}</Text>
              <Text>${invoiceByID.data.totalHours * invoiceByID.data.rate}</Text>
            </DetailContainer>
            <UnderLine></UnderLine>
            <CalculationContainer>
              <Text style={{ fontWeight: 600, fontSize: "16px" }}>Total</Text>
              <Text>${invoiceByID.data.totalHours * invoiceByID.data.rate}</Text>
            </CalculationContainer>
          </Container>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InvoiceDetail;
