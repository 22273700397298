import {
  createUserSuccess,
  deleteUserSuccess,
  getAllUsersSuccess,
  updateUserStatusSuccess,
  updateUserSuccess,
  getIndividualUserSuccess,
  userRequestError,
} from "../actions/usersAction";
import types from "../types";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import { notification } from "antd";
import instance from "../../lib/axios/axiosInstance";
import { AxiosResponse } from "axios";

function* fetchAllUsers(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/User/GetAll", action.payload)
    );
    yield put(getAllUsersSuccess(response.data));
  } catch (error) {
    yield put(userRequestError(error));
  }
}
function* fetchIndividualUser(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/User/GetById", action.payload)
    );
    yield put(getIndividualUserSuccess(response.data));
  } catch (error) {
    yield put(userRequestError(error));
  }
}

function* addUser(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.post("/api/v1.1/User/Create", action.payload.data)
    );
    yield put(createUserSuccess(response.data));
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(userRequestError(error));
    notification.error({
      message: "Create Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* editUser(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.put("/api/v1.1/User/Update", action.payload.data)
    );
    yield put(updateUserSuccess(response.data));
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(userRequestError(error));
    notification.error({
      message: "Update Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* editUserStatus(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.put("/api/v1.1/User/ChangeStatus", action.payload.data)
    );
    yield put(updateUserStatusSuccess(response.data));
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(userRequestError(error));
    notification.error({
      message: "Status Update Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* removeUser(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.delete("/api/v1.1/User/Delete", action.payload)
    );
    yield put(deleteUserSuccess(response.data));
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(userRequestError(error));
    notification.error({
      message: "Delete Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

export function* usersSaga() {
  yield takeEvery(types.GETALL_USERS, fetchAllUsers);
  yield takeEvery(types.GET_INDIVIDUAL_USER, fetchIndividualUser);
  yield takeEvery(types.CREATE_USER, addUser);
  yield takeEvery(types.UPDATE_USER, editUser);
  yield takeEvery(types.UPDATE_USER_STATUS, editUserStatus);
  yield takeEvery(types.DELETE_USER, removeUser);
}
