export const projects = {
  GETALL_PROJECTS: "GETALL_PROJECTS",
  GETALL_PROJECTS_SUCCESS: "GETALL_PROJECTS_SUCCESS",

  GET_PROJECTS_BYID: "GETALL_PROJECTS",
  GET_PROJECTSBYID_SUCCESS: "GET_PROJECTSBYID_SUCCESS",

  GET_PROJECTS_BY_MENTORID: "GET_PROJECTS_BY_MENTORID",
  GET_PROJECTS_BY_MENTORID_SUCCESS: "GET_PROJECTS_BY_MENTORID_SUCCESS",

  GET_PROJECT_STATUS: "GET_PROJECT_STATUS",
  GET_PROJECTSTATUS_SUCCESS: "GET_PROJECTSTATUS_SUCCESS",

  UPDATE_PROJECT_STATUS: "UPDATE_PROJECT_STATUS",
  UPDATE_PROJECTSTATUS_SUCCESS: "UPDATE_PROJECTSTATUS_SUCCESS",

  CREATE_PROJECT: "CREATE_PROJECT",
  CREATE_PROJECT_SUCCESS: "CREATE_PROJECT_SUCCESS",

  UPDATE_PROJECT: "UPDATE_PROJECT",
  UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",

  DELETE_PROJECT: "DELETE_PROJECT",
  DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",

  PROJECT_REQUEST_ERROR: "PROJECT_REQUEST_ERROR",
};
