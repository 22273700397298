import types from '../types';

export function getAllCandidateDetails(data:any) {
  return {
    type: types.GETALL_CANDIDATES,
    payload: data,
  };
}

export function getAllCandidateDetailsSuccess(allCandidatesData:any) {
  return {
    type: types.GETALL_CANDIDATES_SUCCESS,
    payload: allCandidatesData,
  };
}

export function candidateMentorDetails(data:any) {
  return {
    type: types.CANDIDATE_MENTOR_DETAILS,
    payload: data,
  };
}

export function candidateMentorDetailsSuccess(data:any) {
  return {
    type: types.CANDIDATE_MENTOR_DETAILS_SUCCESS,
    payload: data,
  };
}

export function createCandidateDetail(data:any) {
  return {
    type: types.CREATE_CANDIDATE,
    payload: data,
  };
}

export function createCandidateDetailSuccess() {
  return {
    type: types.CREATE_CANDIDATE_SUCCESS,
  };
}

export function deleteCandidateDetail(data:any) {
  return {
    type: types.DELETE_CANDIDATE,
    payload: data,
  };
}

export function deleteCandidateDetailSuccess() {
  return {
    type: types.DELETE_CANDIDATE_SUCCESS,
  };
}

export function editCandidateDetail(data:any) {
  return {
    type: types.UPDATE_CANDIDATE,
    payload: data,
  };
}

export function editCandidateDetailSuccess() {
  return {
    type: types.UPDATE_CANDIDATE_SUCCESS,
  };
}

export function candidateDetailsError(data:any) {
  return {
    type: types.CANDIDATE_REQUEST_ERROR,
    payload: data,
  };
}

export function candidateStatusChange(data:any){
  return{
    type:types.CANDIDATE_STATUS_CHANGE,
    payload:data
  }
}

export function candidateStatusChangeSuccess(){
  return{
    type:types.CANDIDATE_STATUSCHANGE_SUCCESS,
  }
}
