import types from "../types";

const initialState = {
  loading: false,
  users: [],
  error: null,
  individualUser: {},
};

const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GETALL_USERS:
    case types.CREATE_USER:
    case types.UPDATE_USER:
    case types.UPDATE_USER_STATUS:
    case types.DELETE_USER:
    case types.GET_INDIVIDUAL_USER:
      return {
        ...state,
        loading: true,
      };
    case types.GETALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
        error: null,
      };
    case types.GET_INDIVIDUAL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        individualUser: action.payload,
        error: null,
      };
    case types.CREATE_USER_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
    case types.UPDATE_USER_STATUS_SUCCESS:
    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default usersReducer;
