import types from "../types";

const initialState = {
  loading: false,
  allCompanies: [],
  error: null,
  addCompanyModal: false,
  editCompanyModal: false,
  isCompleted: false,
  companyUsers: [],
  companyIdForPaymentDetails: null,
};

const companyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.UPDATE_COMPANY:
    case types.DELETE_COMPANY:
    case types.CREATE_COMPANY:
    case types.GETALL_COMPANIES:
    case types.GET_COMPANY_USERS:
    case types.CREATE_COMPANYPAYMENT_DETAILS:
    case types.UPDATE_COMPANYPAYMENT_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case types.GETALL_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        allCompanies: action.payload,
        error: null,
        isCompleted: true,
      };
    case types.GET_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        companyUsers: action.payload,
        error: null,
      };
    case types.CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        companyIdForPaymentDetails: action.payload,
      };
    case types.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.UPDATE_COMPANY_SUCCESS:
    case types.CREATE_COMPANYPAYMENTDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case types.UPDATE_COMPANYPAYMENTDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.SEND_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ADD_COMPANY_MODAL:
      return {
        ...state,
        addCompanyModal: !state.addCompanyModal,
      };
    case types.EDIT_COMPANY_MODAL:
      return {
        ...state,
        editCompanyModal: !state.editCompanyModal,
      };
    default:
      return {
        ...state,
      };
  }
};

export default companyReducer;
