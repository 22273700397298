import Candidate from "../page/Candidate";
import Company from "../page/Company";
import Home from "../page/Home";
import Mentor from "../page/Mentor";
import Project from "../page/Project";
import TechnologyStack from "../page/TechnologyStack";
import User from "../page/User";
import DashboardIcon from "../image/dashboard.svg";
import CompanyIcon from "../image/building (1).svg";
import TechnologyStackIcon from "../image/Technology stack icon.svg";
import ProjectIcon from "../image/Projects.svg";
import UserIcon from "../image/user (1).svg";
import MentorIcon from "../image/nav-mentor-icon.svg";
import CandidateIcon from "../image/nav-candidate-icon.svg";
import MentorHourLog from "../page/MentorHourLog";
import Timesheets from "../page/Timesheet";
import TimesheetIcon from "../image/timesheets.svg";
import ProjectHourlogIcon from "../image/Project Hourlog.svg";
import InvoiceIcon from "../image/invoice.svg";
import Invoices from "../page/Invoice";
import CandidateDetails from "../page/Candidate/components/CandidateDetails";

export const routes: any = {
  superAdmin: {
    navRoutes: [
      {
        path: "/home",
        exact: true,
        icon: DashboardIcon,
        title: "Dashboard",
        component: Home,
        showInMenu: true,
      },
      {
        path: "/user",
        exact: true,
        icon: UserIcon,
        title: "User",
        component: User,
        showInMenu: true,
      },
      {
        path: "/company",
        exact: true,
        icon: CompanyIcon,
        title: "Company",
        component: Company,
        showInMenu: true,
      },
      {
        path: "/techonlogyStack",
        exact: true,
        icon: TechnologyStackIcon,
        title: "Technology Stack",
        component: TechnologyStack,
        showInMenu: true,
      },
      {
        path: "/mentor",
        exact: true,
        icon: MentorIcon,
        title: "Mentor",
        component: Mentor,
        showInMenu: true,
      },
      {
        path: "/candidate",
        exact: true,
        icon: CandidateIcon,
        title: "Candidate",
        component: Candidate,
        showInMenu: true,
      },
      {
        path: "/candidate/details/:id",
        exact: true,
        title: "Candidate Details",
        component: CandidateDetails,
        showInMenu: false,
      },
      {
        path: "/project",
        exact: true,
        icon: ProjectIcon,
        title: "Project",
        component: Project,
        showInMenu: true,
      },
      // {
      //   path: '/supportRequest',
      //   exact: true,
      //   icon: 'icon-user',
      //   title: 'Support Request',
      //   component: Home,
      //   showInMenu: true,
      // },
      // {
      //   path: '/paymentRequest',
      //   exact: true,
      //   icon: 'icon-user',
      //   title: 'Payment Request',
      //   component: Home,
      //   showInMenu: true,
      // },
    ],
  },
  candidateAdmin: {
    navRoutes: [
      {
        path: "/home",
        exact: true,
        icon: DashboardIcon,
        title: "Dashboard",
        component: Home,
        showInMenu: true,
      },
      {
        path: "/company",
        exact: true,
        icon: CompanyIcon,
        title: "Company",
        component: Company,
        showInMenu: true,
      },
      {
        path: "/candidate",
        exact: true,
        icon: CandidateIcon,
        title: "Candidate",
        component: Candidate,
        showInMenu: true,
      },
      {
        path: "/candidate/details/:id",
        exact: true,
        title: "Candidate Details",
        component: CandidateDetails,
        showInMenu: false,
      },
      {
        path: "/projectHourLog",
        exact: true,
        icon: ProjectHourlogIcon,
        title: "Project HourLog",
        component: MentorHourLog,
        showInMenu: true,
      },
      {
        path: "/timesheet",
        exact: true,
        icon: TimesheetIcon,
        title: "Timesheet",
        component: Timesheets,
        showInMenu: true,
      },
      // {
      //   path: '/candidateHourLog',
      //   exact: true,
      //   icon: 'icon-dashboard',
      //   title: 'Candidate HourLog',
      //   component: Home,
      //   showInMenu: true,
      // },
      // {
      //   path: '/supportRequest',
      //   exact: true,
      //   icon: 'icon-user',
      //   title: 'Support Request',
      //   component: Home,
      //   showInMenu: true,
      // },
      // {
      //   path: '/paymentRequest',
      //   exact: true,
      //   icon: 'icon-user',
      //   title: 'Payment Request',
      //   component: Home,
      //   showInMenu: true,
      // },
    ],
  },
  mentorAdmin: {
    navRoutes: [
      {
        path: "/home",
        exact: true,
        icon: DashboardIcon,
        title: "Dashboard",
        component: Home,
        showInMenu: true,
      },
      {
        path: "/company",
        exact: true,
        icon: CompanyIcon,
        title: "Company",
        component: Company,
        showInMenu: true,
      },
      {
        path: "/technololgyStack",
        exact: true,
        icon: TechnologyStackIcon,
        title: "Technology Stack",
        component: TechnologyStack,
        showInMenu: true,
      },
      {
        path: "/mentor",
        exact: true,
        icon: MentorIcon,
        title: "Mentor",
        component: Mentor,
        showInMenu: true,
      },
      {
        path: "/project",
        exact: true,
        icon: ProjectIcon,
        title: "Project",
        component: Project,
        showInMenu: true,
      },
      {
        path: "/projectHourLog",
        exact: true,
        icon: ProjectHourlogIcon,
        title: "Project HourLog",
        component: MentorHourLog,
        showInMenu: true,
      },
      {
        path: "/timesheet",
        exact: true,
        icon: TimesheetIcon,
        title: "Timesheet",
        component: Timesheets,
        showInMenu: true,
      },

      // {
      //   path: '/supportRequest',
      //   exact: true,
      //   icon: 'icon-user',
      //   title: 'Support Request',
      //   component: Home,
      //   showInMenu: true,
      // },
    ],
  },
  account: {
    navRoutes: [
      {
        path: "/home",
        exact: true,
        icon: DashboardIcon,
        title: "Dashboard",
        component: Home,
        showInMenu: true,
      },
      {
        path: "/projectHourLog",
        exact: true,
        icon: ProjectHourlogIcon,
        title: "Project HourLog",
        component: MentorHourLog,
        showInMenu: true,
      },
      {
        path: "/timesheet",
        exact: true,
        icon: TimesheetIcon,
        title: "Timesheet",
        component: Timesheets,
        showInMenu: true,
      },
      {
        path: "/mentor",
        exact: true,
        icon: MentorIcon,
        title: "Mentor",
        component: Mentor,
        showInMenu: true,
      },
      {
        path: "/invoice",
        exact: true,
        icon: InvoiceIcon,
        title: "Invoice",
        component: Invoices,
        showInMenu: true,
      },
      // {
      //   path: '/candidateHourLog',
      //   exact: true,
      //   icon: 'icon-dashboard',
      //   title: 'Candidate HourLog',
      //   component: Home,
      //   showInMenu: true,
      // },
      // {
      //   path: '/bill',
      //   exact: true,
      //   icon: 'icon-dashboard',
      //   title: 'Bill',
      //   component: Home,
      //   showInMenu: true,
      // },
      // {
      //   path: '/paymentRequest',
      //   exact: true,
      //   icon: 'icon-dashboard',
      //   title: 'Payment Request',
      //   component: Home,
      //   showInMenu: true,
      // },
    ],
  },
};
