import {
  candidateDetailsError,
  createCandidateDetailSuccess,
  deleteCandidateDetailSuccess,
  editCandidateDetailSuccess,
  getAllCandidateDetailsSuccess,
  candidateStatusChangeSuccess,
  candidateMentorDetailsSuccess
} from "../actions/candidateActions";
import types from "../types";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import instance from "../../lib/axios/axiosInstance";
import { AxiosResponse } from "axios";
import { notification } from "antd";

function* fetchCandidateDetails(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/Candidate/GetAll", action.payload)
    );
    yield put(getAllCandidateDetailsSuccess(response.data));
  } catch (error) {
    yield put(candidateDetailsError(error));
  }
}
function* fetchCandidateMentorDetails(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/Candidate/CandidateProjectDetails", action.payload)
    );
    yield put(candidateMentorDetailsSuccess(response.data));
  } catch (error) {
    yield put(candidateDetailsError(error));
  }
}
function* createCandidateDetails(action: any) {
  try {
    yield call(() => instance.post("/api/v1.1/Candidate/Create", action.payload.data));
    yield put(createCandidateDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(candidateDetailsError(error));
    notification.error({
      message: "Create Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* deleteCandidateDetails(action: any) {
  try {
    yield call(() => instance.delete("/api/v1.1/Candidate/Delete", action.payload));
    yield put(deleteCandidateDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(candidateDetailsError(error));
    notification.error({
      message: "Delete Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}
function* candidateStatusChange(action: any) {
  try {
    yield call(() => instance.put("/api/v1.1/Candidate/ChangeStatus2", action.payload.data));
    yield put(candidateStatusChangeSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(candidateDetailsError(error));
    notification.error({
      message: "Status Change Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* editCandidateDetails(action: any) {
  try {
    yield call(() => instance.put("/api/v1.1/Candidate/Update", action.payload.data));
    yield put(editCandidateDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(candidateDetailsError(error));
    notification.error({
      message: "Update Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

export function* candidateDetailsSaga() {
  yield takeEvery(types.GETALL_CANDIDATES, fetchCandidateDetails);
  yield takeEvery(types.CANDIDATE_MENTOR_DETAILS, fetchCandidateMentorDetails);
  yield takeEvery(types.CREATE_CANDIDATE, createCandidateDetails);
  yield takeEvery(types.DELETE_CANDIDATE, deleteCandidateDetails);
  yield takeEvery(types.UPDATE_CANDIDATE, editCandidateDetails);
  yield takeEvery(types.CANDIDATE_STATUS_CHANGE, candidateStatusChange);
}
