import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, updateUserStatus } from "../../../redux/actions/usersAction";
import styled from "@emotion/styled";
import { Button, Divider, notification, Popover } from "antd";
import Text from "antd/lib/typography/Text";
import { RootState } from "../../../redux/store";

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  background: #f2fff2 0% 0% no-repeat padding-box;
  border-radius: 20px;
  width: 90px;
`;

function StatusPopover(props: any) {
  const { item, record, searchData } = props;
  const [visiblePopover, setVisiblePopover] = useState(false);
  const { users } = useSelector((state: RootState) => state.users);

  const dispatch = useDispatch();

  function onStatusChange(value: any) {
    const data = {
      id: record.id,
      isActive: value,
    };
    dispatch(
      updateUserStatus({
        data,
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "User status has been successfully updated.",
            duration: 2,
          });
          dispatch(
            getAllUsers({
              params: {
                PageNumber: users.data.pageNumber,
                PageSize: users.data.pageSize,
                Name: searchData,
              },
            })
          );
        },
      })
    );
    setVisiblePopover(false);
  }

  return (
    <React.Fragment>
      <Popover
        placement="bottom"
        content={
          <React.Fragment>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <StatusContainer onClick={() => onStatusChange(true)} style={{ marginBottom: "4px" }}>
                <div
                  style={{
                    borderRadius: "50%",
                    height: "7px",
                    width: "7px",
                    backgroundColor: "#00BF00",
                    marginRight: "5px",
                  }}
                />
                <Text style={{ fontSize: "12px", color: "#00BF00" }}>Active</Text>
              </StatusContainer>
              <Divider style={{ margin: 0, padding: 0 }} />
              <StatusContainer onClick={() => onStatusChange(false)} style={{ marginTop: "4px" }}>
                <div
                  style={{
                    borderRadius: "50%",
                    height: "7px",
                    width: "7px",
                    backgroundColor: "#BABABA",
                    marginRight: "5px",
                  }}
                />
                <Text style={{ fontSize: "12px", color: "#BABABA" }}>Inactive</Text>
              </StatusContainer>
            </div>
          </React.Fragment>
        }
        trigger="click"
        visible={visiblePopover}
        onVisibleChange={() => setVisiblePopover(!visiblePopover)}
      >
        <StyledButton style={{ borderColor: `${item ? "#00BF00" : "#BABABA"}` }}>
          <StatusContainer>
            <div
              style={{
                borderRadius: "50%",
                height: "7px",
                width: "7px",
                backgroundColor: `${item ? "#00BF00" : "#BABABA"}`,
                marginRight: "5px",
              }}
            />
            <Text style={{ fontSize: "12px", color: `${item ? "#00BF00" : "#BABABA"}` }}>
              {item ? "Active" : "Inactive"}
            </Text>
          </StatusContainer>
        </StyledButton>
      </Popover>
    </React.Fragment>
  );
}

export default StatusPopover;
