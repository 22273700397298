import styled from "@emotion/styled";
import Paragraph from "antd/lib/typography/Paragraph";
import moment from "moment";
import { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { MentorLogDetailProps, MentorLogProps } from "../interface";
import HourLogInput from "./HourlogInput";

const CellWrap = styled.div`
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 90px;
  padding: 0px 10px 0px 10px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  }

  &.isToday {
    // background: #e8f8ff;
    // background: #33cccc;
    background: #e0ffff;
  }

  &.billActive {
    background: #c0e2ff;
    // background: #9bddff;
  }

  &.paidActive {
    background: #b5ff97;
  }
`;

const PopoverText = styled(Paragraph)`
  &.ant-typography {
    display: block;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;

    &.label {
      font-size: 10px;
      margin-bottom: 0;
    }

    &.date {
      margin-bottom: 15px;
    }

    textarea {
      resize: none;
      width: 100%;
      height: auto;
    }
  }
`;

interface CellPopoverProps {
  log: MentorLogProps;
  date: Moment;
}

const CellPopover = (props: CellPopoverProps) => {
  const { log, date } = props;
  const [hourLog, sethourLog] = useState<MentorLogDetailProps[]>();
  const approvedByCandidate = hourLog && hourLog.length > 0 && hourLog[0].isValidatedByCandidate;
  const isTimesheetCreated = hourLog && hourLog.length > 0 && hourLog[0].timesheetCreated;
  const matchDate = () => {
    if (log.details) {
      let hour = log.details.filter((detail) => {
        return moment(detail.date).format("DD/MM/YYYY") === date.format("DD/MM/YYYY");
      });
      sethourLog(hour);
    } else {
      sethourLog([]);
    }
  };

  useEffect(() => {
    matchDate();
  }, [log]);

  if (
    hourLog &&
    hourLog.length > 0 &&
    hourLog[0].isValidatedByCandidate === true &&
    hourLog[0].timesheetCreated === true &&
    hourLog[0].isPaid
  ) {
    return (
      <CellWrap className="paidActive">
        {hourLog && <HourLogInput hourLog={hourLog} date={date} log={log} />}
      </CellWrap>
    );
  }

  return (
    <React.Fragment>
      {/* {hourLog && hourLog.length > 0 && hourLog[0].isValidatedByCandidate === true ? (
        <CellWrap className="isToday">
          {hourLog && <HourLogInput hourLog={hourLog} date={date} log={log} />}
        </CellWrap>
      ) : (
        <CellWrap>{hourLog && <HourLogInput hourLog={hourLog} date={date} log={log} />}</CellWrap>
      )} */}
      {hourLog &&
        hourLog.length > 0 &&
        hourLog[0].isValidatedByCandidate === true &&
        hourLog[0].timesheetCreated === false && (
          <CellWrap className="isToday">
            {hourLog && <HourLogInput hourLog={hourLog} date={date} log={log} />}
          </CellWrap>
        )}

      {hourLog &&
        hourLog.length > 0 &&
        hourLog[0].isValidatedByCandidate === true &&
        hourLog[0].timesheetCreated === true && (
          <CellWrap className="billActive">
            {hourLog && <HourLogInput hourLog={hourLog} date={date} log={log} />}
          </CellWrap>
        )}

      {hourLog && hourLog.length === 0 && (
        <CellWrap>{hourLog && <HourLogInput hourLog={hourLog} date={date} log={log} />}</CellWrap>
      )}

      {hourLog && hourLog.length > 0 && hourLog[0].isValidatedByCandidate === false && (
        <CellWrap>{hourLog && <HourLogInput hourLog={hourLog} date={date} log={log} />}</CellWrap>
      )}
    </React.Fragment>
  );
};

export default CellPopover;
