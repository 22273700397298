import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt from "jwt-decode";
import {
  createCompanyDetail,
  createCompanyModal,
  editCompanyDetail,
  getAllCompanyDetails,
  updateCompanyModal,
} from "../../../redux/actions/companyAction";
import { createFromIconfontCN } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Col, Form, Input, notification, Row, Select, Typography, Upload } from "antd";
import { useForm } from "antd/lib/form/Form";

import FileUploadImage from "../../../image/file.svg";
import { getAllCountries } from "../../../redux/actions/countriesAction";
import { RootState } from "../../../redux/store";
import { Option } from "antd/lib/mentions";
import Avatar from "antd/lib/avatar/avatar";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2453178_s80bobnfq9p.js",
});

const Container = styled.div`
  background: transparent linear-gradient(98deg, #3bb1ff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  width: 465px;
  height: 94px;
`;
const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 22px 22px 35px;
`;
const ModalBody = styled.div`
  padding: 35px;
  background-color: white;
`;
const PictureConatiner = styled.div`
  margin-top: 16px;
`;
const FileContainer = styled.div`
  margin-top: 16px;
`;
const StyledImage = styled("img")`
  width: 42px;
  height: 54px;
`;
const AddButton = styled(Button)`
  background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 3px;
  color: white;
  width: 100%;
  margin-top: 30px;
  &:hover {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat;
    color: white;
  }
`;
const StyledInput = styled(Input)`
  color: #434343;
  font-size: 13px;
`;
const { Text } = Typography;
const { Dragger } = Upload;

interface IAddCompanyProps {
  editItem: any;
  setVisible: (visible: boolean) => void;
  allCompanies: any;
  searchItem: any;
  setDisabled: any;
}

function AddCompany(props: IAddCompanyProps) {
  const { editItem, setVisible, allCompanies, searchItem, setDisabled } = props;
  const [form] = useForm();
  const [uploadImage, setUploadImage]: any = useState("");
  const [uploadFile, setUploadFile]: any = useState("");

  const dispatch = useDispatch();
  const { countries } = useSelector((state: RootState) => state.countries);

  let token: any = localStorage.getItem("token");
  const user: any = jwt(token);
  const newUser = user.roles.split(" ").join("").toUpperCase();

  const handleImageChange = async (e: any) => {
    let length = e.fileList.length;
    if (length > 0) {
      setUploadImage(e.fileList[length - 1].originFileObj);
      //   form.setFieldsValue({ content: e.file.name });
    }
  };

  const handleFileChange = async (e: any) => {
    let length = e.fileList.length;
    if (length > 0) {
      setUploadFile(e.fileList[length - 1].originFileObj);
    }
  };

  const handleAddSubmit = (value: any) => {
    const data = value;
    data["creatorUserId"] = localStorage.getItem("current");

    dispatch(
      createCompanyDetail({
        data,
        onSuccess: () => {
          setDisabled(false);
          notification.success({
            message: "Created",
            description: "Company has been successfully created.",
            duration: 2,
          });
          dispatch(createCompanyModal());
          dispatch(
            getAllCompanyDetails({
              params: {
                role: newUser,
                pageNumber: allCompanies.data.pageNumber,
                pageSize: allCompanies.data.pageSize,
              },
            })
          );
        },
      })
    );
  };

  const handleEditSubmit = (value: any) => {
    const data = value;
    data["creatorUserId"] = localStorage.getItem("current");
    data["id"] = editItem.id;
    data["paymentDetailId"] = editItem.paymentDetails && editItem.paymentDetails.id;

    dispatch(
      editCompanyDetail({
        data,
        onSuccess: () => {
          setVisible(false);
          notification.success({
            message: "Edited",
            description: "Company has been successfully updated.",
            duration: 2,
          });
          dispatch(updateCompanyModal());
          dispatch(
            getAllCompanyDetails({
              params: {
                role: newUser,
                pageNumber: allCompanies.data.pageNumber,
                pageSize: allCompanies.data.pageSize,
                CompanyName: searchItem,
              },
            })
          );
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getAllCountries({}));
  }, [dispatch]);

  return (
    <React.Fragment>
      <Form
        name="basic"
        form={form}
        onFinish={editItem ? handleEditSubmit : handleAddSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 4]}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please input name!" }]}
              initialValue={editItem ? editItem.name : ""}
              label="Company Name"
            >
              <StyledInput placeholder="Company Name" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            {editItem ? (
              <Form.Item
                name="countryId"
                rules={[{ required: true, message: "Please select country!" }]}
                initialValue={editItem && editItem.countryInfo.id}
                label="Country"
              >
                <Select placeholder="Country" style={{ fontSize: "13px" }} allowClear={true}>
                  {countries &&
                    countries.data &&
                    countries.data.map((country: any) => (
                      <Option key={country.id} value={country.id} style={{ fontSize: "13px" }}>
                        {country.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                name="countryId"
                rules={[{ required: true, message: "Please select country!" }]}
                label="Country"
              >
                <Select placeholder="Country" style={{ fontSize: "13px" }} allowClear={true}>
                  {countries &&
                    countries.data &&
                    countries.data.map((country: any) => (
                      <Option key={country.id} value={country.id} style={{ fontSize: "13px" }}>
                        {country.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input email!" },
                { type: "email", message: "Please input valid email!" },
              ]}
              initialValue={editItem ? editItem.email : ""}
              label="Email"
            >
              <StyledInput placeholder="Email" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="phone"
              rules={[{ required: true, message: "Please input phone number!" }]}
              initialValue={editItem ? editItem.phone : ""}
              label="Phone Number"
            >
              <StyledInput placeholder="Phone Number" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="streetAddress1"
              rules={[{ required: true, message: "Please input Street Address 1!" }]}
              initialValue={editItem ? editItem.streetAddress1 : ""}
              label="Street Address 1"
            >
              <StyledInput placeholder="Street Address 1" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="streetAddress2"
              initialValue={editItem ? editItem.streetAddress2 : ""}
              label="Street Address 2"
            >
              <StyledInput placeholder="Street Address 2" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="zipCode"
              rules={[{ required: true, message: "Please input zip code!" }]}
              initialValue={editItem ? editItem.zipCode : ""}
              label="Zip Code"
            >
              <StyledInput placeholder="Zip Code" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="state"
              rules={[{ required: true, message: "Please input state!" }]}
              initialValue={editItem ? editItem.state : ""}
              label="State"
            >
              <StyledInput placeholder="State" />
            </Form.Item>
          </Col>
        </Row>
        <AddButton htmlType="submit">{editItem ? "UPDATE" : "CREATE"}</AddButton>
      </Form>
    </React.Fragment>
  );
}
export default AddCompany;
