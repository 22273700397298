import types from "../types";

const initialState = {
  loading: false,
  allInvoices: [],
  status: [],
  error: null,
  invoiceByID: [],
};

const invoiceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GETALL_INVOICES:
    case types.GENERATE_INVOICE:
    case types.GET_INVOICE_BYID:
      return {
        ...state,
        loading: true,
      };
    case types.GETALL_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        allInvoices: action.payload,
        error: null,
      };
    case types.GET_INVOICEBYID_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceByID: action.payload,
        error: null,
      };

    case types.GENERATE_INVOICE_SUTECCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case types.INVOICE_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
      
    case types.INVOICE_MARKASPAID_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.payload,
      };

    case types.INVOICE_MARKASPAID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case types.INVOICE_MARKASPAID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return {
        ...state,
      };
  }
};

export default invoiceReducer;
