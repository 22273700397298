import {
  createMentorDetailSuccess,
  deleteMentorDetailSuccess,
  getAllMentorDetailsSuccess,
  mentorDetailsError,
  editMentorDetailSuccess,
  mentorStatusChangeSuccess,
  createMentorPaymentDetailSuccess,
  getAllBankDetailsSuccess,
  updateMentorPaymentDetailSuccess,
} from "../actions/mentorAction";
import types from "../types";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import instance from "../../lib/axios/axiosInstance";
import { AxiosResponse } from "axios";
import { notification } from "antd";

function* fetchMentorDetails(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/Mentor/GetAll", action.payload)
    );
    yield put(getAllMentorDetailsSuccess(response.data));
  } catch (error) {
    yield put(mentorDetailsError(error));
  }
}

function* fetchBankDetails(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/Mentor/GetBanks", action.payload)
    );
    yield put(getAllBankDetailsSuccess(response.data));
  } catch (error) {
    yield put(mentorDetailsError(error));
  }
}

function* createMentorDetails(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.post("/api/v1.1/Mentor/Create", action.payload.data)
    );

    yield put(createMentorDetailSuccess(response.data.data));
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(mentorDetailsError(error));
    notification.error({
      message: "Create Failed",
      description: error.response.data.message.description,

      duration: 2,
    });
  }
}

function* createMentorPaymentDetails(action: any) {
  try {
    yield call(() => instance.post("/api/v1.1/Mentor/AddPayment", action.payload.data));
    yield put(createMentorPaymentDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(mentorDetailsError(error));
    notification.error({
      message: "Create Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* updateMentorPaymentDetails(action: any) {
  try {
    yield call(() => instance.put("/api/v1.1/Mentor/UpdatePayment", action.payload.data));
    yield put(updateMentorPaymentDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(mentorDetailsError(error));
    notification.error({
      message: "Update Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* deleteMentorDetails(action: any) {
  try {
    yield call(() => instance.delete("/api/v1.1/Mentor/Delete", action.payload));
    yield put(deleteMentorDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(mentorDetailsError(error));
    notification.error({
      message: "Delete Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}
function* mentorStatusChange(action: any) {
  try {
    yield call(() => instance.put("/api/v1.1/Mentor/ChangeStatus", action.payload.data));
    yield put(mentorStatusChangeSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(mentorDetailsError(error));
    notification.error({
      message: "Status Change Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* editMentorDetails(action: any) {
  try {
    yield call(() => instance.put("/api/v1.1/Mentor/Update", action.payload.data));
    yield put(editMentorDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(mentorDetailsError(error));
    notification.error({
      message: "Update Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

export function* mentorDetailsSaga() {
  yield takeEvery(types.GETALL_MENTORS, fetchMentorDetails);
  yield takeEvery(types.GETALL_BANKS, fetchBankDetails);
  yield takeEvery(types.CREATE_MENTOR, createMentorDetails);
  yield takeEvery(types.CREATE_PAYMENT_DETAILS, createMentorPaymentDetails);
  yield takeEvery(types.UPDATE_PAYMENT_DETAILS, updateMentorPaymentDetails);
  yield takeEvery(types.DELETE_MENTOR, deleteMentorDetails);
  yield takeEvery(types.UPDATE_MENTOR, editMentorDetails);
  yield takeEvery(types.MENTOR_STATUS_CHANGE, mentorStatusChange);
}
