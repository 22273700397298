import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Typography,
  Button,
  DatePicker,
  Tabs,
  Badge,
  Steps,
  Drawer,
  notification,
  Result,
  Popconfirm,
} from "antd";
import BillTable from "./BillTable";
import Condition from "../../../components/Condition";
import InvoiceDetail from "./InvoiceDetail";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTimesheet,
  approveTimesheet,
  getAllStatus,
  deleteTimesheet,
} from "../../../redux/actions/timesheetAction";
import { generateInvoices } from "../../../redux/actions/invoiceAction";
import { RootState } from "../../../redux/store";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { createFromIconfontCN, ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import jwt from "jwt-decode";
import ProjectContainer from "./ProjectContainer";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2646627_en735g9v9b.js",
});

const { Text } = Typography;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Step } = Steps;

const Wrapper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 22px #008ce905;
  border: 1px solid #008ce930;
`;
const Header = styled.div`
  height: 85px;
  background: #eef8ff 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;
`;
const BodyConatiner = styled.div`
  padding: 20px;
`;
const GenerateButton = styled(Button)`
  height: 36px;
  width: 155px;
  background: transparent linear-gradient(106deg, #5abdff 0%, #0f9fff 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 25px 11px #008ce90d;
  border-radius: 6px;
  color: white;
  &:focus,
  &:hover {
    background: transparent linear-gradient(106deg, #5abdff 0%, #0f9fff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
    outline: none;
    border: none;
  }
`;
const DeleteButton = styled(Button)`
  height: 36px;
  width: 100px;
  background: transparent linear-gradient(111deg, #ff6868 0%, #ff1f5c 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 25px 11px #ff285d0d;
  border-radius: 6px;
  color: white;
  &:focus,
  &:hover {
    background: transparent linear-gradient(111deg, #ff6868 0%, #ff1f5c 100%) 0% 0% no-repeat
      padding-box;
    color: white;
    outline: none;
    border: none;
  }
`;
const ApproveButton = styled(Button)`
  height: 36px;
  width: 100px;
  background: transparent linear-gradient(106deg, #5abdff 0%, #0f9fff 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 25px 11px #ff285d0d;
  border-radius: 6px;
  color: white;
  &:focus,
  &:hover {
    background: transparent linear-gradient(106deg, #5abdff 0%, #0f9fff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
    outline: none;
    border: none;
  }
`;
const InvoiceButton = styled(Button)`
  height: 36px;
  width: 114px;
  background: transparent linear-gradient(106deg, #5abdff 0%, #0f9fff 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 25px 11px #008ce90d;
  border-radius: 6px;
  color: white;
  &:focus,
  &:hover {
    background: transparent linear-gradient(106deg, #5abdff 0%, #0f9fff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
    outline: none;
    border: none;
  }
`;
const StyledRangePicker = styled(RangePicker)`
  box-shadow: 0px 25px 11px #008ce90d;
  border-radius: 6px;
  height: 36px;
  width: 230px;

  &.ant-picker,
  &.ant-picker-disabled {
    border: 1px solid #008ce940;
    background: #ffffff73 0% 0% no-repeat padding-box;
  }
  .ant-picker-input > input[disabled] {
    color: #323232;
    opacity: 1;
  }
`;
const StyledSteps = styled(Steps)`
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background: none;
  }
  .ant-steps-item-title::after {
    border-bottom: 2px dashed #434343;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    border-bottom: 2px dashed #008ce9;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
  .ant-steps-item-icon {
    background: #eef8ff 0% 0% no-repeat padding-box;
    font-size: 18px;
    border: 2px solid #008ce9;
    color: #008ce9;
    height: 28px;
    width: 28px;
  }
  .ant-steps-item-icon,
  .ant-steps-icon {
    top: -4px;
  }
  .ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
    display: none;
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
    display: none;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #008ce9;
  }
  .ant-steps-item-active
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #434343;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #434343;
  }
`;
const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: -20px 0px 75px #008ce926;
  }
  .ant-drawer-close {
    margin-right: 55px;
    color: #171717;
    font-size: 20px;
    margin-top: 35px;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-badge {
    color: inherit;
  }
  .ant-tabs-tab {
    padding: 12px;
  }
`;
const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 36px;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

interface TimesheetProps {
  types: any;
  hourlogDetails: any;
  projectId: any;
  filterMentorData: any;
  filterHourLogData: any;
}
const TimesheetTable = (props: TimesheetProps) => {
  const { types, hourlogDetails, projectId, filterMentorData, filterHourLogData } = props;
  const dispatch = useDispatch();
  const [viewInvoice, setViewinvoice] = useState(false);
  const { allTimesheets } = useSelector((state: RootState) => state.timesheets);
  let token: any = localStorage.getItem("token");
  const user: any = jwt(token);

  const defaultHourlogDetails =
    allTimesheets && allTimesheets.filter((item: any) => item.timesheetStatusId === projectId);

  const data = hourlogDetails.length > 0 ? hourlogDetails[0] : defaultHourlogDetails[0];

  const today = new Date();
  const timesheetCreatedDate = new Date(data && data.createdDate);
  const difference = today.getTime() - timesheetCreatedDate.getTime();
  const differenceInDays = difference / (1000 * 60 * 60 * 24);

  const ProjectStartDate = new Date(data && data.projectStartDate);
  const ProjectStartDateFormatted = ProjectStartDate.toDateString();
  const ProjectEndDate =
    data && data.projectEndDate === null ? null : new Date(data && data.projectEndDate);
  const ProjectEndDateFormatted = ProjectEndDate === null ? null : ProjectEndDate.toDateString();

  const getCurrentStep = (types: any) => {
    switch (types) {
      case "Pending Approval":
        return 1;
      case "Approved":
        return 2;
      case "Invoiced":
        return 3;
      case "Rejected":
        return 4;
      default:
        return 1;
    }
  };

  let currentStep = getCurrentStep(types);

  const handleApprove = () => {
    const id = data.id;
    dispatch(
      approveTimesheet({
        params: { id },
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "Timesheet Approved.",
            duration: 2,
          });
          dispatch(getAllTimesheet({}));
          dispatch(getAllStatus({}));
          filterMentorData(currentStep);
        },
      })
    );
  };

  const handleDelete = () => {
    const id = data.id;
    dispatch(
      deleteTimesheet({
        params: { id },
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "Timesheet Deleted.",
            duration: 2,
          });
          dispatch(getAllTimesheet({}));
          dispatch(getAllStatus({}));
          filterMentorData(currentStep);
        },
      })
    );
  };

  const handleGenerateInvoice = () => {
    const invoiceData: any = { timesheetId: null };
    invoiceData.timesheetId = data.id;

    dispatch(
      generateInvoices({
        invoiceData,
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "Invoice Generated.",
            duration: 2,
          });
          dispatch(getAllTimesheet({}));
          dispatch(getAllStatus({}));
          filterMentorData(currentStep);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getAllTimesheet({}));
  }, []);

  useEffect(() => {}, []);

  return defaultHourlogDetails.length === 0 ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: "10px",
      }}
    >
      <Result status="404" title="No Data Found" subTitle="Sorry, this page does not have data" />
    </div>
  ) : data === undefined ? (
    <LoadingSpinner />
  ) : (
    <React.Fragment>
      <Wrapper>
        <Header>
          <StyledSteps current={currentStep}>
            <Step title="Hourlogs" />
            <Step title="Approved" />
            <Step title="Invoiced" />
          </StyledSteps>
        </Header>
        <BodyConatiner>
          <StyledContainer style={{ marginTop: "0px" }}>
            <StyledHeader>
              <Text style={{ fontSize: "19px", fontWeight: 600 }}>{data.name}</Text>
              <StyledWrapper>
                <Text style={{ fontSize: "15px" }}>
                  <IconFont type="icon-company" style={{ fontSize: "13px", paddingRight: "7px" }} />
                  {data.company}
                </Text>
                <Text style={{ fontSize: "15px" }}>
                  <ClockCircleOutlined style={{ fontSize: "13px", paddingRight: "7px" }} />
                  {Math.round(differenceInDays)} {Math.round(differenceInDays) > 1 ? "days" : "day"}{" "}
                  ago/{data.totalHours} hours
                </Text>
              </StyledWrapper>
            </StyledHeader>
            <StyledWrapper>
              <Condition val={types == "Approved"}>
                {user && user.roles === "Account" && (
                  <Popconfirm
                    title="Are you sure to generate Invoice?"
                    onConfirm={() => handleGenerateInvoice()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <GenerateButton>Generate Invoice</GenerateButton>
                  </Popconfirm>
                )}
              </Condition>
              <Condition val={types == "Pending Approval"}>
                {user && user.roles === "Candidate Admin" && (
                  <Popconfirm
                    title="Are you sure to approve this Timesheet?"
                    onConfirm={() => handleApprove()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <ApproveButton>Approve</ApproveButton>
                  </Popconfirm>
                )}
              </Condition>
              <Condition
                val={types == "Pending Approval" || types == "Approval" || types == "Approved"}
              >
                {(user && user.roles === "Account" && currentStep === 2 && (
                  <Popconfirm
                    title="Are you sure to delete this Timesheet?"
                    onConfirm={() => handleDelete()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteButton>Delete</DeleteButton>
                  </Popconfirm>
                )) ||
                  (user && user.roles === "Candidate Admin" && currentStep === 1 && (
                    <Popconfirm
                      title="Are you sure to delete this Timesheet?"
                      onConfirm={() => handleDelete()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteButton>Delete</DeleteButton>
                    </Popconfirm>
                  ))}
              </Condition>
              <Condition val={types == "Invoiced"}>
                <InvoiceButton onClick={() => setViewinvoice(true)}>View Invoice</InvoiceButton>
              </Condition>
            </StyledWrapper>
          </StyledContainer>
          <div>
            <ProjectContainer data={data} />
          </div>

          <div style={{ marginTop: "36px" }}>
            <BillTable data={data} />
          </div>
          <div style={{ marginTop: "36px" }}>
            <StyledTabs defaultActiveKey="1" size="large">
              <TabPane
                tab={
                  <Badge count={5} offset={[10, -3]}>
                    Attachment
                  </Badge>
                }
                key="1"
              ></TabPane>
              <TabPane tab="Timesheet History" key="2"></TabPane>
              <TabPane tab="Expense" key="3"></TabPane>
            </StyledTabs>
          </div>
          <StyledDrawer
            title=""
            placement="right"
            closable={true}
            onClose={() => setViewinvoice(false)}
            visible={viewInvoice}
            width={700}
            mask={false}
          >
            <InvoiceDetail invoiceID={data.invoiceId} />
          </StyledDrawer>
        </BodyConatiner>
      </Wrapper>
    </React.Fragment>
  );
};
export default TimesheetTable;
