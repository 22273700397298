import React, { useEffect, useState, useContext } from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker, notification } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";
import { useDispatch } from "react-redux";
import { createTimesheet, getAllTimesheet } from "../../../redux/actions/timesheetAction";
import { useForm } from "antd/lib/form/Form";
import { getAllMentorHourLogs } from "../../../redux/actions/mentorHourLogAction";
import { ProjectHourLogContext } from "../../../components/ContextComponent";

const { Option } = Select;
const { RangePicker } = DatePicker;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    background-color: #f9fcff !important;
    color: #27a8ff !important;
  }

  .ant-drawer-footer {
    border: none !important;
  }
`;

const StyledFooter = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 24px 14px;
`;

const DateTitleWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const DateRangeWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DateLineWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin: 10px 25px;
`;

const OuterCircle = styled("div")`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: ${(props) => props.color};
  box-shadow: 0px 3px 15px ${(props) => props.color};
`;

const InnerCircle = styled("div")`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: #ffffff;
`;

const Line = styled("div")`
  height: 5px;
  background: transparent linear-gradient(90deg, #37afff 0%, #2fabff2e 100%) 0% 0% no-repeat
    padding-box;
  flex-grow: 1;
  offset: 0px 5px;
`;
const AddButton = styled(Button)`
  background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 3px;
  color: white;
  &:hover {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat;
    color: white;
  }
`;

interface DrawerProps {
  onClose: () => void;
  visible: boolean;
  createTimesheetData: any;
  setvisible: any;
}

const CreateTimesheet = (props: DrawerProps) => {
  const { onClose, visible, createTimesheetData, setvisible } = props;
  const dispatch = useDispatch();
  const [form] = useForm();
  const [totalHours, setTotalHours] = useState<number>();
  const { startDate, endDate, searchValue } = useContext(ProjectHourLogContext);

  const getTotalHours = (values: any) => {
    if (createTimesheetData) {
      let totalHrs = 0;
      createTimesheetData.details.map((val: any) => {
        let hourlogdate = new Date(val.date);
        let startDate = new Date(values[0]);
        let endDate = new Date(values[1]);
        if (
          val.isValidatedByCandidate === true &&
          val.timesheetCreated === false &&
          hourlogdate.getDate() >= startDate.getDate() &&
          hourlogdate.getDate() <= endDate.getDate()
        ) {
          return (totalHrs += val.hours);
        }
      });

      return totalHrs;
    }
  };

  useEffect(() => {
    form.setFieldsValue({ totalHours: totalHours });
  }, [totalHours]);

  useEffect(() => {
    form.setFieldsValue({ company: createTimesheetData && createTimesheetData.company });
    form.setFieldsValue({ mentor: createTimesheetData && createTimesheetData.mentorName });
  }, [createTimesheetData]);

  const handleCreateTimesheet = (values: any) => {
    let TotalHours = getTotalHours(values.date);
    let data: any = {};
    data["projectId"] = createTimesheetData && createTimesheetData.projectId;
    data["startDate"] = values.date[0].format("YYYY-MM-DD");
    data["endDate"] = values.date[1].format("YYYY-MM-DD");
    data["totalHours"] = TotalHours;
    dispatch(
      createTimesheet({
        data,
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "Timesheet Created.",
            duration: 2,
          });
          getAllTimesheet({});
          setvisible(false);
          form.resetFields();
          setTotalHours(undefined);
          dispatch(
            getAllMentorHourLogs({
              params: {
                StartDate: startDate,
                EndDate: endDate,
                MentorOrCandidateName: searchValue,
              },
            })
          );
        },
      })
    );
  };

  const handleDateChange = (values: any) => {
    const totalHours = getTotalHours(values);
    setTotalHours(totalHours);
  };

  return (
    <StyledDrawer
      title=""
      width={500}
      onClose={() => {
        form.resetFields();
        onClose();
        setTotalHours(undefined);
      }}
      visible={visible}
    >
      <Form layout="vertical" hideRequiredMark form={form} onFinish={handleCreateTimesheet}>
        <Row>
          <Col span={24}>
            <Title level={4}>
              <CalendarOutlined /> New Timesheets
            </Title>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "16px" }}>
          <Col span={24}>
            <Text style={{ fontSize: "18px" }}>Project Information</Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="company"
              label="Company"
              initialValue={createTimesheetData && createTimesheetData.company}
            >
              <Input
                style={{ background: "#F9FCFF", color: "#434343" }}
                disabled
                placeholder="Company Name"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="mentor"
              label="Mentor"
              initialValue={createTimesheetData && createTimesheetData.mentorName}
            >
              <Input
                style={{ background: "#F9FCFF", color: "#434343" }}
                disabled
                placeholder="Mentor Name"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ paddingBottom: "16px" }}>
          <Col span={24}>
            <DateTitleWrapper>
              <Text strong>Start Date</Text> <Text strong>End Date</Text>
            </DateTitleWrapper>
            <DateLineWrapper>
              <OuterCircle color="#37AFFF">
                <InnerCircle />
              </OuterCircle>
              <Line />
              <OuterCircle color="#C3C3C3">
                <InnerCircle />
              </OuterCircle>
            </DateLineWrapper>
            <DateRangeWrapper>
              <Form.Item
                name="date"
                rules={[{ required: true, message: "Please select the start date and end date!" }]}
              >
                <RangePicker format="MM-DD-YYYY" allowClear onChange={handleDateChange} />
              </Form.Item>
            </DateRangeWrapper>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="totalHours" label="Total Hours" initialValue={totalHours}>
              <Input
                style={{ background: "#F9FCFF", color: "#434343" }}
                disabled
                placeholder="Total Hours"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ paddingTop: "20px" }}>
          <Col span={24} style={{ paddingTop: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingTop: "30px",
              }}
            >
              <Button
                onClick={onClose}
                type="text"
                style={{ marginRight: 8, backgroundColor: "#DBDBDB" }}
              >
                Cancel
              </Button>
              <AddButton htmlType="submit">Create</AddButton>
            </div>
          </Col>
        </Row>
      </Form>
    </StyledDrawer>
  );
};

export default CreateTimesheet;
