import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProjectStatus,
  getAllProjects,
  getProjectStatus,
} from "../../../redux/actions/projectAction";
import styled from "@emotion/styled";
import { Button, Divider, Popover, notification } from "antd";
import Text from "antd/lib/typography/Text";
import jwtDecode from "jwt-decode";
import { RootState } from "../../../redux/store";

const StatusContainer = styled.div``;

const StyledButton = styled(Button)`
  border-radius: 20px;
  width: 95px;
`;

function StatusChange(props: any) {
  const { item, record, searchData, isActive} = props;
  const [visiblePopover, setVisiblePopover] = useState(false);
  let token: any = localStorage.getItem("token");
    const user: any = jwtDecode(token);

  const dispatch = useDispatch();

  const { projectStatus, projects } = useSelector((state: RootState) => state.projects);

  useEffect(() => {
    dispatch(getProjectStatus(""));
  }, []);

  function onStatusChange(value: any) {
    const data = {
      id: record.id,
      projectStatusId: value,
    };
    dispatch(
      updateProjectStatus({
        data,
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "Project status has been successfully updated.",
            duration: 2,
          });
          dispatch(
            getAllProjects({
              params: {
                PageNumber: projects.data.pageNumber,
                pageSize: projects.data.pageSize,
                    Name: searchData,
                    isActive: isActive
              },
            })
          );
        },
      })
    );
    setVisiblePopover(false);
  }
  return (
    <React.Fragment>
      <Popover
        placement="bottom"
        content={
          <React.Fragment>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {projectStatus.data &&
                projectStatus.data.map((item: any) => (
                  <>
                    <StatusContainer
                      onClick={() => onStatusChange(item.id)}
                      style={{ marginTop: "4px" }}
                    >
                      <Text
                        style={{
                          fontSize: "12px",
                          color: `${item.name === "Completed" ? "#00BF00" : "#A6A7A8"}`,
                          cursor: "pointer",
                        }}
                      >
                        {item.name}
                      </Text>
                    </StatusContainer>
                  </>
                ))}
            </div>
          </React.Fragment>
        }
        trigger={user.roles === "Super Admin" ? "none" : "click"}
        visible={visiblePopover}
        onVisibleChange={() => setVisiblePopover(!visiblePopover)}
      >
        <StyledButton
          style={{
            borderColor: `${item === "Completed" ? "#00BF00" : "#BABABA"}`,
            backgroundColor: `${item === "Completed" ? "#F2FFF2" : "#FFFFFF"}`,
          }}
        >
          <StatusContainer>
            <Text
              style={{ fontSize: "12px", color: `${item === "Completed" ? "#00BF00" : "#A8A8A8"}` }}
            >
              {item}
            </Text>
          </StatusContainer>
        </StyledButton>
      </Popover>
    </React.Fragment>
  );
}
export default StatusChange;
