import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createMentorDetail,
  editMentorDetail,
  getAllMentorDetails,
} from "../../../redux/actions/mentorAction";
import { createFromIconfontCN } from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Typography,
  Upload,
  InputNumber,
  Select,
  notification,
  Tabs,
} from "antd";
import { Option } from "antd/lib/mentions";
import { useForm } from "antd/lib/form/Form";
import { getAllCompanyDetails } from "../../../redux/actions/companyAction";
import { getAllTechnologyStackDetails } from "../../../redux/actions/technologyStackAction";
import { RootState } from "../../../redux/store";
import jwt from "jwt-decode";
import { getAllCountries } from "../../../redux/actions/countriesAction";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2453178_s80bobnfq9p.js",
});

const { TabPane } = Tabs;

const PictureConatiner = styled.div`
  margin-top: 16px;
`;
const AddButton = styled(Button)`
  background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 3px;
  color: white;
  width: 100%;
  margin-top: 30px;
  &:hover {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0%
      0% no-repeat;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0%
      0% no-repeat;
    color: white;
  }
`;
const StyledInput = styled(Input)`
  font-size: 13px;
  margin-bottom: 5px;
`;
const StyledSelect = styled(Select)`
  font-size: 13px;
  margin-bottom: 5px;
`;
const StyledInputNumber = styled(InputNumber)`
  font-size: 13px;
  margin-bottom: 5px;
  width: 100%;
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input-wrap {
    height: 27px;
  }
`;
const StyledForm = styled(Form)``;

const { Text } = Typography;

interface IAddMentorProps {
  setVisible: (visible: boolean) => void;
  editItem: any;
  searchItem: any;
  allMentors: any;
    setDisabled: any;
    isActive: boolean;
}

function AddMentor(props: IAddMentorProps) {
  const { editItem, setVisible, searchItem, allMentors, setDisabled,isActive } = props;
  const [form] = useForm();
  const dispatch = useDispatch();
  let token: any = localStorage.getItem("token");
  const user: any = jwt(token);
  const newUser = user.roles.split(" ").join("").toUpperCase();

  useEffect(() => {
    dispatch(getAllCompanyDetails({ params: { role: newUser } }));
    dispatch(getAllTechnologyStackDetails({}));
    dispatch(getAllCountries({}));
  }, [dispatch]);

  const allCompanies = useSelector(
    (state: RootState) => state.companies.allCompanies
  );
  const isLoading = useSelector((state: RootState) => state.companies.loading);
  const allTechnologyStacks = useSelector(
    (state: RootState) => state.technologyStacks.allTechnologyStacks
  );
  const { countries } = useSelector((state: RootState) => state.countries);
  const { mentorIdForPayment } = useSelector(
    (state: RootState) => state.mentors
  );

  const handleAddSubmit = (value: any) => {
    const data = value;
    data.createrId = localStorage.getItem("current");
    dispatch(
      createMentorDetail({
        data,
        onSuccess: () => {
          setVisible(false);
          form.resetFields();
          notification.success({
            message: "Success",
            description: "Mentor has been successfully created.",
            duration: 2,
          });

          dispatch(
            getAllMentorDetails({
              params: {
                PageNumber: allMentors.data.pageNumber,
                    pageSize: allMentors.data.pageSize,
                isActive:isActive
              },
            })
          );
        },
      })
    );
  };

  const handleEditSubmit = (value: any) => {
    const data = value;
    value.id = editItem.id;
    dispatch(
      editMentorDetail({
        data,
        onSuccess: () => {
          setVisible(false);
          notification.success({
            message: "Success",
            description: "Mentor has been successfully updated.",
            duration: 2,
          });
          dispatch(
            getAllMentorDetails({
              params: {
                PageNumber: allMentors.data.pageNumber,
                pageSize: allMentors.data.pageSize,
                    Name: searchItem,
                    isActive: isActive
              },
            })
          );
        },
      })
    );
  };

  return (
    <React.Fragment>
      <StyledForm
        name="basic"
        layout="vertical"
        form={form}
        onFinish={editItem ? handleEditSubmit : handleAddSubmit}
      >
        <Row gutter={[16, 4]}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: "Please input firstname!" }]}
              initialValue={editItem ? editItem.firstName : ""}
              label="First Name"
            >
              <StyledInput placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="middleName"
              initialValue={editItem ? editItem.middleName : ""}
              label="Middle Name"
            >
              <StyledInput placeholder="Middle Name" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: "Please input lastname!" }]}
              initialValue={editItem ? editItem.lastName : ""}
              label="Last Name"
            >
              <StyledInput placeholder="Last Name" />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input name!" },
                { type: "email", message: "Please input valid email!" },
              ]}
              initialValue={editItem ? editItem.email : ""}
              label="Email"
            >
              <StyledInput placeholder="Email" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item
              name="phone"
              rules={[
                { required: true, message: "Please input phone number!" },
              ]}
              initialValue={editItem ? editItem.phone : ""}
              label="Phone Number"
            >
              <StyledInput placeholder="Phone" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="street"
              rules={[{ required: true, message: "Please input street name!" }]}
              initialValue={editItem ? editItem.street : ""}
              label="Street"
            >
              <StyledInput placeholder="Street" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="city"
              rules={[
                { required: true, message: "Please input city/town name!" },
              ]}
              initialValue={editItem ? editItem.city : ""}
              label="City"
            >
              <StyledInput placeholder="City/Town" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please input State/Province name!",
                },
              ]}
              initialValue={editItem ? editItem.state : ""}
              label="State"
            >
              <StyledInput placeholder="State/Province" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="zipCode"
              rules={[{ required: true, message: "Please input ZIP Code!" }]}
              initialValue={editItem ? editItem.zipCode : ""}
              label="ZIP Code"
            >
              <StyledInput placeholder="ZIP Code" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            {editItem ? (
              <Form.Item
                name="countryId"
                rules={[
                  { required: true, message: "Please select a country!" },
                ]}
                initialValue={editItem && editItem.countryDetailForMentor.id}
                label="Country"
              >
                <Select
                  placeholder="Country"
                  style={{ fontSize: "13px" }}
                  allowClear={true}
                >
                  {countries &&
                    countries.data &&
                    countries.data.map((country: any) => (
                      <Option
                        key={country.id}
                        value={country.id}
                        style={{ fontSize: "13px" }}
                      >
                        {country.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                name="countryId"
                rules={[
                  { required: true, message: "Please select a country!" },
                ]}
                label="Country"
              >
                <Select
                  placeholder="Country"
                  style={{ fontSize: "13px" }}
                  allowClear={true}
                >
                  {countries &&
                    countries.data &&
                    countries.data.map((country: any) => (
                      <Option
                        key={country.id}
                        value={country.id}
                        style={{ fontSize: "13px" }}
                      >
                        {country.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            </Col>
            <Col className="gutter-row" span={12}>
                <Form.Item
                    name="rate"
                    rules={[
                        {
                            required: true,
                            type: "number",
                            min: 0.1,
                            message: "Please input rate in number!",
                        },
                    ]}
                    initialValue={editItem ? editItem.rate : ""}
                    label="Rate"
                >
                    <StyledInputNumber placeholder="Rate" />
                </Form.Item>
            </Col>
          <Col className="gutter-row" span={24}>
            {!editItem ? (
              <Form.Item name="companyId" label="Company">
                <StyledSelect
                  placeholder="Select Company"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                >
                  {allCompanies &&
                    allCompanies.data &&
                    allCompanies.data.results.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </StyledSelect>
              </Form.Item>
            ) : (
              <Form.Item
                name="companyId"
                initialValue={
                  editItem.companyDetailForMentor === null
                    ? null
                    : editItem.companyDetailForMentor.id
                }
                label="Company"
              >
                <StyledSelect
                  placeholder="Select Company"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                >
                  {allCompanies &&
                    allCompanies.data &&
                    allCompanies.data.results.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </StyledSelect>
              </Form.Item>
            )}
          </Col>
          <Col className="gutter-row" span={24}>
            {!editItem ? (
              <Form.Item
                name="technologyStacks"
                rules={[
                  {
                    required: true,
                    message: "Please select technology stacks!",
                  },
                ]}
                label="Technology Stack"
              >
                <StyledSelect
                  mode="multiple"
                  placeholder="Select TechnologyStack"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                >
                  {allTechnologyStacks &&
                    allTechnologyStacks.data &&
                    allTechnologyStacks.data.results.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </StyledSelect>
              </Form.Item>
            ) : (
              <Form.Item
                name="technologyStacks"
                rules={[
                  {
                    required: true,
                    message: "Please select technology stacks!",
                  },
                ]}
                initialValue={
                  editItem &&
                  editItem.technologyStacks &&
                  editItem.technologyStacks.map((item: any) => item.techStackId)
                }
                label="Technology Stack"
              >
                <StyledSelect
                  mode="multiple"
                  placeholder="Select TechnologyStack"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                >
                  {allTechnologyStacks &&
                    allTechnologyStacks.data &&
                    allTechnologyStacks.data.results.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </StyledSelect>
              </Form.Item>
            )}
          </Col>
        </Row>

        <AddButton htmlType="submit">
          {editItem ? "UPDATE" : "CREATE"}
        </AddButton>
      </StyledForm>
    </React.Fragment>
  );
}
export default AddMentor;
