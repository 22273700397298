import React from 'react';
import styled from '@emotion/styled';
import { Col, Form, Input, notification, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import ActionButton from '../../../components/ActionButton';
import ForgotLock from '../../../image/forgot.svg';
import Logo from '../../../image/logo.svg';

const { Title, Text } = Typography;

const StyledLogoContainer = styled(Row)`
  margin-bottom: 1.5em;
`;

const StyledLoginForm = styled(Col)`
  box-shadow: -3px 18px 35px rgba(0, 0, 0, 0.12);
  padding: 2em 5em 1px 4.5em;
  @media only screen and (max-width: 1024px) {
    padding: 40px 40px 20px 40px;
  }
  background: #f2fbff;
  min-height: 84vh;
`;
const StyledInput = styled(Input)`
  border-radius: 5px;
  // margin-top: 30px;
  :active,
  :focus {
    border-color: #1dce78;
    outline: none;
  }
`;

const StyledForm = styled(Form)`
  height: 100%;
  padding-bottom: 10px;
  .ant-form-item {
    margin-bottom: 40px;
  }
  .ant-form-item-label {
    padding: 0;
    font-size: 0.8em;
  }
`;

const StyledLink = styled('a')`
  color: #6e6e6e !important;
`;

const StyledDiv = styled.div`
  text-align: center;
  margin: 20px 0 20px 0;
`;

const StyledLogo = styled('img')`
  font-size: 1.5em;
  font-weight: 500;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 95px;
  background: #ecf8ff 0% 0% no-repeat padding-box;
  border: 1px solid #0092f33b;
  border-radius: 50%;
  margin-top: 1.5em;
`;
// const StyledButton = styled(Button)`
//   border-radius: 8px;
//   border: 1px solid #30a3e2a1;
//   background: #169af4 0% 0% no-repeat padding-box;
//   width: 100%;
// `;

type SignUpFormProps = {
  setAnimation: any;
  animation: boolean;
};

function ForgotPasswordPage(props: SignUpFormProps) {
  const [form] = useForm();
  const { setAnimation, animation } = props;

  return (
    <StyledLoginForm>
      <StyledLogoContainer>
        <Row>
          <Col span={24}>
            <StyledLogo src={Logo} />
          </Col>
          <Col span={24}>
            <ImageWrapper>
              <img src={ForgotLock} alt="forgot-lock-icon" />
            </ImageWrapper>
          </Col>
        </Row>
      </StyledLogoContainer>
      <Title style={{ fontSize: '1.3em' }}>Forgot Password? Let's get you a new one.</Title>
      <Text style={{ fontSize: '15px', marginBottom: '1em', display: 'block' }}>
        Enter the email you use while creating your account, we will sent you instruction to reset
        your password.
      </Text>
      <StyledForm form={form} layout="vertical" size="large" hideRequiredMark>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            { required: true, message: 'Please enter your Email!' },
            {
              pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: 'Please Enter valid email',
            },
          ]}
        >
          <StyledInput placeholder="name@gmail.com" />
        </Form.Item>
        <Row justify="center" align="middle">
          <Col span={24}>
            <ActionButton
              size="large"
              style={{
                width: '100%',
                background: '#169af4 ',
                borderRadius: '8px',
                fontSize: '1em',
                border: ' 1px solid #30a3e2a1',
              }}
              url="/api/v1.1/Account/ForgetPassword"
              onSuccess={(response: any) => {
                console.log(response);
                notification.open({
                  message: 'Success',
                  description: 'ResetLink Sent Successfully! Please Check your email.',
                });
              }}
              onError={(err: any) => {
                console.log(err);
                notification.open({
                  message: 'Error',
                  description: 'Password reset link cannot be sent.',
                  placement: 'topLeft',
                });
              }}
              onSubmit={({ setFormData, setIsReady }: any) => {
                form
                  .validateFields()
                  .then(values => {
                    const { email } = values;
                    setFormData({
                      email,
                    });
                    setIsReady(true);
                  })
                  .catch(errorInfo => {
                    console.log(errorInfo.message);
                    setIsReady(false);
                  });
              }}
            >
              Reset
            </ActionButton>
            {/* <StyledButton type="primary" onClick={handleResetPassword}>
              Reset
            </StyledButton> */}
          </Col>
          <Col span={24}>
            <StyledDiv>
              <StyledLink onClick={() => setAnimation(!animation)}> Back to login</StyledLink>
            </StyledDiv>
          </Col>
        </Row>
      </StyledForm>
    </StyledLoginForm>
  );
}

export default ForgotPasswordPage;
