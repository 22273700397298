import types from "../types";

export function getAllCompanyDetails(data: any) {
  return {
    type: types.GETALL_COMPANIES,
    payload: data,
  };
}

export function getAllCompanyDetailsSuccess(allCompaniesData: any) {
  return {
    type: types.GETALL_COMPANIES_SUCCESS,
    payload: allCompaniesData,
  };
}

export function createCompanyDetail(data: any) {
  return {
    type: types.CREATE_COMPANY,
    payload: data,
  };
}

export function createCompanyDetailSuccess(id: any) {
  return {
    type: types.CREATE_COMPANY_SUCCESS,
    payload: id,
  };
}

export function createCompanyPaymentDetail(data: any) {
  return {
    type: types.CREATE_COMPANYPAYMENT_DETAILS,
    payload: data,
  };
}

export function createCompanyPaymentDetailSuccess() {
  return {
    type: types.CREATE_COMPANYPAYMENTDETAILS_SUCCESS,
  };
}

export function updateCompanyPaymentDetail(data: any) {
  return {
    type: types.UPDATE_COMPANYPAYMENT_DETAILS,
    payload: data,
  };
}

export function updateCompanyPaymentDetailSuccess() {
  return {
    type: types.UPDATE_COMPANYPAYMENTDETAILS_SUCCESS,
  };
}

export function deleteCompanyDetail(data: any) {
  return {
    type: types.DELETE_COMPANY,
    payload: data,
  };
}

export function deleteCompanyDetailSuccess() {
  return {
    type: types.CREATE_COMPANY_SUCCESS,
  };
}

export function editCompanyDetail(data: any) {
  return {
    type: types.UPDATE_COMPANY,
    payload: data,
  };
}

export function editCompanyDetailSuccess() {
  return {
    type: types.UPDATE_COMPANY_SUCCESS,
  };
}

export function companyDetailsError(data: any) {
  return {
    type: types.SEND_REQUEST_ERROR,
    payload: data,
  };
}

export function createCompanyModal() {
  return {
    type: types.ADD_COMPANY_MODAL,
  };
}

export function updateCompanyModal() {
  return {
    type: types.EDIT_COMPANY_MODAL,
  };
}

export function getAllCompanyUsers(data: any) {
  return {
    type: types.GET_COMPANY_USERS,
    payload: data,
  };
}
export function getAllCompanyUsersSuccess(companyusers: any) {
  return {
    type: types.GET_COMPANY_USERS_SUCCESS,
    payload: companyusers,
  };
}
