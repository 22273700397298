import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Typography, Row, Col, Avatar, Button, Form, Input, Upload, notification } from 'antd';
import RightHeader from '../RightHeader';
import ProfilePicture from '../../image/avatar.png';
import jwt from 'jwt-decode';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getIndividualUser, updateUser } from '../../redux/actions/usersAction';
import EditProfile from './components/EditProfile';
import { CameraFilled } from '@ant-design/icons';
import LoadingSpinner from '../LoadingSpinner';

const { Text } = Typography;

const Container: any = styled.div`
    padding: 25px 60px 25px 60px;
`;
const HeaderContainer: any = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const LeftHeader: any = styled.div`
    display: flex;
    flex-direction: column;
`;
const BodyContainer: any = styled.div`
    margin-top: 35px;
    padding: 15px 0px 0px 0px;
    position: relative;
    display: flex;
    gap: 20px;
`;
const OuterImageConatiner = styled.div`
    height: 180px;
    width: 180px;
    border-radius: 50%;
    border: 3px dashed #3cb1ffbf;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -30%;
    left: 25%;
`;
const InnerImageContainer = styled.div`
    height: 160px;
    width: 160px;
    border-radius: 50%;
    border: 3px solid #3cb1ffbf;
    position: relative;
`;
const ProfileContainer = styled.div`
    height: 360px;
    width: 350px;
    background: transparent linear-gradient(143deg, #ffffff 0%, #eaf7ff 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 22px #008ce90a;
    border: 1px solid #008ce94d;
    position: relative;
    margin-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ProfileDetail = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 80px;
    height: 200px;
    width: 350px;
`;
const StyledText = styled(Text)`
    padding-bottom: 12px;
`;
const StyledButton = styled(Button)`
    background: transparent linear-gradient(99deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 25px 11px #008ce914;
    border-radius: 6px;
    color: white;
    height: 45px;
    width: 190px;
    margin-top: 7px;
    &:hover {
        background: transparent linear-gradient(99deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat;
        color: white;
    }
    &:focus {
        background: transparent linear-gradient(99deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat;
        color: white;
    }
`;
const ProfileForm = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 22px #008ce905;
    border: 1px solid #008ce930;
    height: 360px;
    margin-top: 120px;
    position: relative;
    flex-grow: 1;
`;
const FormHeader = styled.div`
    position: absolute;
    height: 70px;
    width: 82%;
    background: transparent linear-gradient(95deg, #3bb1ff 0%, #0099ff 100%) 0% 0% no-repeat padding-box;
    top: -7%;
    left: 9%;
    display: flex;
    flex-direction: column;
    padding: 10px 0px 10px 20px;
`;
const CameraContainer = styled.div`
    position: absolute;
    background-color: white;
    border: 1px solid #9cd2f7;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 63%;
    bottom: -2%;
    opacity: 100;
    cursor: pointer;
`;
const StyledCameraFilled = styled(CameraFilled)`
    color: #39b0ff;
`;

const StyledUpload = styled(Upload)`
    .ant-upload-list {
        display: none !important;
    }
`;
const ViewProfile = () => {
    let token: any = localStorage.getItem('token');
    let userId: any = localStorage.getItem('current');
    const dispatch = useDispatch();
    const user: any = jwt(token);
    const individualUser = useSelector((state: RootState) => state.users.individualUser);
    const { loading } = useSelector((state: RootState) => state.users);

    const handleImageChange = async (e: any) => {
        let length = e.fileList.length;
        if (length > 0) {
            const formData: any = new FormData();
            formData.append('id', individualUser.id);
            formData.append('firstName', individualUser.firstName);
            formData.append('middleName', individualUser.middleName);
            formData.append('lastName', individualUser.lastName);
            formData.append('userName', individualUser.userName);
            formData.append('email', individualUser.email);
            formData.append('phone', individualUser.phone);
            formData.append('countryId', individualUser.countryId);
            formData.append('file', e.fileList[length - 1].originFileObj);

            dispatch(
                updateUser({
                    data: formData,
                    onSuccess: () => {
                        notification.success({
                            message: 'Success',
                            description: 'Profile image changed successfully.',
                        });
                        dispatch(getIndividualUser({ params: { profileId: userId } }));
                    },
                    onFailure: () => {
                        notification.error({
                            message: 'Error',
                            description: 'Failed to change profile picture.',
                        });
                    },
                })
            );
        }
    };

    useEffect(() => {
        dispatch(getIndividualUser({ params: { profileId: userId } }));
    }, []);
    return (
        <Container>
            <HeaderContainer>
                <LeftHeader>
                    <Text style={{ fontSize: '30px', fontWeight: 600, color: '#434343' }}>Setting</Text>
                    <Text style={{ color: '#434343' }}>Monday,June16,2020</Text>
                </LeftHeader>
                <RightHeader />
            </HeaderContainer>
            <BodyContainer>
                <ProfileContainer>
                    <OuterImageConatiner>
                        <InnerImageContainer>
                            {individualUser.imageUrl ? (
                                <Avatar src={individualUser.imageUrl} size={154} />
                            ) : (
                                <Avatar src={ProfilePicture} size={154} />
                            )}
                            <StyledUpload
                                accept=".jpeg,.jpg,.png"
                                multiple={false}
                                maxCount={1}
                                name="File"
                                onChange={handleImageChange}
                                beforeUpload={() => false}
                            >
                                <CameraContainer>
                                    <StyledCameraFilled />
                                </CameraContainer>
                            </StyledUpload>
                        </InnerImageContainer>
                    </OuterImageConatiner>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <ProfileDetail>
                            <StyledText style={{ color: '#363636', fontSize: '20px', fontWeight: 600 }}>
                                {individualUser.firstName} {individualUser.lastName}
                            </StyledText>
                            <StyledText style={{ color: '#363636' }}>({individualUser.userName})</StyledText>
                            <StyledText style={{ color: '#008CE9' }}>{user.roles}</StyledText>
                            <StyledButton>Change Password</StyledButton>
                        </ProfileDetail>
                    )}
                </ProfileContainer>
                <ProfileForm>
                    <FormHeader>
                        <Text style={{ color: 'white', fontSize: '16px' }}>Edit Profile</Text>
                        <Text style={{ color: 'white', fontSize: '12px', paddingTop: '6px' }}>Complete your profile</Text>
                    </FormHeader>
                    <EditProfile individualUser={individualUser} />
                </ProfileForm>
            </BodyContainer>
        </Container>
    );
};
export default ViewProfile;
