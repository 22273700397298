import React from "react";
import { createFromIconfontCN } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Col, Progress, Row, Typography } from "antd";

import StyledCard from "../../components/card";
import WelcomeBanner from "../../components/WelcomeBanner";
import ChartComponent from "./LineChart";
import BarChart from "./BarChart";
import CandidateIcon from "../../image/candidate-icon.svg";
import MentorIcon from "../../image/mentor-icon.svg";
import CompanyIcon from "../../image/company-icon.svg";
import RightHeader from "../../components/RightHeader";

const { Text } = Typography;
const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2453178_q5r5ntd0lf.js",
});

const StyledProgress: any = styled(Progress)`
  .ant-progress-text {
    display: none;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;
const Container: any = styled.div`
  padding: 25px 50px 25px 50px;
`;
const HeaderContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LeftHeader: any = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyContainer: any = styled.div`
  margin-top: 35px;
  padding: 15px 0px 0px 0px;
`;
const ProjectOverview: any = styled.div`
  font-size: 24px;
  font-weight: 600;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a3f4ff;
  box-shadow: 0px 7px 21px #00000029;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;
const ProjectStatusContainer: any = styled.div`
  position: relative;
  margin-top: 25px;
  //   padding-top: 45px;
  height: 350px;
`;
const CandidateCard: any = styled(StyledCard)`
  padding: 20px 0;
  box-shadow: 0px 23px 30px #008ce908;
  border: 1px solid #008ce94d;
`;
const MentorCard: any = styled(StyledCard)`
  padding: 20px 0;
  box-shadow: 0px 23px 30px #008ce908;
  border: 1px solid #a3f4ff;
`;
const CompanyCard: any = styled(StyledCard)`
  padding: 20px 0;
  box-shadow: 0px 23px 30px #008ce908;
  border: 1px solid #e9d3ff;
`;
const CardContent: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const ChartContainer: any = styled.div`
  height: 480px;
  padding: 25px;
  box-shadow: 0px 15px 22px #008ce908;
  border: 1px solid #008ce94d;
  margin-bottom: 18px;
`;

const IconWrapper = styled("div")`
  display: grid;
  place-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

function Home() {
  const Today = new Date();
  const StringToday = Today.toDateString();
  return (
    <Container>
      <HeaderContainer>
        <LeftHeader>
          <Text style={{ fontSize: "28px", fontWeight: 600, color: "#434343" }}>Dashboard</Text>
          <Text style={{ color: "#434343" }}>{StringToday}</Text>
        </LeftHeader>
        <RightHeader />
      </HeaderContainer>
      <BodyContainer>
        <Row gutter={32}>
          <Col md={24} lg={24} xl={16} xxl={18}>
            <Row>
              <Col span={24}>
                <WelcomeBanner />
              </Col>
            </Row>
            <Row gutter={32} style={{ marginTop: "20px", paddingTop: "20px" }}>
              <Col span={8}>
                <CandidateCard>
                  <CardContent>
                    <IconWrapper style={{ backgroundColor: "#F0F8FF" }}>
                      <img src={CandidateIcon} alt="candidate icon" />
                    </IconWrapper>
                    <Text style={{ color: "#434343", fontSize: "43px" }}>05</Text>
                    <Text style={{ color: "#434343", fontSize: "16px" }}>Total Candidate</Text>
                  </CardContent>
                </CandidateCard>
              </Col>
              <Col span={8}>
                <MentorCard>
                  <CardContent>
                    <IconWrapper style={{ backgroundColor: "#E7FCFF" }}>
                      <img src={MentorIcon} alt="mentor icon" />
                    </IconWrapper>
                    <Text style={{ color: "#434343", fontSize: "43px" }}>10</Text>
                    <Text style={{ color: "#434343", fontSize: "16px" }}>Total Mentors</Text>
                  </CardContent>
                </MentorCard>
              </Col>
              <Col span={8}>
                <CompanyCard>
                  <CardContent>
                    <IconWrapper style={{ backgroundColor: "#F4E9FF" }}>
                      <img src={CompanyIcon} alt="company icon" />
                    </IconWrapper>
                    <Text style={{ color: "#434343", fontSize: "43px" }}>03</Text>
                    <Text style={{ color: "#434343", fontSize: "16px" }}>Total Company</Text>
                  </CardContent>
                </CompanyCard>
              </Col>
            </Row>
            <Row style={{ paddingTop: "20px", marginTop: "20px" }}>
              <Col span={24}>
                <ChartContainer>
                  <Text style={{ fontSize: "18px", fontWeight: 600, color: "#434343" }}>
                    Project Summary
                  </Text>
                  <ChartComponent />
                </ChartContainer>
              </Col>
            </Row>
          </Col>
          <Col md={24} lg={24} xl={8} xxl={6}>
            <Row>
              <Col span={24}>
                <div>
                  <StyledCard
                    style={{
                      height: "620px",
                      backgroundColor: "#f0f8ff",
                      border: "none",
                    }}
                  >
                    <Text style={{ fontSize: "20px", fontWeight: 620, color: "#434343" }}>
                      Project Status
                    </Text>
                    <ProjectStatusContainer>
                      <StyledProgress
                        type="circle"
                        percent={60}
                        width={250}
                        strokeColor="#00A2FF"
                        trailColor="white"
                      />

                      <StyledProgress
                        type="circle"
                        percent={27}
                        width={180}
                        strokeWidth={9}
                        strokeColor="#00DDFF"
                        trailColor="white"
                      />
                      <ProjectOverview>87%</ProjectOverview>
                    </ProjectStatusContainer>
                    <div
                      style={{
                        position: "absolute",
                        top: "70%",
                        width: "100%",
                      }}
                    >
                      <Text style={{ fontWeight: 600, paddingBottom: "35px", color: "#434343" }}>
                        Project Status
                      </Text>
                      <Row style={{ paddingTop: "25px" }}>
                        <Col span={2}>
                          <IconFont
                            type="icon-diamonds"
                            style={{ fontSize: "22px", color: "#169AF4", paddingTop: "3px" }}
                          />
                        </Col>
                        <Col span={16}>
                          <Text style={{ fontSize: "18px" }}>Completed projects</Text>
                        </Col>
                        <Col span={6}>
                          <Text style={{ fontSize: "18px", color: "#00A2FF" }}>60%</Text>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: "20px" }}>
                        <Col span={2}>
                          <IconFont
                            type="icon-diamonds"
                            style={{ fontSize: "22px", color: "#00DDFF", paddingTop: "3px" }}
                          />
                        </Col>
                        <Col span={16}>
                          <Text style={{ fontSize: "18px", color: "#434343" }}>
                            Ongoing projects
                          </Text>
                        </Col>
                        <Col span={6}>
                          <Text style={{ fontSize: "18px", color: "#00DDFF" }}>27%</Text>
                        </Col>
                      </Row>
                    </div>
                  </StyledCard>
                </div>
              </Col>
              <Col span={24}>
                <StyledCard
                  style={{ height: "373px", marginTop: "40px", border: "1px solid #008ce94d" }}
                >
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Text style={{ fontSize: "18px", fontWeight: 600, color: "#434343" }}>
                      Growth
                    </Text>
                    <Text style={{ color: "#323232" }}>68% this month</Text>
                  </div>
                  <BarChart />
                </StyledCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </BodyContainer>
    </Container>
  );
}

export default Home;
