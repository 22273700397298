import React, { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Typography, Row, Col } from "antd";
import Slider, { Settings } from "react-slick";
import moment, { Moment } from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const { Text } = Typography;

const CarouselWrap = styled.div``;

const OurCarousel = styled(Slider)`
  .itemWrap {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .itemDate {
    display: grid;
    place-items: center;
    height: 25px;
    width: 25px;
    margin-bottom: 5px;
    font-weight: 500;
  }

  .isToday {
    display: grid;
    place-items: center;
    background: #008ce9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 24px #2a5db445;
    opacity: 1;
    height: 25px;
    width: 25px;
    color: #ffffff;
    border-radius: 50%;
    margin-bottom: 5px;
  }

  .itemDay {
    text-align: center;
    font-weight: 500;
  }

  .anticon {
    font-size: 15px;
    z-index: 10;
    cursor: pointer;
    padding: 0;
    color: #4b4b4b;
    height: auto;
    width: auto;

    &:hover,
    &:focus {
      color: #242424;
    }

    &.slick-next {
      right: 0;
    }

    &.slick-prev {
      left: 0;
    }
  }
`;
const CellWrap = styled.div`
  cursor: pointer;
  height: 70px;
  border-right: 1px solid #00355e66;
  padding: 0px;
`;

const StyledLeftOutlined = styled(LeftOutlined)`
  &.anticon.slick-prev {
    font-size: 12px;
  }
`;
const StyledRightOutlined = styled(RightOutlined)`
  &.anticon.slick-next {
    font-size: 12px;
  }
`;
const HoursTableWrap = styled.div`
  height: 100%;
  border: 1px #008ce9bf solid;
  padding: 0px;
`;

const TotalHours = styled.div`
  height: 73px;
  width: 100%;
  background: #f8fdff 0% 0% no-repeat padding-box;
  box-shadow: -30px 0px 30px #0000000d;
  border: 1px solid #008ce9;
  display: flex;
  flex-items: center;
  align-items: center;
`;

export const settings: Settings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 7,
  slidesToScroll: 7,
  swipeToSlide: false,
  touchMove: false,
  prevArrow: <StyledLeftOutlined />,
  nextArrow: <StyledRightOutlined />,
  waitForAnimate: true,
};

const BillTable = (props: any) => {
  const { data } = props;
  const [allDates, setallDates] = useState<Array<Moment> | null>();
  const ourCarouselRef = useRef<Slider>();
  const tableCarouselRef = useRef<Slider>();
  const getAllDatesInMonth = () => {
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);
    let firstDate = moment(startDate, "DD/MM/YYYY");
    let secondDate = moment(endDate, "DD/MM/YYYY");
    let difference = secondDate.diff(firstDate, "days");

    let dates = new Array(difference + 1)
      .fill(null)
      .map((x, i) => moment(startDate).add(i, "days"));
    setallDates([...dates]);
  };
  useEffect(() => {
    getAllDatesInMonth();
  }, [data.hourlogDetails]);

  const getCurrentHourlog = (date: any) => {
    const newdate = new Date(date);
    const currentdate = data.hourlogDetails.filter((item: any) => {
      const newhourlogdate = new Date(item.day);
      return newhourlogdate.getDate() == newdate.getDate();
    });

    if (currentdate.length > 0) {
      return currentdate[0].hours;
    } else return null;
  };

  return (
    <React.Fragment>
      <Text style={{ fontSize: "16px", fontWeight: 600 }}>Timesheet Period</Text>
      <Row style={{ marginTop: "12px" }}>
        <Col span={21}>
          <CarouselWrap>
            <OurCarousel
              asNavFor={tableCarouselRef.current}
              ref={(c) => {
                if (c) ourCarouselRef.current = c;
              }}
              {...settings}
            >
              {allDates &&
                allDates.map((date, index) => (
                  <div key={index} className="itemWrap">
                    <div className="itemDate">{date.date()}</div>
                    <div className="itemDay">{date.format("ddd")}</div>
                  </div>
                ))}
            </OurCarousel>
          </CarouselWrap>
        </Col>
        <Col span={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Text style={{ fontSize: "17px", fontWeight: 600 }}>Hours</Text>
        </Col>
      </Row>
      <Row style={{ marginTop: "5px" }}>
        <Col span={21}>
          <HoursTableWrap>
            <Slider
              asNavFor={ourCarouselRef.current}
              ref={(c) => {
                if (c) tableCarouselRef.current = c;
              }}
              {...settings}
              arrows={false}
            >
              {allDates &&
                allDates.map((date, index) => (
                  <CellWrap key={index}>
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderLeft: `${index === 0 ? "1px solid #00355e66" : ""}`,
                      }}
                    >
                      <Text style={{ fontSize: "16px", fontWeight: 600 }}>
                        {getCurrentHourlog(date)}
                      </Text>
                    </div>
                  </CellWrap>
                ))}
            </Slider>
          </HoursTableWrap>
        </Col>
        <Col span={3}>
          <TotalHours>
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: "16px", fontWeight: 600 }}>{data.totalHours}</Text>
            </div>
          </TotalHours>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default BillTable;
