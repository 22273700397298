import {
  companyDetailsError,
  createCompanyDetailSuccess,
  deleteCompanyDetailSuccess,
  editCompanyDetailSuccess,
  getAllCompanyDetailsSuccess,
  getAllCompanyUsersSuccess,
  createCompanyPaymentDetailSuccess,
  updateCompanyPaymentDetailSuccess,
} from "../actions/companyAction";
import types from "../types";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import instance from "../../lib/axios/axiosInstance";
import { AxiosResponse } from "axios";
import { notification } from "antd";

function* fetchCompanyDetails(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/Company/GetAll", action.payload)
    );
    yield put(getAllCompanyDetailsSuccess(response.data));
  } catch (error) {
    yield put(companyDetailsError(error));
  }
}

function* createCompanyDetail(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.post("/api/v1.1/Company/Create", action.payload.data)
    );
    yield put(createCompanyDetailSuccess(response.data.data.id));
    yield call(action.payload.onSuccess);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put(companyDetailsError(error));
    notification.error({
      message: "Create Failed",
      description: errorMessage && errorMessage.description,
      duration: 2,
    });
  }
}

function* createCompanyPaymentDetails(action: any) {
  try {
    yield call(() => instance.post("/api/v1.1/Company/AddPayment", action.payload.data));
    yield put(createCompanyPaymentDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(companyDetailsError(error));
    notification.error({
      message: "Create Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* updateCompanyPaymentDetails(action: any) {
  try {
    yield call(() => instance.put("/api/v1.1/Mentor/UpdatePayment", action.payload.data));
    yield put(updateCompanyPaymentDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(companyDetailsError(error));
    notification.error({
      message: "Update Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* deleteCompanyDetail(action: any) {
  try {
    yield call(() => instance.delete("/api/v1.1/Company/Delete", action.payload));
    yield put(deleteCompanyDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(companyDetailsError(error));
    yield call(action.payload.onFailure);
    notification.error({
      message: "Delete Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* editCompanyDetail(action: any) {
  try {
    yield call(() => instance.put("/api/v1.1/Company/Update", action.payload.data));
    yield put(editCompanyDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put(companyDetailsError(error));
    notification.error({
      message: "Update Failed",
      description: errorMessage && errorMessage.description,
      duration: 2,
    });
  }
}
function* fetchCompanyUsersDetails(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/Company/GetCompanyUsers", action.payload)
    );
    yield put(getAllCompanyUsersSuccess(response.data));
  } catch (error) {
    yield put(companyDetailsError(error));
  }
}

export function* companyDetailsSaga() {
  yield takeEvery(types.GETALL_COMPANIES, fetchCompanyDetails);
  yield takeEvery(types.CREATE_COMPANY, createCompanyDetail);
  yield takeEvery(types.CREATE_COMPANYPAYMENT_DETAILS, createCompanyPaymentDetails);
  yield takeEvery(types.UPDATE_COMPANYPAYMENT_DETAILS, updateCompanyPaymentDetails);
  yield takeEvery(types.DELETE_COMPANY, deleteCompanyDetail);
  yield takeEvery(types.UPDATE_COMPANY, editCompanyDetail);
  yield takeEvery(types.GET_COMPANY_USERS, fetchCompanyUsersDetails);
}
