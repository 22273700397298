import React from "react";
import { Drawer } from "antd";
import styled from "@emotion/styled";

const StyledDrawer = styled(Drawer)`
  &.ant-drawer,
  &.ant-drawer-right,
  &.ant-drawer-open,
  &.no-mask {
    margin-top: 210px !important;
    height: 71vh;
    overflow-y: auto;
    margin-right: 58px;
  }
  .ant-drawer-close {
    margin: 25px 20px 0px 0px;
    color: white;
  }
  .ant-drawer-body {
    padding: 0px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 33px 53px #008ce91a;
    border: 1px solid #008ce94d;
  }
`;

const CustomDrawer = (props: any) => {
  return <StyledDrawer {...props}>{props.children}</StyledDrawer>;
};
export default CustomDrawer;
