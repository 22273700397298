import React, { useState, useEffect } from "react";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import styled from "@emotion/styled";
import { Button, Input, Modal, Popconfirm, Table, Typography, notification } from "antd";
import AddTechnologyStack from "./components/AddTechnologyStack";
import {
  getAllTechnologyStackDetails,
  deleteTechnologyStackDetail,
} from "../../redux/actions/technologyStackAction";
import RightHeader from "../../components/RightHeader";
import jwt from "jwt-decode";
import CustomPagination from "../../components/CustomPagination";
import CustomDrawer from "../../components/CustomDrawer";
import Description from "./components/Description";

const { Text } = Typography;
const { Search } = Input;

const Container: any = styled.div`
  padding: 25px 50px 25px 50px;
`;
const HeaderContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BodyContainer: any = styled.div`
  margin-top: 35px;
  padding: 15px 0px 0px 0px;
`;
const LeftHeader: any = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSearch = styled(Search)`
  .ant-input-wrapper {
    box-shadow: 0px 8px 10px #008ce91a;
    width: 280px;
    height: 30px;
    border-radius: 6px;
  }
  .ant-input {
    border: 1px solid #008ce940;
    border-radius: 6px;
    padding-top: 6px;
    color: #434343;
    height: 40px;
  }
  .ant-btn,
  .ant-btn-icon-only,
  .ant-input-search-button {
    border: none;
    box-shadow: none;
    margin-left: 2px;
  }

  .ant-input-group-addon {
    border: 1px solid #008ce940;
    border-left: none;
    border-radius: 6px;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #008ce940 !important ;
    border-left: none !important;
    background-color: white !important;
  }
  .ant-input-group input::placeholder {
    color: #434343;
    opacity: 0.7;
  }
`;
const AddButton = styled(Button)`
  background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 25px 11px #008ce914;
  border-radius: 6px;
  height: 40px;
  width: 175px;
  border: none;
  color: white;
  &:hover {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
`;
const EditContainer = styled(FormOutlined)`
  background: #e3f4ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 18px #008ce912;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #008ce9;
`;
const DeleteContainer = styled(DeleteOutlined)`
  background: #ffe9e9 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 7px #ff00000d;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000;
`;

const StyledTable = styled(Table)`
  .ant-table-row,
  .ant-table-row-level-0 {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000008;
    // border: 1px solid #008ce933;
  }
  .ant-table-thead > tr > th {
    background: #f9fcff 0% 0% no-repeat padding-box;
    font-size: 16px;
  }
  .ant-table-tbody > tr > td {
    // border-bottom: 1px solid #008ce930;
  }
  .ant-table-row {
    box-shadow: 1px 1px 10px #1c3faa1a !important;
  }
  .ant-table-tbody > tr:last-child {
    & > td {
      border-bottom: none;
    }
  }
`;
const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close-x {
    color: white;
  }
`;

const TechnologyStack = () => {
  const [editItem, setEditItem] = useState(null);
  const [visible, setVisible] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const dispatch = useDispatch();
  const { allTechnologyStacks, loading } = useSelector(
    (state: RootState) => state.technologyStacks
  );

  let token: any = localStorage.getItem("token");
  const user: any = jwt(token);

  useEffect(() => {
    dispatch(getAllTechnologyStackDetails({ params: { PageNumber: 1, PageSize: 10 } }));
  }, [dispatch]);

  useEffect(() => {
    if (searchItem === "") {
      dispatch(
        getAllTechnologyStackDetails({
          params: {
            PageNumber: (allTechnologyStacks.data && allTechnologyStacks.data.pageNumber) || 1,
            PageSize: (allTechnologyStacks.data && allTechnologyStacks.data.pageSize) || 10,
          },
        })
      );
    }
  }, [searchItem]);

  const editTechnologyStack = (item: any) => {
    setVisible(true);
    setEditItem(item);
  };

  const deleteTechnologyStack = (id: any) => {
    const isLastElement = allTechnologyStacks.data.results.length === 1;
    if (isLastElement && allTechnologyStacks.data.totalCount === 1) {
      setSearchItem("");
    }
    dispatch(
      deleteTechnologyStackDetail({
        params: { id },
        onSuccess: () => {
          setVisible(false);
          notification.success({
            message: "Success",
            description: "Technologystack has been successfully deleted.",
            duration: 2,
          });
          dispatch(
            getAllTechnologyStackDetails({
              params: {
                PageNumber:
                  isLastElement && allTechnologyStacks.data.totalCount > 1
                    ? allTechnologyStacks.data.pageNumber - 1
                    : allTechnologyStacks.data.pageNumber,
                pageSize: allTechnologyStacks.data.pageSize,
                TechStackName: allTechnologyStacks.data.totalCount === 1 ? null : searchItem,
              },
            })
          );
        },
      })
    );
  };

  const handleTableChange = (current: any, pageSize: any) => {
    dispatch(
      getAllTechnologyStackDetails({
        params: { PageNumber: current, PageSize: pageSize, TechStackName: searchItem },
      })
    );
  };

  const onSearch = (value: any) => {
    dispatch(
      getAllTechnologyStackDetails({
        params: {
          PageNumber: 1,
          PageSize: 10,
          TechStackName: value,
        },
      })
    );
  };

  const onChangeSearch = (e: any) => {
    setSearchItem(e.target.value);
  };

  const columns = [
    {
      title: "Technology Stack",
      dataIndex: "name",
      key: "name",
      render: (name: any) => <div style={{ width: "250px" }}>{name}</div>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description: any) => (
        <div style={{ width: "350px" }}>
          <Description description={description} />
        </div>
      ),
    },
    user.roles === "Super Admin"
      ? {}
      : {
          title: "Action",
          dataIndex: "id",
          key: "x",
          render: (item: any, record: any) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <EditContainer onClick={() => editTechnologyStack(record)}>
                <FormOutlined />
              </EditContainer>
              <Popconfirm
                title="Are you sure to delete this TechnologyStack?"
                onConfirm={() => deleteTechnologyStack(item)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteContainer>
                  <DeleteOutlined />
                </DeleteContainer>
              </Popconfirm>
            </div>
          ),
        },
  ];

  const Today = new Date();
  const StringToday = Today.toDateString();

  return (
    <Container>
      <HeaderContainer>
        <LeftHeader>
          <Text style={{ fontSize: "28px", fontWeight: 600, color: "#434343" }}>
            Technology Stack
          </Text>
          <Text style={{ color: "#434343" }}>{StringToday}</Text>
        </LeftHeader>
        <RightHeader />
      </HeaderContainer>
      <BodyContainer>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <StyledSearch
            placeholder="Search by technology stack"
            onChange={onChangeSearch}
            onSearch={onSearch}
          />
          {user.roles === "Super Admin" ? (
            <></>
          ) : (
            <AddButton
              style={{ marginLeft: "18px", width: "220px" }}
              onClick={() => {
                setVisible(!visible);
                setEditItem(null);
              }}
            >
              + Add TechnologyStack
            </AddButton>
          )}
        </div>
        <div
          style={{
            border: "1px solid #008ce930",
            boxShadow: "0px 20px 22px #008ce905",
            marginTop: "25px",
          }}
        >
          <StyledTable
            columns={columns}
            dataSource={
              allTechnologyStacks && allTechnologyStacks.data && allTechnologyStacks.data.results
            }
            pagination={false}
            loading={loading}
          />
        </div>
        <CustomPagination
          size="small"
          total={
            allTechnologyStacks && allTechnologyStacks.data && allTechnologyStacks.data.totalCount
          }
          current={
            allTechnologyStacks && allTechnologyStacks.data && allTechnologyStacks.data.pageNumber
          }
          defaultPageSize={10}
          defaultCurrent={1}
          showSizeChanger={true}
          onChange={handleTableChange}
        />
        <CustomDrawer
          title=""
          placement="right"
          mask={false}
          onClose={() => setVisible(false)}
          visible={visible}
          width={465}
          destroyOnClose={true}
        >
          <AddTechnologyStack setVisible={setVisible} editItem={editItem} searchItem={searchItem} />
        </CustomDrawer>
      </BodyContainer>
    </Container>
  );
};
export default TechnologyStack;
