import types from "../types";

const initialState = {
  loading: false,
  allTechnologyStacks: [],
  error: null,
};

const technologyStackReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.UPDATE_TECHNOLOGYSTACK:
    case types.DELETE_TECHNOLOGYSTACK:
    case types.CREATE_TECHNOLOGYSTACK:
    case types.GETALL_TECHNOLOGYSTACKS:
      return {
        ...state,
        loading: true,
      };
    case types.GETALL_TECHNOLOGYSTACKS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTechnologyStacks: action.payload,
        error: null,
      };
    case types.CREATE_TECHNOLOGYSTACK_SUCCESS:
    case types.DELETE_TECHNOLOGYSTACK_SUCCESS:
    case types.UPDATE_TECHNOLOGYSTACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.TECHNOLOGYSTACK_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default technologyStackReducer;
