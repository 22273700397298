import types from '../types';

export function getAllTechnologyStackDetails(data:any) {
  return {
    type: types.GETALL_TECHNOLOGYSTACKS,
    payload: data,
  };
}

export function getAllTechnologyStackDetailsSuccess(techstacks:any) {
  return {
    type: types.GETALL_TECHNOLOGYSTACKS_SUCCESS,
    payload: techstacks,
  };
}

export function createTechnologyStackDetail(data:any) {
  return {
    type: types.CREATE_TECHNOLOGYSTACK,
    payload: data,
  };
}

export function createTechnologyStackDetailSuccess() {
  return {
    type: types.CREATE_TECHNOLOGYSTACK_SUCCESS,
  };
}

export function deleteTechnologyStackDetail(data:any) {
  return {
    type: types.DELETE_TECHNOLOGYSTACK,
    payload: data,
  };
}

export function deleteTechnologyStackDetailSuccess() {
  return {
    type: types.DELETE_TECHNOLOGYSTACK_SUCCESS,
  };
}

export function editTechnologyStackDetail(data:any) {
  return {
    type: types.UPDATE_TECHNOLOGYSTACK,
    payload: data,
  };
}

export function editTechnologyStackDetailSuccess() {
  return {
    type: types.UPDATE_TECHNOLOGYSTACK_SUCCESS,
  };
}

export function technologyStackDetailsError(data:any) {
  return {
    type: types.TECHNOLOGYSTACK_REQUEST_ERROR,
    payload: data,
  };
}
