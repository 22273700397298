import React, { useEffect } from "react";
import { Typography, Button, DatePicker, Form } from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 36px;
`;
const StyledRangePicker = styled(RangePicker)`
  box-shadow: 0px 25px 11px #008ce90d;
  border-radius: 6px;
  height: 36px;
  width: 230px;

  &.ant-picker,
  &.ant-picker-disabled {
    border: 1px solid #008ce940;
    background: #ffffff73 0% 0% no-repeat padding-box;
  }
  .ant-picker-input > input[disabled] {
    color: #323232;
    opacity: 1;
  }
`;

const ProjectContainer = (props: any) => {
  const { data } = props;
  const [form] = useForm();
  const ProjectStartDate = new Date(data && data.projectStartDate);
  const ProjectStartDateFormatted = ProjectStartDate.toDateString();
  const ProjectEndDate =
    data && data.projectEndDate === null ? null : new Date(data && data.projectEndDate);
  const ProjectEndDateFormatted = ProjectEndDate === null ? null : ProjectEndDate.toDateString();

  useEffect(() => {
    form.setFieldsValue({ daterange: [moment(data.startDate), moment(data.endDate)] });
  }, [data]);

  return (
    <React.Fragment>
      <StyledContainer>
        <StyledHeader>
          <Text style={{ fontSize: "19px", fontWeight: 600 }}>Project</Text>
          <Text style={{ fontSize: "15px" }}>
            {ProjectStartDateFormatted}-{ProjectEndDateFormatted}
          </Text>
        </StyledHeader>
        <Form form={form}>
          <Form.Item name="daterange">
            <StyledRangePicker format="MM-DD-YYYY" disabled={true} />
          </Form.Item>
        </Form>
      </StyledContainer>
    </React.Fragment>
  );
};
export default ProjectContainer;
