import types from '../types';

export function getAllCountries(data: any) {
  return {
    type: types.GETALL_COUNTRIES,
    payload: data,
  };
}

export function getAllCountriesSuccess(countries: any) {
  return {
    type: types.GETALL_COUNTRIES_SUCCESS,
    payload: countries,
  };
}

export function countryRequestError(data: any) {
  return {
    type: types.COUNTRY_REQUEST_ERROR,
    payload: data,
  };
}
