import types from '../types';

const initialState = {
    loading: false,
    projects: [],
    error: null,
    projectById: {},
    projectStatus: {},
    projectByMentorId: [],
};

const projectReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.GETALL_PROJECTS:
        case types.CREATE_PROJECT:
        case types.DELETE_PROJECT:
        case types.UPDATE_PROJECT:
        case types.GET_PROJECTS_BYID:
        case types.GET_PROJECTS_BY_MENTORID:
        case types.GET_PROJECT_STATUS:
        case types.UPDATE_PROJECT_STATUS:
            return {
                ...state,
                loading: true,
            };
        case types.GETALL_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                projects: action.payload,
                error: null,
            };
        case types.GET_PROJECTS_BY_MENTORID_SUCCESS:
            return {
                ...state,
                loading: false,
                projectByMentorId: action.payload,
                error: null,
            };
        case types.GET_PROJECTSTATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                projectStatus: action.payload,
                error: null,
            };
        case types.CREATE_PROJECT_SUCCESS:
        case types.DELETE_PROJECT_SUCCESS:
        case types.UPDATE_PROJECT_SUCCESS:
        case types.UPDATE_PROJECTSTATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case types.GET_PROJECTSBYID_SUCCESS:
            return {
                ...state,
                loading: false,
                projectById: action.payload,
                error: null,
            };
        case types.PROJECT_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return {
                ...state,
            };
    }
};

export default projectReducer;
