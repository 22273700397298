export const candidateTypes = {
  GETALL_CANDIDATES: 'GETALL_CANDIDATES',
  GETALL_CANDIDATES_SUCCESS: 'GETALL_CANDIDATES_SUCCESS',

  CREATE_CANDIDATE: 'CREATE_CANDIDATE',
  CREATE_CANDIDATE_SUCCESS: 'CREATE_CANDIDATE_SUCCESS',

  UPDATE_CANDIDATE: 'UPDATE_CANDIDATE',
  UPDATE_CANDIDATE_SUCCESS: 'UPDATE_CANDIDATE_SUCCESS',

  DELETE_CANDIDATE: 'DELETE_CANDIDATE',
  DELETE_CANDIDATE_SUCCESS: 'DELETE_CANDIDATE_SUCCESS',

  CANDIDATE_REQUEST_ERROR: 'CANDIDATE_REQUEST_ERROR',

  CANDIDATE_STATUS_CHANGE:'CANDIDATE_STATUS_CHANGE',
  CANDIDATE_STATUSCHANGE_SUCCESS:'CANDIDATE_STATUSCHANGE_SUCCESS',

  CANDIDATE_MENTOR_DETAILS: 'CANDIDATE_MENTOR_DETAILS',
  CANDIDATE_MENTOR_DETAILS_SUCCESS: 'CANDIDATE_MENTOR_DETAILS_SUCCESS',
};
