import { getAllRolesSuccess, roleRequestError } from '../actions/rolesAction';
import types from '../types';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import instance from '../../lib/axios/axiosInstance';
import { AxiosResponse } from 'axios';

function* getAllRoles(action: any) {
    try {
        const response: AxiosResponse = yield call(() => instance.get('/api/v1.1/Account/GetAll', action.payload));
        yield put(getAllRolesSuccess(response.data));
    } catch (error) {
        yield put(roleRequestError(error));
    }
}

export function* rolesSaga() {
    yield takeEvery(types.GETALL_ROLES, getAllRoles);
}
