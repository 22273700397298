import { all } from "redux-saga/effects";

import { countriesSaga } from "./countriesSaga";
import { rolesSaga } from "./rolesSaga";
import { mentorDetailsSaga } from "./mentorSaga";
import { usersSaga } from "./usersSaga";
import { candidateDetailsSaga } from "./candidateSaga";
import { technologyStackDetailsSaga } from "./technologyStackSaga";
import { companyDetailsSaga } from "./companySaga";
import { projectSaga } from "./projectSaga";
import { mentorHourLogSaga } from "./mentorHourLogsSaga";
import { timesheetSaga } from "./timesheetSaga";
import { invoiceSaga } from "./invoiceSaga";

export default function* RootSaga() {
  yield all([
    usersSaga(),
    rolesSaga(),
    countriesSaga(),
    mentorDetailsSaga(),
    candidateDetailsSaga(),
    companyDetailsSaga(),
    technologyStackDetailsSaga(),
    projectSaga(),
    mentorHourLogSaga(),
    timesheetSaga(),
    invoiceSaga(),
  ]);
}
