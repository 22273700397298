import {
  getAllInvoicesSuccess,
  invoiceDetailsError,
  generateInvoiceSuccess,
  generateInvoices,
  getInvoiceByIdSuccess,
  invoiceMarkAsPaidError,
  invoiceMarkAsPaidSuccess,
} from "../actions/invoiceAction";
import types from "../types";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import instance from "../../lib/axios/axiosInstance";
import { AxiosResponse } from "axios";
import { notification } from "antd";

function* fetchInvoices(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/Invoice/GetAll", action.payload)
    );
    yield put(getAllInvoicesSuccess(response.data));
  } catch (error) {
    yield put(invoiceDetailsError(error));
  }
}

function* fetchInvoiceById(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/Invoice/GetById", action.payload)
    );
    yield put(getInvoiceByIdSuccess(response.data));
  } catch (error) {
    yield put(invoiceDetailsError(error));
  }
}

function* generateInvoice(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.post("/api/v1.1/Invoice/Create", action.payload.invoiceData)
    );
    yield put(generateInvoiceSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(invoiceDetailsError(error));
    notification.error({
      message: "Create Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* markAsPaidInvoice(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.put(
        "/api/v1.1/Invoice/ChangeIsPaidStatus",
        action.payload.markAsPaidData
      )
    );
    yield put(invoiceMarkAsPaidSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(invoiceMarkAsPaidError(error));
    notification.error({
      message: "Request Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

export function* invoiceSaga() {
  yield takeEvery(types.GETALL_INVOICES, fetchInvoices);
  yield takeEvery(types.GET_INVOICE_BYID, fetchInvoiceById);
  yield takeEvery(types.GENERATE_INVOICE, generateInvoice);
  yield takeEvery(types.INVOICE_MARKASPAID_REQUEST, markAsPaidInvoice);
}
