import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMentorDetail,
  getAllMentorDetails,
} from "../../redux/actions/mentorAction";
import { RootState } from "../../redux/store";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Button,
  Input,
  Popconfirm,
  Table,
  Typography,
  notification,
  Tag,
  Row,
  Col,
  Badge,
} from "antd";
import AddMentor from "./components/AddMentor";
import StatusChange from "./components/StatusChange";
import RightHeader from "../../components/RightHeader";
import jwtDecode from "jwt-decode";
import CustomPagination from "../../components/CustomPagination";
import CustomDrawer from "../../components/CustomDrawer";
import CreateForm from "./components/CreateForm";

const { Text } = Typography;
const { Search } = Input;

const Container: any = styled.div`
  padding: 25px 50px 25px 50px;
`;
const HeaderContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BodyContainer: any = styled.div`
  margin-top: 35px;
  padding: 15px 0px 0px 0px;
`;
const LeftHeader: any = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledSearch = styled(Search)`
  .ant-input-wrapper {
    box-shadow: 0px 8px 10px #008ce91a;
    width: 280px;
    height: 30px;
    border-radius: 6px;
  }
  .ant-input {
    border: 1px solid #008ce940;
    border-radius: 6px;
    padding-top: 6px;
    color: #434343;
    height: 40px;
  }
  .ant-btn,
  .ant-btn-icon-only,
  .ant-input-search-button {
    border: none;
    box-shadow: none;
    margin-left: 2px;
  }

  .ant-input-group-addon {
    border: 1px solid #008ce940;
    border-left: none;
    border-radius: 6px;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #008ce940 !important ;
    border-left: none !important;
    background-color: white !important;
  }
  .ant-input-group input::placeholder {
    color: #434343;
    opacity: 0.7;
  }
`;
const AddUserButton = styled(Button)`
  background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 8px 10px #008ce914;
  border-radius: 6px;
  height: 40px;
  width: 175px;
  border: none;
  color: white;
  &:hover {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0%
      0% no-repeat padding-box;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0%
      0% no-repeat padding-box;
    color: white;
  }
`;
const EditContainer = styled(FormOutlined)`
  background: #e3f4ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 18px #008ce912;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #008ce9;
`;
const DeleteContainer = styled(DeleteOutlined)`
  background: #ffe9e9 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 7px #ff00000d;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000;
`;

const StyledTable = styled(Table)`
  .ant-table-row,
  .ant-table-row-level-0 {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000008;
    // border: 1px solid #008ce933;
  }
  .ant-table-thead > tr > th {
    background: #f9fcff 0% 0% no-repeat padding-box;
    font-size: 16px;
  }
  .ant-table-tbody > tr > td {
    // border-bottom: 1px solid #008ce930;
  }
  .ant-table-row {
    box-shadow: 1px 1px 10px #1c3faa1a !important;
  }
  .ant-table-tbody > tr:last-child {
    & > td {
      border-bottom: none;
    }
  }
`;
const ButtonWrapper = styled(Row)`
  margin-top:20px;
  .activeBtn {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat  
         padding-box;
    color: white;
  }`;

const StyledButton = styled(Button)`
  background: #80808066;
  box-shadow: 0px 8px 10px #008ce914;
  border-radius: 6px;
  width: 100px;
  height: 40px;`;

function Mentor() {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(true);
  const [editItem, setEditItem] = useState(null);
  const [searchItem, setSearchItem] = useState("");
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [visible, setVisible] = useState(false);
  const allMentors = useSelector((state: RootState) => state.mentors.mentors);
  const loading = useSelector((state: RootState) => state.mentors.loading);
  let token: any = localStorage.getItem("token");
  const user: any = jwtDecode(token);

  useEffect(() => {
    dispatch(getAllMentorDetails({ params: { PageNumber: 1, PageSize: 10, isActive:isActive } }));
  }, [dispatch]);

  useEffect(() => {
    if (searchItem === "") {
      dispatch(
        getAllMentorDetails({
          params: {
            PageNumber: (allMentors.data && allMentors.data.pageNumber) || 1,
            PageSize: (allMentors.data && allMentors.data.pageSize) || 10,
            isActive: isActive
          },
        })
      );
    }
  }, [searchItem]);

  useEffect(() => {
    dispatch(
      getAllMentorDetails({
        params: {
          PageNumber: 1,
          PageSize: 10,
          Name: searchItem,
          isActive: isActive
        },
      })
    );
  }, [isActive]);

  const editMentorDetails = (item: any) => {
    setVisible(true);
    setEditItem(item);
  };

  const handleDeleteMentorDetails = (id: any) => {
    const isLastElement = allMentors.data.results.length === 1;
    if (isLastElement && allMentors.data.totalCount === 1) {
      setSearchItem("");
    }
    dispatch(
      deleteMentorDetail({
        params: { id },
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "Mentor has been successfully deleted.",
            duration: 2,
          });
          dispatch(
            getAllMentorDetails({
              params: {
                PageNumber:
                  isLastElement && allMentors.data.totalCount > 1
                    ? allMentors.data.pageNumber - 1
                    : allMentors.data.pageNumber,
                pageSize: allMentors.data.pageSize,
                Name: allMentors.data.totalCount === 1 ? null : searchItem,
                isActive: isActive
              },
            })
          );
        },
      })
    );
  };

  const handleTableChange = (current: any, pageSize: any) => {
    dispatch(
      getAllMentorDetails({
        params: { PageNumber: current, PageSize: pageSize, Name: searchItem, isActive: isActive },
      })
    );
  };

  const onSearch = (value: any) => {
    dispatch(
      getAllMentorDetails({
        params: {
          PageNumber: 1,
          PageSize: 10,
          Name: value,
          isActive: isActive
        },
      })
    );
  };

  const onChangeSearch = (e: any) => {
    setSearchItem(e.target.value);
  };
  const columns = [
    {
      title: "Mentor",
      dataIndex: "firstName",
      key: "firstName",
      render: (firstname: any, record: any) => (
        <span>
          {firstname} {record.middleName} {record.lastName}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Company",
      dataIndex: "companyDetailForMentor",
      key: "companyDetailForMentor",
      render: (companydetail: any) => (
        <React.Fragment>
          {companydetail === null ? null : companydetail.name}
        </React.Fragment>
      ),
    },
    {
      title: "Technology Stack",
      dataIndex: "technologyStacks",
      key: "technologyStacks",
      render: (technologystack: any) => (
        <React.Fragment>
          {technologystack.map((item: any) => (
            <Tag
              key={item.techStackId}
              color="#E3F4FF"
              style={{
                marginTop: "5px",
                border: "1px solid #008CE940",
                padding: "3px",
              }}
            >
              <Text style={{ color: "black", padding: "6px" }}>
                {" "}
                {item.techStackName}
              </Text>
            </Tag>
          ))}
        </React.Fragment>
      ),
    },
    {
      title: "Address",
      dataIndex: "street",
      render: (street:any, record: any) => (
        <span> {record.street && record.street + ", "} {record.city && record.city + ", "} {record.state && record.state + ", "} {record.zipCode && record.zipCode + ", "} {record.countryDetailForMentor.name}</span>
      )
    },
    { title: "Phone Number", dataIndex: "phone", key: "phone" },
    { title: "Rate", dataIndex: "rate", key: "rate" },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "x",
      render: (isActive: any, record: any) => (
        <div>
          <StatusChange
            item={isActive}
            record={record}
            searchItem={searchItem}
            isActive={isActive}
          />
        </div>
      ),
    },
    user.roles === "Super Admin" || user.roles === "Account"
      ? {}
      : {
          title: "Action",
          dataIndex: "id",
          key: "id",
          render: (item: any, record: any) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <EditContainer onClick={() => editMentorDetails(record)}>
                <FormOutlined style={{ color: "red" }} />
              </EditContainer>
              <Popconfirm
                title="Are you sure to delete this Mentor Detail?"
                onConfirm={() => handleDeleteMentorDetails(item)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteContainer>
                  <DeleteOutlined />
                </DeleteContainer>
              </Popconfirm>
            </div>
          ),
        },
  ];

  const Today = new Date();
  const StringToday = Today.toDateString();

  return (
    <Container>
      <HeaderContainer>
        <LeftHeader>
          <Text style={{ fontSize: "28px", fontWeight: 600, color: "#434343" }}>
            Mentor
          </Text>
          <Text style={{ color: "#434343" }}>{StringToday}</Text>
        </LeftHeader>
        <RightHeader />
      </HeaderContainer>
      <BodyContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <StyledSearch
            placeholder="Search by mentor"
            onChange={onChangeSearch}
            onSearch={onSearch}
          />

          {user.roles === "Super Admin" || user.roles === "Account" ? (
            <></>
          ) : (
            <AddUserButton
              style={{ paddingLeft: "18px" }}
              onClick={() => {
                setVisible(!visible);
                setEditItem(null);
              }}
            >
              + Add Mentor
            </AddUserButton>
          )}
        </div>
        <ButtonWrapper>
          <Col>
            <Badge
              count={
                allMentors && allMentors.data && allMentors.data.activeCount
              }
              overflowCount={1000}
            >
              <StyledButton
                className={isActive ? "activeBtn" : " "}
                onClick={() => setIsActive(true)}
              >
                Active
              </StyledButton>
            </Badge>
            <Badge
              count={
                allMentors && allMentors.data && allMentors.data.inactiveCount
              }
              overflowCount={1000}
            >
              <StyledButton
                style={{ marginLeft: "18px" }}
                className={!isActive ? "activeBtn" : " "}
                onClick={() => setIsActive(false)}
              >
                Inactive
              </StyledButton>
            </Badge>
          </Col>
        </ButtonWrapper>
        <div
          style={{
            border: "1px solid #008ce930",
            boxShadow: "0px 20px 22px #008ce905",
            marginTop: "25px",
          }}
        >
          <StyledTable
            columns={columns}
            dataSource={
              allMentors && allMentors.data && allMentors.data.results
            }
            pagination={false}
            loading={loading}
          />
        </div>
        <CustomPagination
          size="small"
          total={allMentors && allMentors.data && allMentors.data.totalCount}
          current={allMentors && allMentors.data && allMentors.data.pageNumber}
          defaultPageSize={10}
          defaultCurrent={1}
          showSizeChanger={true}
          onChange={handleTableChange}
        />
        <CustomDrawer
          title=""
          placement="right"
          mask={false}
          onClose={() => {
            setVisible(false);
            setDisabled(true);
          }}
          visible={visible}
          width={470}
          destroyOnClose={true}
        >
          <CreateForm
            editItem={editItem}
            setVisible={setVisible}
            searchItem={searchItem}
            allMentors={allMentors}
            isDisabled={isDisabled}
            setDisabled={setDisabled}
            isActive={isActive }
          />
        </CustomDrawer>
      </BodyContainer>
    </Container>
  );
}
export default Mentor;
