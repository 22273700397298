import React, { useContext } from "react";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { ProjectHourLogContext } from "../../../components/ContextComponent";
import { getAllMentorHourLogs, saveHourLog } from "../../../redux/actions/mentorHourLogAction";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import jwt from "jwt-decode";

const SaveHourLogs = (props: any) => {
  const { showDrawer } = props;
  const { record, setRecord, startDate, endDate, searchValue } = useContext(ProjectHourLogContext);
  const dispatch = useDispatch();

  let token: any = localStorage.getItem("token");
  const user: any = jwt(token);

  const handleSave = () => {
    let data = record;
    dispatch(
      saveHourLog({
        data,
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "Hours has been saved.",
            duration: 2,
          });

          dispatch(
            getAllMentorHourLogs({
              params: {
                StartDate: startDate,
                EndDate: endDate,
                MentorOrCandidateName: searchValue,
              },
            })
          );
          setRecord([]);
        },
      })
    );
  };
  return (
    <React.Fragment>
      {user && user.roles === "Mentor Admin" && (
        <ContextMenu id="projecthourlogcontext" hideOnLeave={true}>
          <MenuItem onClick={handleSave}>Save Entries</MenuItem>
          <MenuItem onClick={showDrawer}>Create Timesheet</MenuItem>
        </ContextMenu>
      )}
    </React.Fragment>
  );
};

export default SaveHourLogs;
