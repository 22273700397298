import {
  technologyStackDetailsError,
  createTechnologyStackDetailSuccess,
  deleteTechnologyStackDetailSuccess,
  editTechnologyStackDetailSuccess,
  getAllTechnologyStackDetailsSuccess,
} from "../actions/technologyStackAction";
import types from "../types";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import instance from "../../lib/axios/axiosInstance";

import { AxiosResponse } from "axios";
import { notification } from "antd";

function* fetchTechnologyStackDetails(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/TechnologyStack/GetAll", action.payload)
    );
    yield put(getAllTechnologyStackDetailsSuccess(response.data));
  } catch (error) {
    yield put(technologyStackDetailsError(error));
  }
}

function* createTechnologyStackDetails(action: any) {
  try {
    yield call(() => instance.post("/api/v1.1/TechnologyStack/Create", action.payload.data));
    yield put(createTechnologyStackDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(technologyStackDetailsError(error));
    yield call(action.payload.onFailure);
    notification.error({
      message: "Create Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* deleteTechnologyStackDetails(action: any) {
  try {
    yield call(() => instance.delete("/api/v1.1/TechnologyStack/Delete", action.payload));
    yield put(deleteTechnologyStackDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(technologyStackDetailsError(error));
    notification.error({
      message: "Delete Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* editTechnologyStackDetails(action: any) {
  try {
    yield call(() => instance.put("/api/v1.1/TechnologyStack/Update", action.payload.data));
    yield put(editTechnologyStackDetailSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(technologyStackDetailsError(error));
    yield call(action.payload.onFailure);
    notification.error({
      message: "Update Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

export function* technologyStackDetailsSaga() {
  yield takeEvery(types.GETALL_TECHNOLOGYSTACKS, fetchTechnologyStackDetails);
  yield takeEvery(types.CREATE_TECHNOLOGYSTACK, createTechnologyStackDetails);
  yield takeEvery(types.DELETE_TECHNOLOGYSTACK, deleteTechnologyStackDetails);
  yield takeEvery(types.UPDATE_TECHNOLOGYSTACK, editTechnologyStackDetails);
}
