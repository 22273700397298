import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCandidateDetail,
  editCandidateDetail,
  getAllCandidateDetails,
} from "../../../redux/actions/candidateActions";
import { createFromIconfontCN } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Col, Form, Input, Row, Typography, Upload, Select, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { getAllCompanyDetails } from "../../../redux/actions/companyAction";
import { RootState } from "../../../redux/store";
import jwt from "jwt-decode";
import { Option } from "antd/lib/mentions";
import { getAllCountries } from "../../../redux/actions/countriesAction";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2453178_s80bobnfq9p.js",
});

const Container = styled.div`
  background: transparent linear-gradient(98deg, #3bb1ff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  width: 465px;
  height: 94px;
`;
const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 22px 22px 45px;
`;
const ModalBody = styled.div`
  padding: 35px;
  background-color: white;
`;
const PictureConatiner = styled.div`
  margin-top: 16px;
`;
const AddButton = styled(Button)`
  background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 3px;
  color: white;
  width: 100%;
  margin-top: 30px;
  &:hover {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat;
    color: white;
  }
`;
const StyledInput = styled(Input)`
  font-size: 13px;
  margin-bottom: 5px;
`;

const StyledSelect = styled(Select)`
  font-size: 13px;
  margin-bottom: 5px;
`;
const StyledForm = styled(Form)``;

const { Text } = Typography;

interface IAddCompanyProps {
  setVisible: (visible: boolean) => void;
  editItem: any;
  allCandidates: any;
  searchItem: any;
  isActive:boolean;
}

function AddCandidate(props: IAddCompanyProps) {
  const { setVisible, editItem, allCandidates, searchItem, isActive } = props;
  const [form] = useForm();
  const dispatch = useDispatch();
  let token: any = localStorage.getItem("token");
  const user: any = jwt(token);
  const newUser = user.roles.split(" ").join("").toUpperCase();
  console.log(editItem);

  useEffect(() => {
    dispatch(getAllCompanyDetails({ params: { role: newUser } }));
    dispatch(getAllCountries({}));
  }, [dispatch]);

  const allCompanies = useSelector((state: RootState) => state.companies.allCompanies);
  const { countries } = useSelector((state: RootState) => state.countries);

  const handleAddSubmit = (value: any) => {
    const data = value;
    data["createrId"] = localStorage.getItem("current");

    dispatch(
      createCandidateDetail({
        data,
        onSuccess: () => {
          setVisible(false);
          notification.success({
            message: "Success",
            description: "Candidate has been successfully created.",
            duration: 2,
          });
          dispatch(
            getAllCandidateDetails({
              params: {
                PageNumber: allCandidates.data.pageNumber,
                pageSize: allCandidates.data.pageSize,
                isActive:isActive
              },
            })
          );
        },
      })
    );
  };

  const handleEditSubmit = (value: any) => {
    const data = value;
    value.id = editItem.id;
    data["createrId"] = localStorage.getItem("current");
    console.log("helloedit");

    dispatch(
      editCandidateDetail({
        data,
        onSuccess: () => {
          setVisible(false);
          notification.success({
            message: "Success",
            description: "Candidate has been successfully updated.",
            duration: 2,
          });
          dispatch(
            getAllCandidateDetails({
              params: {
                PageNumber: allCandidates.data.pageNumber,
                pageSize: allCandidates.data.pageSize,
                Name: searchItem,
                isActive:isActive
              },
            })
          );
        },
      })
    );
  };

  return (
    <Container>
      <ModalHeader>
        <Text style={{ color: "white", fontWeight: 300, fontSize: "13px" }}>
          {editItem ? "Edit Candidate" : "Add Candidate"}
        </Text>
        <Text style={{ color: "white", fontWeight: 300, marginTop: "5px", fontSize: "16px" }}>
          Enter candidate details below
        </Text>
      </ModalHeader>
      <ModalBody>
        <StyledForm
          name="basic"
          layout="vertical"
          form={form}
          onFinish={editItem ? handleEditSubmit : handleAddSubmit}
        >
          <Row gutter={[16, 4]}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: "Please input firstname!" }]}
                initialValue={editItem ? editItem.firstName : ""}
                label="First Name"
              >
                <StyledInput placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="middleName"
                initialValue={editItem ? editItem.middleName : ""}
                label="Middle Name"
              >
                <StyledInput placeholder="Middle Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: "Please input lastname!" }]}
                initialValue={editItem ? editItem.lastName : ""}
                label="Last Name"
              >
                <StyledInput placeholder="Last Name" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input email!" },
                  { type: "email", message: "Please input valid email!" },
                ]}
                initialValue={editItem ? editItem.email : ""}
                label="Email"
              >
                <StyledInput placeholder="Email" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="phoneNumber"
                rules={[{ required: true, message: "Please input phone number!" }]}
                initialValue={editItem ? editItem.phoneNumber : ""}
                label="Phone Number"
              >
                <StyledInput placeholder="Phone" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              {editItem ? (
                <Form.Item
                  name="countryId"
                  initialValue={editItem && editItem.countryDetailForCandidate.id}
                  rules={[{ required: true, message: "Please select a country!" }]}
                  label="Country"
                >
                  <Select placeholder="Country" style={{ fontSize: "13px" }} allowClear={true}>
                    {countries &&
                      countries.data &&
                      countries.data.map((country: any) => (
                        <Option key={country.id} value={country.id} style={{ fontSize: "13px" }}>
                          {country.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  name="countryId"
                  rules={[{ required: true, message: "Please select a country!" }]}
                  label="Country"
                >
                  <Select placeholder="Country" style={{ fontSize: "13px" }} allowClear={true}>
                    {countries &&
                      countries.data &&
                      countries.data.map((country: any) => (
                        <Option key={country.id} value={country.id} style={{ fontSize: "13px" }}>
                          {country.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {!editItem ? (
                <Form.Item name="companyId" label="Company">
                  <StyledSelect
                    placeholder="Select Company"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear={true}
                  >
                    {allCompanies &&
                      allCompanies.data &&
                      allCompanies.data.results &&
                      allCompanies.data.results.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </StyledSelect>
                </Form.Item>
              ) : (
                <Form.Item
                  name="companyId"
                  initialValue={
                    editItem && editItem.companyDetail && editItem.companyDetail.name != null
                      ? editItem.companyDetail.id
                      : ""
                  }
                  label="Company"
                >
                  <StyledSelect
                    placeholder="Select Company"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear={true}
                  >
                    {allCompanies &&
                      allCompanies.data &&
                      allCompanies.data.results &&
                      allCompanies.data.results.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </StyledSelect>
                </Form.Item>
              )}
            </Col>
          </Row>

          <AddButton htmlType="submit">{editItem ? "UPDATE" : "CREATE"}</AddButton>
        </StyledForm>
      </ModalBody>
    </Container>
  );
}
export default AddCandidate;
