import React, { useEffect, useState, useContext } from "react";
import styled from "@emotion/styled";
import { Button, DatePicker, Input, Typography } from "antd";
import moment from "moment";
import RightHeader from "../../components/RightHeader";

import HourLogs from "./components/HourLogs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getAllMentorHourLogs } from "../../redux/actions/mentorHourLogAction";
import { ProjectHourLogContext } from "../../components/ContextComponent";
import LoadingSpinner from "../../components/LoadingSpinner";

const { RangePicker } = DatePicker;

const { Text } = Typography;
const { Search } = Input;

const Container: any = styled.div`
  padding: 25px 50px 25px 50px;
`;
const HeaderContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BodyContainer: any = styled.div`
  margin-top: 35px;
  padding: 15px 0px 0px 0px;
`;
const LeftHeader: any = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSearch = styled(Search)`
  .ant-input-wrapper {
    box-shadow: 0px 8px 10px #008ce914;
    width: 280px;
    height: 30px;
    border-radius: 6px;
  }
  .ant-input {
    border: 1px solid #008ce940;
    border-radius: 6px;
    padding-top: 6px;
    color: #434343;
    height: 40px;
  }
  .ant-btn,
  .ant-btn-icon-only,
  .ant-input-search-button {
    border: none;
    box-shadow: none;
    margin-left: 2px;
  }

  .ant-input-group-addon {
    border: 1px solid #008ce940;
    border-left: none;
    border-radius: 6px;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #008ce940 !important ;
    border-left: none !important;
    background-color: white !important;
  }
  .ant-input-group input::placeholder {
    color: #434343;
    opacity: 0.7;
  }
`;
const StyledDateRangePicker = styled(RangePicker)`
  box-shadow: 0px 8px 10px #008ce914;
  border: 1px solid #008ce940;
  border-radius: 6px;
`;

function MentorHourLog() {
  const dateFormat = "MM/DD/YYYY";
  let startDate = moment().startOf("month");
  let endDate = moment().endOf("month");
  const [date, setDate] = useState([startDate, endDate]);
  const [searchData, setSearchData] = useState<any>("");

  const { setStartDate, setEndDate, setSearchValue } = useContext(ProjectHourLogContext);
  let defaulFirstdate = moment().startOf("month").format("MM-DD-YYYY");
  let defaultSeconddate = moment().endOf("month").format("MM-DD-YYYY");

  const dispatch = useDispatch();
  const { mentorHourLogs, loading } = useSelector((state: RootState) => state.mentorHourLogs);

  function onDataChange(dates: any) {
    setDate(dates);
    const StartDate = dates[0].format("MM-DD-YYYY");
    const EndDate = dates[1].format("MM-DD-YYYY");
    setStartDate(StartDate);
    setEndDate(EndDate);

    dispatch(
      getAllMentorHourLogs({ params: { StartDate, EndDate, MentorOrCandidateName: searchData } })
    );
  }

  const onChangeSearch = (e: any) => {
    setSearchData(e.target.value);
    setSearchValue(e.target.value);
  };

  const onSearch = (value: any) => {
    dispatch(
      getAllMentorHourLogs({
        params: { MentorOrCandidateName: value },
      })
    );
  };

  useEffect(() => {
    dispatch(getAllMentorHourLogs({ StartDate: defaulFirstdate, EndDate: defaultSeconddate }));
  }, [dispatch]);

  useEffect(() => {
    if (searchData === "") {
      dispatch(getAllMentorHourLogs({}));
    }
  }, [searchData]);

  const Today = new Date();
  const StringToday = Today.toDateString();

  return mentorHourLogs.length === 0 ? (
    <LoadingSpinner margin={300} />
  ) : (
    <Container>
      <HeaderContainer>
        <LeftHeader>
          <Text style={{ fontSize: "28px", fontWeight: 600, color: "#434343" }}>
            Project HourLog
          </Text>
          <Text style={{ color: "#434343" }}>{StringToday}</Text>
        </LeftHeader>
        <RightHeader />
      </HeaderContainer>
      <BodyContainer>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <StyledSearch
            name="search"
            placeholder="Search by first name"
            onChange={onChangeSearch}
            onSearch={onSearch}
          />
          <StyledDateRangePicker
            onChange={onDataChange}
            size="large"
            clearIcon={false}
            defaultValue={[moment().startOf("month"), moment().endOf("month")]}
            format={dateFormat}
          />
        </div>
        <div>
          {mentorHourLogs && mentorHourLogs.data && (
            <HourLogs
              mentorLog={mentorHourLogs && mentorHourLogs.data && mentorHourLogs.data}
              loading={loading}
              date={date}
            />
          )}
        </div>
      </BodyContainer>
    </Container>
  );
}
export default MentorHourLog;
