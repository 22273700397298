import types from '../types';

export function getAllUsers(data: any) {
  return {
    type: types.GETALL_USERS,
    payload: data,
  };
}

export function getIndividualUser(data:any){
  return{
    type:types.GET_INDIVIDUAL_USER,
    payload:data,
  }
}
export function getIndividualUserSuccess(data:any){
  return{
    type:types. GET_INDIVIDUAL_USER_SUCCESS,
    payload:data,
  }
}

export function getAllUsersSuccess(user: any) {
  return {
    type: types.GETALL_USERS_SUCCESS,
    payload: user,
  };
}

export function createUser(data: any) {
  return {
    type: types.CREATE_USER,
    payload: data,
  };
}

export function createUserSuccess(data: any) {
  return {
    type: types.CREATE_USER_SUCCESS,
    payload: data,
  };
}

export function updateUser(data: any) {
  return {
    type: types.UPDATE_USER,
    payload: data,
  };
}

export function updateUserSuccess(data: any) {
  return {
    type: types.UPDATE_USER_SUCCESS,
    payload: data,
  };
}

export function updateUserStatus(data: any) {
  return {
    type: types.UPDATE_USER_STATUS,
    payload: data,
  };
}

export function updateUserStatusSuccess(data: any) {
  return {
    type: types.UPDATE_USER_STATUS_SUCCESS,
    payload: data,
  };
}

export function deleteUser(data: any) {
  return {
    type: types.DELETE_USER,
    payload: data,
  };
}

export function deleteUserSuccess(data: any) {
  return {
    type: types.DELETE_USER_SUCCESS,
    payload: data,
  };
}

export function userRequestError(data: any) {
  return {
    type: types.USER_REQUEST_ERROR,
    payload: data,
  };
}
