import React, { useState } from "react";
import { Route, Switch as Switching, useHistory } from "react-router";
import { MenuOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Image, Layout, Menu, Typography } from "antd";
import ViewProfile from "../ViewProfile/index";

import jwt from "jwt-decode";
import Logo from "../../image/logo1.png";
import { routes } from "../../router/router.config";

const { Sider, Content } = Layout;
const { Text } = Typography;

const StyledLayout: any = styled(Layout)`
  background-color: #169af4;
  padding: 4px;
  height: 100vh;
  overflow: hidden;
`;
const Container: any = styled.div`
  display: flex;
  align-items: center;
`;
const CollapseIconContainer: any = styled.div`
  cursor: pointer;
  margin-top: 20px;
  height: 50px;
`;
const LogoContainer: any = styled.div`
  padding-right: 20px;
  padding-left: 20px;
  height: 50px;
  cursor: pointer;
`;
const StyledMenuOutlined: any = styled(MenuOutlined)`
  font-size: 25px;
  padding-left: 25px;
  color: white;
`;
const StyledContent: any = styled(Content)`
  background-color: white;
  height: 100vh;
  overflow: auto;
`;
const StyledMenu = styled(Menu)`
  margin-top: 20px;
  padding-top: 20px;
  margin-left: 8px;
  background-color: #169af4;
  &.ant-menu-inline {
    border-right: none;
  }
`;
const MenuContainer = styled.div``;
const StyledMenuItem = styled(Menu.Item)`
  padding: 30px 0 30px 20px !important;
  margin: 20px 0px 20px 0px !important;
  color: #ffffff;
  display: flex !important;
  align-items: center !important;
  &:hover {
    background-color: #0fa0fa;
    color: #ffffff;
  }
  &.ant-menu-item-selected {
    background: #1aa6ff 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 12px 40px #00000005 !important;
    background-color: #1aa6ff !important;
  }
`;
const StyledText: any = styled(Text)`
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0.79px;
  font-weight: 300;
`;

function LayoutOne() {
  let token: any = localStorage.getItem("token");
  const user: any = jwt(token);

  const [isCollapse, setCollapse] = useState(false);
  const history = useHistory();

  const roleFunction = (role: any) => {
    switch (role) {
      case "Super Admin":
        return "superAdmin";
      case "Mentor Admin":
        return "mentorAdmin";
      case "Candidate Admin":
        return "candidateAdmin";
      case "Account":
        return "account";
      default:
        return "candidateAdmin";
    }
  };
  let role = roleFunction(user.roles);

  return (
    <StyledLayout>
      <Sider
        collapsedWidth={68}
        trigger={null}
        collapsible
        collapsed={isCollapse}
        width={280}
        style={{ backgroundColor: "#169af4", height: "100vh", overflow: "auto" }}
      >
        <Container>
          <CollapseIconContainer onClick={() => setCollapse(!isCollapse)}>
            <StyledMenuOutlined />
          </CollapseIconContainer>
          {isCollapse ? null : (
            <LogoContainer onClick={() => history.push("/home")}>
              <Image src={Logo} preview={false} width={170} />
            </LogoContainer>
          )}
        </Container>
        <MenuContainer>
          <StyledMenu mode="inline" defaultSelectedKeys={[history.location.pathname]}>
            {routes[role].navRoutes
              .filter((item: any) => item.showInMenu)
              .map((item: any) => (
                <StyledMenuItem key={item.path} onClick={() => history.push(item.path)}>
                  <Image
                    src={item.icon}
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "20px",
                    }}
                    preview={false}
                  />
                  <span>
                    <StyledText>{item.title}</StyledText>
                  </span>
                </StyledMenuItem>
              ))}
          </StyledMenu>
        </MenuContainer>
      </Sider>
      <Layout>
        <StyledContent>
          <Switching>
            {routes[role].navRoutes.map((item: any) => (
              <Route key={item.path} path={item.path} component={item.component} exact />
            ))}
            <Route path="/viewprofile" component={ViewProfile} />
          </Switching>
        </StyledContent>
      </Layout>
    </StyledLayout>
  );
}

export default LayoutOne;
