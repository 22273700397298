import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Col, Row } from "antd";
import Title from "antd/lib/typography/Title";
import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import HoursTable from "./HoursTable";
import { MentorLogProps } from "../interface";
import LoadingSpinner from "../../../components/LoadingSpinner";

const Wrapper = styled.div`
  margin-top: 25px;
`;

const Name = styled(Title)`
  &.ant-typography {
    color: #323232;
    letter-spacing: 0.45px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
  }
`;
const StyledLeftOutlined = styled(LeftOutlined)`
  &.anticon.slick-prev {
    padding-right: 30px;
  }
`;
const StyledRightOutlined = styled(RightOutlined)`
  &.anticon.slick-next {
    padding-left: 30px;
  }
`;

const CarouselWrap = styled.div`
  position: relative;
`;

const OurCarousel = styled(Slider)`
  .itemWrap {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .itemDate {
    display: grid;
    place-items: center;
    height: 25px;
    width: 25px;
    margin-bottom: 5px;
  }

  .isToday {
    display: grid;
    place-items: center;
    background: #008ce9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 24px #2a5db445;
    opacity: 1;
    height: 25px;
    width: 25px;
    color: #ffffff;
    border-radius: 50%;
    margin-bottom: 5px;
  }

  .itemDay {
    text-align: center;
  }

  .anticon {
    font-size: 15px;
    z-index: 10;
    cursor: pointer;
    padding: 0;
    color: #4b4b4b;
    height: auto;
    width: auto;

    &:hover,
    &:focus {
      color: #242424;
    }

    &.slick-next {
      right: 0;
    }

    &.slick-prev {
      left: 0;
    }
  }
`;

export const settings: Settings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 13,
  slidesToScroll: 6,
  swipeToSlide: false,
  touchMove: false,
  prevArrow: <StyledLeftOutlined />,
  nextArrow: <StyledRightOutlined />,
  waitForAnimate: true,
};

interface HourLogsProps {
  date: Moment[];
  mentorLog: Array<MentorLogProps>;
  loading: boolean;
}

const HourLogs = (props: HourLogsProps) => {
  const { date, mentorLog, loading } = props;
  const [allDates, setallDates] = useState<Array<Moment> | null>();
  const ourCarouselRef = useRef<Slider>();
  const [tableCellCarousel, settableCellCarousel] = useState<Slider>();

  const getAllDatesInMonth = () => {
    let firstDate = moment(date[0], "DD/MM/YYYY");
    let secondDate = moment(date[1], "DD/MM/YYYY");
    let difference = secondDate.diff(firstDate, "days");
    let dates = new Array(difference + 1).fill(null).map((x, i) => moment(date[0]).add(i, "days"));
    setallDates([...dates]);
  };

  useEffect(() => {
    getAllDatesInMonth();
    return () => {
      setallDates(null);
    };
  }, [date]);

  return (
    <Wrapper>
      <Row align="middle">
        <Col span={3}>
          <Name className="head" level={5} style={{ textAlign: "center" }}>
            Projects
          </Name>
        </Col>
        <Col span={17}>
          <CarouselWrap>
            <OurCarousel
              asNavFor={tableCellCarousel}
              ref={(c) => {
                if (c) ourCarouselRef.current = c;
              }}
              {...settings}
            >
              {allDates &&
                allDates.map((date, index) => (
                  <div key={index} className="itemWrap">
                    {moment(date).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY") ? (
                      <div className="isToday">{date.date()}</div>
                    ) : (
                      <div className="itemDate">{date.date()}</div>
                    )}

                    <div className="itemDay">{date.format("ddd")}</div>
                  </div>
                ))}
            </OurCarousel>
          </CarouselWrap>
        </Col>
        <Col span={2}>
          <Name style={{ textAlign: "center" }}>Hrs</Name>
        </Col>
        <Col span={2}>
          <Name style={{ textAlign: "center" }}>Total Hrs</Name>
        </Col>
      </Row>
      {mentorLog.length == 0 && loading ? (
        <div style={{ paddingTop: "50px" }}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {allDates && mentorLog.length > 0 && (
            <HoursTable
              mentorLog={mentorLog}
              allDates={allDates}
              settableCellCarousel={settableCellCarousel}
              ourCarouselRef={ourCarouselRef}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default HourLogs;
