import styled from "@emotion/styled";
import { Col, Row, Menu, Dropdown, notification, Popover } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState, useContext } from "react";
import Slider from "react-slick";
import { MentorLogDetailProps, MentorLogProps } from "../interface";
import { settings } from "./HourLogs";
import TableCol from "./TableCol";
import jwt from "jwt-decode";
import CreateTimesheet from "./CreateTimesheet";
import ApproveHours from "./ApproveHours";
import { ContextMenuTrigger } from "react-contextmenu";
import SaveHourLogs from "./AddHour";
import { getAllMentorHourLogs, saveHourLog } from "../../../redux/actions/mentorHourLogAction";
import { useDispatch } from "react-redux";
import { ProjectHourLogContext } from "../../../components/ContextComponent";

const Wrapper = styled.div`
  margin-top: 20px;
  overflow: scroll;
  scroll-behavior: smooth;
  height: 75vh;
`;

const LogNameWrap = styled.div`
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Name = styled(Text)`
  margin: 0;
  font-weight: 400;
  color: #323232;
  font-size: 16px;
  text-align: center;
`;

const HoursTableWrap = styled.div`
  height: 100%;
  border: 1px #008ce9bf solid;
`;

const HoursWrap = styled.div`
  height: 100%;
  background: #f8fdff 0% 0% no-repeat padding-box;
  box-shadow: -20px 0px 20px #00000008;
  border: 1px solid #008ce9;
`;

const Hours = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  position: relative;
`;
const TotalHoursWrap = styled.div`
  height: 100%;
  background: #f8fdff 0% 0% no-repeat padding-box;
  box-shadow: -20px 0px 20px #00000008;
  border: 1px solid #008ce9;
`;
const TotalHours = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledCol = styled(Col)`
  .css-z8wzav:not(:last-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.16);
  }
`;

const CreateTimeSheetButton = styled(Dropdown)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const TimesheetButtonWrapper = styled("span")`
  display: grid;
  place-items: center;
  height: 20px;
  width: 20px;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 50%;
  cursor: pointer;
`;

interface HoursTableProps {
  allDates: Moment[];
  settableCellCarousel: React.Dispatch<React.SetStateAction<Slider | undefined>>;
  ourCarouselRef: React.MutableRefObject<Slider | undefined>;
  mentorLog: Array<MentorLogProps>;
}

const HoursTable = (props: HoursTableProps) => {
  const { allDates, settableCellCarousel, ourCarouselRef, mentorLog } = props;
  const tableCarouselRef = useRef<Slider>();
  let token: any = localStorage.getItem("token");
  const user: any = jwt(token);
  const { record, setRecord } = useContext(ProjectHourLogContext);
  const dispatch = useDispatch();

  const [visible, setvisible] = useState<boolean>(false);
  const [isApproveHours, setApproveHours] = useState<boolean>(false);
  const [createTimesheetData, setCreateTimesheetData] = useState<MentorLogProps>();

  const showDrawer = () => {
    setvisible(true);
  };

  const onClose = () => {
    setvisible(false);
  };

  const getTotalHours = (value: Array<MentorLogDetailProps>) => {
    if (value) {
      let totalHrs = 0;
      value.map((val) => (totalHrs += val.hours));
      return totalHrs;
    }
    return "";
  };

  const getApprovedHours = (value: any) => {
    if (value) {
      let totalHrs = 0;
      value.map((val: any) => {
        if (val.isValidatedByCandidate === true) {
          return (totalHrs += val.hours);
        }
      });
      return totalHrs;
    }
  };

  const handleSave = () => {
    let data = record;
    dispatch(
      saveHourLog({
        data,
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "Hours has been saved.",
            duration: 2,
          });

          dispatch(getAllMentorHourLogs({}));
          setRecord([]);
        },
      })
    );
  };

  useEffect(() => {
    settableCellCarousel(tableCarouselRef.current);
  }, []);

  return (
    <Wrapper>
      <Row>
        <Col span={3}>
          {mentorLog.map((log, index) => (
            <Popover
              title=""
              trigger="hover"
              placement="left"
              content={
                <div style={{ display: "flex", flexDirection: "column", gap: "3px" }}>
                  <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                    <Text>Mentor:</Text>
                    <Text style={{ fontSize: "16px", fontWeight: 500 }}> {log.mentorName}</Text>
                  </div>
                  <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                    <Text>Candidate:</Text>
                    <Text style={{ fontSize: "16px", fontWeight: 500 }}> {log.candidateName}</Text>
                  </div>
                  <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                    <Text>Start Date:</Text>
                    <Text style={{ fontSize: "16px", fontWeight: 500 }}>
                      {" "}
                      {new Date(log.startDate).toDateString()}
                    </Text>
                  </div>
                  <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                    <Text>End Date:</Text>
                    <Text style={{ fontSize: "16px", fontWeight: 500 }}>
                      {" "}
                      {log.endDate === null ? "" : new Date(log.endDate).toDateString()}
                    </Text>
                  </div>
                </div>
              }
            >
              <LogNameWrap key={index}>
                <Name style={{ color: "#008CE9", fontSize: "16px" }}>{log.mentorName}</Name>
                <Name>{log.candidateName}</Name>
              </LogNameWrap>
            </Popover>
          ))}
        </Col>
        <StyledCol span={17}>
          <ContextMenuTrigger id="projecthourlogcontext">
            <HoursTableWrap>
              <Slider
                asNavFor={ourCarouselRef.current}
                ref={(c) => {
                  if (c) tableCarouselRef.current = c;
                }}
                {...settings}
                arrows={false}
              >
                {allDates.map((date, index) => (
                  <div key={index}>
                    <TableCol mentorLog={mentorLog} date={date} />
                  </div>
                ))}
              </Slider>
            </HoursTableWrap>
          </ContextMenuTrigger>
        </StyledCol>
        <Col span={2}>
          <HoursWrap>
            {mentorLog.map((log, index) => (
              <React.Fragment key={index}>
                <Hours key={index} style={{ cursor: "pointer" }}>
                  {user && user.roles === "Candidate Admin" && (
                    <Name>{getTotalHours(log.details)}</Name>
                  )}
                  {user && user.roles === "Mentor Admin" && (
                    <Name>{getTotalHours(log.details)}</Name>
                  )}
                  {user && user.roles === "Account" && <Name>{getApprovedHours(log.details)}</Name>}
                  {user && user.roles === "Candidate Admin" && (
                    <ApproveHours projectId={log.projectId} />
                  )}
                  {user && user.roles === "Mentor Admin" && (
                    <CreateTimeSheetButton
                      placement="bottomRight"
                      arrow
                      overlay={
                        <Menu>
                          <Menu.Item>
                            <a rel="noopener noreferrer" onClick={handleSave}>
                              Save Entries
                            </a>
                          </Menu.Item>
                          <Menu.Item>
                            <a
                              rel="noopener noreferrer"
                              onClick={() => {
                                showDrawer();
                                setCreateTimesheetData(log);
                              }}
                            >
                              Create Timesheet
                            </a>
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <TimesheetButtonWrapper>
                        <EllipsisOutlined />
                      </TimesheetButtonWrapper>
                    </CreateTimeSheetButton>
                  )}
                </Hours>
              </React.Fragment>
            ))}
          </HoursWrap>
        </Col>
        <Col span={2}>
          <TotalHoursWrap>
            {mentorLog.map((log, index) => (
              <TotalHours key={index}>
                <Name>{log.totalHours}</Name>
              </TotalHours>
            ))}
          </TotalHoursWrap>
        </Col>
      </Row>
      <CreateTimesheet
        onClose={onClose}
        visible={visible}
        createTimesheetData={createTimesheetData}
        setvisible={setvisible}
      />
      <SaveHourLogs showDrawer={showDrawer} />
    </Wrapper>
  );
};

export default HoursTable;
