import React, { useState, useRef, useContext, useEffect } from "react";
import { Form, InputNumber, Popover } from "antd";
import { useForm } from "antd/lib/form/Form";
import styled from "@emotion/styled";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import jwt from "jwt-decode";
import Text from "antd/lib/typography/Text";
import { ProjectHourLogContext } from "../../../components/ContextComponent";

const StyledInput = styled(InputNumber)`
  width: 50px;
  border: none;
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const HourLogInput = (props: any) => {
  const { hourLog, date, log } = props;
  const [form] = useForm();
  const [value, setValue] = useState<number | string>(
    hourLog && hourLog.length > 0 ? hourLog[0].hours : 0
  );
  const [validationPopup, setValidationPopup] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isFocused, setIsfocused] = useState(false);
  const [isDecimal, setDecimal] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>();
  const [inRange, setInRange] = useState<boolean>();

  const inputref: any = useRef(null);
  let token: any = localStorage.getItem("token");
  const user: any = jwt(token);
  const { setRecord, record } = useContext(ProjectHourLogContext);

  useEffect(() => {
    form.setFieldsValue({ hourlogInput: hourLog && hourLog.length > 0 ? hourLog[0].hours : "" });
  }, [hourLog]);

  useEffect(() => {
    let value = checkRange();
    setInRange(value);
  }, [date, log]);

  const handleChange = (data: any) => {
    setValue(data);
    let val: number = data === "" ? 0 : parseFloat(data);
    let stringVal: string = data && data.toString();
    if (
      val > 24 ||
      (stringVal && stringVal.includes(".") && stringVal.split(".")[1].length > 1) ||
      val < 0
    ) {
      setValidationPopup(true);
      val > 24 && setErrorMessage("HourLog entry must be between 0 and 24");
      stringVal &&
        stringVal.includes(".") &&
        stringVal.split(".")[1].length > 1 &&
        setErrorMessage("HourLog entry must contain only one number after decimal point") &&
        setDecimal(true);
      val < 0 && setErrorMessage("HourLog entry cannot be negative");
    } else {
      setValidationPopup(false);
    }
  };

  const checkRange = () => {
    let today = new Date();
    let currentDate = new Date(date);
    let startDate = new Date(log.startDate);
    let endDate =
      log.endDate === null ? new Date(2025, 11, 24, 10, 33, 30, 0) : new Date(log.endDate);
    if (
      currentDate.getTime() < startDate.getTime() ||
      currentDate.getTime() > endDate.getTime() ||
      currentDate.getTime() > today.getTime()
    ) {
      return true;
    } else return false;
  };

  const saveRecord = (values: any) => {
    setValidationPopup(false);
    setIsfocused(false);
    const newrecord: any = {};
    newrecord["projectId"] = log.projectId;
    newrecord["day"] = moment(date).format("YYYY-MM-DD");
    newrecord["hours"] = value < 0 ? 0 : value;
    newrecord["createrId"] = localStorage.getItem("current");
    newrecord["id"] = hourLog && hourLog.length > 0 ? hourLog[0].hourlogId : null;
    const newArray: any = [...record, newrecord];
    setRecord(newArray);
  };

  const checkSingleDigit = (value: number) => {
    if (typeof value === "number") {
      var valAsString = value.toString(10);

      if (valAsString.length === 1) {
        return true;
      } else return false;
    }
  };

  const checkIsEditable = () => {
    if (hourLog && hourLog.length === 0) {
      return true;
    }
    if (hourLog && hourLog.length > 0 && hourLog[0].isValidatedByCandidate === false) {
      return true;
    } else return false;
  };
  return (
    <React.Fragment>
      {user &&
        user.roles === "Mentor Admin" &&
        hourLog &&
        hourLog.length > 0 &&
        hourLog[0].isValidatedByCandidate === true && (
          <Text>{hourLog && hourLog.length > 0 ? hourLog[0].hours : ""}</Text>
        )}
      {user && user.roles === "Mentor Admin" && checkIsEditable() && !inRange && (
        <div style={{ height: "36px", position: "relative" }}>
          <Popover
            title=""
            content={<Text style={{ fontSize: "10px" }}>{errorMessage}</Text>}
            visible={validationPopup}
            trigger="click"
            overlayClassName="validationhourlog"
          >
            <Form form={form} hideRequiredMark>
              <Form.Item name="hourlogInput" help="" rules={[{ type: "number", min: 0, max: 24 }]}>
                <StyledInput
                  type="number"
                  onPressEnter={saveRecord}
                  onBlur={() => {
                    setIsfocused(false);
                    saveRecord(value);
                  }}
                  onChange={handleChange}
                  ref={inputref}
                  onFocus={() => setIsfocused(true)}
                  style={{
                    paddingLeft: `${
                      checkSingleDigit(form.getFieldValue("hourlogInput")) && isFocused
                        ? "0px"
                        : "7px"
                    }`,
                  }}
                  parser={(value: any) => {
                    return value.substring(0, 4);
                  }}
                  tabIndex={date.date()}
                />
              </Form.Item>
            </Form>
          </Popover>
        </div>
      )}

      {user && user.roles === "Candidate Admin" && (
        <Text>{hourLog && hourLog.length > 0 ? hourLog[0].hours : ""}</Text>
      )}
      {user &&
        user.roles === "Account" &&
        hourLog &&
        hourLog.length > 0 &&
        hourLog[0].isValidatedByCandidate === true && (
          <Text>{hourLog && hourLog.length > 0 ? hourLog[0].hours : ""}</Text>
        )}
    </React.Fragment>
  );
};
export default HourLogInput;
