import { countryRequestError, getAllCountriesSuccess, getAllCountries } from '../actions/countriesAction';
import types from '../types';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import instance from '../../lib/axios/axiosInstance';

function* fetchAllCountries(action: any) {
  try {
    const response: AxiosResponse = yield call(() => instance.get('/api/v1.1/Company/GetAllCountries', {data: action.payload}));
    yield put(getAllCountriesSuccess(response.data));
  } catch (error) {
    yield put(countryRequestError(error));
  }
}

export function* countriesSaga() {
  yield takeEvery(types.GETALL_COUNTRIES, fetchAllCountries);
}
