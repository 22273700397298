import React from "react";
import styled from "@emotion/styled";
import { Col, Row, Typography } from "antd";
import jwt from "jwt-decode";
import moment from "moment";

import ForgotPasswordForm from "./components/ForgotPasswordPage";
import LoginForm from "./components/LoginPage";

import { Colors } from "../../constants/Colors";
import ForgotPasswordVector from "../../image/forgot-password-vector.svg";
import { routes } from "../../router/router.config";

const { Title, Text } = Typography;

const StyledWrapper = styled("div")`
  background: ${Colors.backgroundColor};
  position: relative;
  min-height: 100vh;
`;

const StyledDiv = styled("div")`
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 auto;
  font-size: 1vw;
  @media only screen and (max-width: 1023px) {
    font-size: 10px;
  }
`;

function Login(props: any) {
  const [animation, setAnimation] = React.useState(false);

  React.useEffect(() => {
    let token: any = localStorage.getItem("token");
    const loggedIn: any = localStorage.getItem("loggedIn");
    var isRemember;
    if (loggedIn === "undefined") {
      isRemember = false;
    }
    if (loggedIn === "true") {
      isRemember = true;
    }

    if (token) {
      const user: any = jwt(token);
      let isExpired = moment.unix(user.exp).diff(moment(), "seconds");
      const roleFunction = (role: any) => {
        switch (role) {
          case "Super Admin":
            return "superAdmin";
          case "Mentor Admin":
            return "mentorAdmin";
          case "Candidate Admin":
            return "candidateAdmin";
          case "Account":
            return "account";
          default:
            return "superAdmin";
        }
      };
      let role = roleFunction(user.roles);
      if (isExpired > 0 && isRemember) {
        props.history.push(routes[role].navRoutes[0].path);
      }
    }
  }, []);

  const StyledContainer = styled(Row)`
    @media only screen and (max-width: 1023px) {
      justify-content: center;
    }
  `;
  const ShadeLayout = styled("div")`
    position: absolute;
    background: rgb(13, 142, 255);
    background: linear-gradient(300deg, rgba(13, 142, 255, 1) 51%, rgba(102, 184, 255, 1) 100%);
    box-shadow: -3px 18px 35px #00000014;
    z-index: 10000;
    width: 100%;
    left: 0;
    height: 100vh;
    opacity: 0.9;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 0 17vw;
    color: white;
  `;
  const StyledFormContainer = styled(Col)`
    padding: ${animation ? "4em 3em 4em 0px" : "4em 0px 4em 3em"};

    @media only screen and (max-width: 1023px) {
      transform: translateX(0%);
      padding: 4em 0px 4em 3em;
    }
    @media only screen and (min-width: 1024px) {
      animation: ${animation
        ? "slide-in-rights 0.6s linear both"
        : "slide-in-lefts 0.6s linear both"};
      @keyframes slide-in-rights {
        0% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
          transform: translateX(140%);
        }
      }
      @keyframes slide-in-lefts {
        0% {
          opacity: 0.5;
          transform: translateX(140%);
        }
        50% {
        }
        100% {
          opacity: 1;
          transform: translateX(0%);
        }
      }
      z-index: 99;
    }
  `;
  const StyledOverlayContainer = styled(Col)`
    position: absolute;
    right: 0;
    padding: ${animation ? "4em 0px 4em 3em" : "4em 3em 4em 0px"};
    min-height: 100%;
    width: 100%;
    z-index: 100;
    left: ${animation && "0"};
    animation: ${animation ? "slide-in-right 0.6s linear both" : "slide-in-left 0.6s linear both"};
    @keyframes slide-in-right {
      0% {
        transform: translateX(1000px);
      }
      100% {
        transform: translateX(0);
      }
    }
    @keyframes slide-in-left {
      0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
      }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }
    }
  `;
  const StyledLayer = styled("div")`
    background: #42a7ff;
    width: 100%;
    object-fit: cover;
    height: 84vh;
    z-index: 15;
  `;

  return (
    <StyledDiv>
      <StyledWrapper>
        <StyledContainer>
          <StyledFormContainer xs={23} lg={10}>
            {animation ? (
              <ForgotPasswordForm setAnimation={setAnimation} animation={animation} />
            ) : (
              <LoginForm setAnimation={setAnimation} animation={animation} />
            )}
          </StyledFormContainer>
          <StyledOverlayContainer xs={0} lg={14}>
            <ShadeLayout>
              {animation ? (
                <React.Fragment>
                  <Text
                    style={{
                      fontSize: "25px",
                      fontWeight: "normal",
                      color: "white",
                      marginBottom: "30px",
                    }}
                  >
                    Forgot password? Let's get you a new one.
                  </Text>
                  <img src={ForgotPasswordVector} alt="forgot-password-vector" />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Title
                    style={{ fontSize: "45px", color: "white", borderBottom: "1px solid white" }}
                  >
                    WELCOME
                  </Title>
                  <Text style={{ fontSize: "15px", fontWeight: "normal", color: "white" }}>
                    We are passionate about our clients' problems and we bring a fierce focus to get
                    the job done right, every time. By sharing our knowledge and understanding our
                    clients' businesses, we deliver the right results for them.
                  </Text>
                </React.Fragment>
              )}
            </ShadeLayout>
            <StyledLayer />
          </StyledOverlayContainer>
        </StyledContainer>
      </StyledWrapper>
    </StyledDiv>
  );
}

export default Login;
