import styled from "@emotion/styled";
import { Pagination } from "antd";
import React from "react";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin: 40px 0;
  .ant-pagination-item-active {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    border: none;
    border-color: #1890ff;
  }
  .ant-pagination-item-active a {
    color: #000000;
  }
`;

const StyledPageChanger = styled("a")`
  text-decoration: none;
  color: #000000;
  padding: 0 10px;
`;

interface PaginationProps {
  size: any;
  total: any;
  current: any;
  defaultPageSize: number;
  defaultCurrent: number;
  showSizeChanger: boolean;
  onChange: (current: any, pageSize: any) => void;
}

const CustomPagination = (props: PaginationProps) => {
  const {
    size,
    total,
    current,
    defaultPageSize,
    defaultCurrent,
    showSizeChanger,
    onChange,
  } = props;

  function itemRender(current: any, type: any, originalElement: any) {
    if (type === "prev") {
      return (
        <StyledPageChanger>
          <CaretLeftOutlined style={{ marginRight: "5px" }} />
          Prev
        </StyledPageChanger>
      );
    }
    if (type === "next") {
      return (
        <StyledPageChanger>
          Next
          <CaretRightOutlined style={{ marginLeft: "5px" }} />
        </StyledPageChanger>
      );
    }
    return originalElement;
  }

  return (
    <StyledPagination
      size={size}
      total={total}
      current={current}
      defaultPageSize={defaultPageSize}
      defaultCurrent={defaultCurrent}
      showSizeChanger={showSizeChanger}
      onChange={onChange}
      itemRender={itemRender}
    />
  );
};

export default CustomPagination;
