export const companyTypes = {
  GETALL_COMPANIES: "GETALL_COMPANIES",
  GETALL_COMPANIES_SUCCESS: "GETALL_COMPANIES_SUCCESS",

  CREATE_COMPANY: "CREATE_COMPANY",
  CREATE_COMPANY_SUCCESS: "CREATE_COMPANY_SUCCESS",

  UPDATE_COMPANY: "UPDATE_COMPANY",
  UPDATE_COMPANY_SUCCESS: "UPDATE_COMPANY_SUCCESS",

  CREATE_COMPANYPAYMENT_DETAILS: "CREATE_COMPANYPAYMENT_DETAILS",
  CREATE_COMPANYPAYMENTDETAILS_SUCCESS: "CREATE_COMPANYPAYMENTDETAILS_SUCCESS",

  UPDATE_COMPANYPAYMENT_DETAILS: "UPDATE_COMPANYPAYMENT_DETAILS",
  UPDATE_COMPANYPAYMENTDETAILS_SUCCESS: "UPDATE_COMPANYPAYMENTDETAILS_SUCCESS",

  DELETE_COMPANY: "DELETE_COMPANY",
  DELETE_COMPANY_SUCCESS: "DELETE_COMPANY_SUCCESS",

  SEND_REQUEST_ERROR: "SEND_REQUEST_ERROR",

  ADD_COMPANY_MODAL: "ADD_COMPANY_MODAL",
  EDIT_COMPANY_MODAL: "EDIT_COMPANY_MODAL",

  GET_COMPANY_USERS: "GET_COMPANY_USERS",
  GET_COMPANY_USERS_SUCCESS: "GET_COMPANY_USERS_SUCCESS",
};
