import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";

const Description = (props: any) => {
  const { description } = props;
  const [isViewMore, setViewMore] = useState(false);
  const [isTruncated, setTruncated] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const result = isEllipsisActive(descriptionRef.current);
    setTruncated(result);
  }, [description]);

  const isEllipsisActive = (e: any) => {
    return e.offsetHeight < e.scrollHeight;
  };

  const DescriptionContent = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: ${isViewMore ? 25 : 2};
    -webkit-box-orient: vertical;
    overflow: ${isViewMore ? "auto" : "hidden"};
    word-wrap: break-word;
  `;
  return (
    <React.Fragment>
      <DescriptionContent ref={descriptionRef}>{description}</DescriptionContent>
      {!isViewMore && isTruncated && (
        <span>
          <a onClick={() => setViewMore(true)}>View More</a>
        </span>
      )}
      {isViewMore && (
        <span>
          <a onClick={() => setViewMore(false)}>View Less</a>
        </span>
      )}
    </React.Fragment>
  );
};
export default Description;
