import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Typography, Tabs, Radio, Input, DatePicker, Table, Drawer, Popover, Checkbox, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import RightHeader from "../../components/RightHeader";
import CustomPagination from "../../components/CustomPagination/index";
import BankingDetails from "./components/BankingDetails";
import PaypalDetails from "./components/PaypalDetails";
import ReportDetails from "./components/ReportDetails";
import { getAllInvoices, invoiceMarkAsPaidRequest } from "../../redux/actions/invoiceAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Item from "antd/lib/list/Item";
import LoadingSpinner from "../../components/LoadingSpinner";

const { Text } = Typography;
const { Search } = Input;
const { RangePicker } = DatePicker;

const Container: any = styled.div`
  padding: 25px 50px 25px 50px;
`;
const HeaderContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BodyContainer: any = styled.div`
  margin-top: 35px;
  padding: 15px 0px 0px 0px;
`;
const LeftHeader: any = styled.div`
  display: flex;
  flex-direction: column;
`;
const RadioContainer = styled.div``;

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 35px 0px;
`;
const StyledSearch = styled(Search)`
  .ant-input-wrapper {
    box-shadow: 0px 8px 10px #008ce914;
    width: 280px;
    height: 30px;
  }
  .ant-input {
    border: 1px solid #008ce940;
    border-radius: 6px;
    padding-top: 6px;
    color: #434343;
    height: 40px;
  }
  .ant-btn,
  .ant-btn-icon-only,
  .ant-input-search-button {
    border: none;
  }

  .ant-input-group-addon {
    border: 1px solid #008ce940;
    border-left: none;
    border-radius: 6px;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #008ce940 !important ;
    border-left: none !important;
    background-color: white !important;
  }
  .ant-input-group input::placeholder {
    color: #434343;
    opacity: 0.7;
  }
`;
const StyledRangePicker = styled(RangePicker)`
  height: 40px;
  background: transparent linear-gradient(97deg, #60c0ff 0%, #27a9ff 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 25px 11px #008ce90d;
  border: 1px solid #008ce940;
  color: white;
  .ant-picker-input input::placeholder {
    color: white;
    opacity: 1;
  }
  width: 289px;
  border-radius: 6px;
  .ant-picker-separator {
    color: white !important;
  }
  .ant-picker-suffix {
    color: white !important;
  }
  .ant-picker-active-bar {
    background-color: white;
  }
`;
const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: #f9fcff 0% 0% no-repeat padding-box;
  }
  .ant-table-tbody > tr > td {
    background: #ffeeee 0% 0% no-repeat padding-box;
    border-bottom: 2px solid #008ce930;
  }
`;
const TableWrapper = styled("div")`
  background: #ffeeee 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 22px #008ce905;
  border: 1px solid #008ce930;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 150px;
`;
const CustomBadge = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-left: 10px;
`;
const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: -20px 0px 75px #008ce926;
    overflow: hidden;
  }
  .ant-drawer-close {
    margin-right: 55px;
    color: #171717;
    font-size: 20px;
    margin-top: 15px;
  }
`;
const StyledPopover = styled(Popover)`
  .ant-popover-content {
    padding: 0px !important;
  }
`;

const Invoices = () => {
  const dateFormat = "MM/DD/YYYY";
  const [types, setTypes] = useState("pendingapproval");
  const [viewBanikgDetails, setViewBankingDetails] = useState(false);
  const [viewPaypalDetails, setViewPaypalDetails] = useState(false);
  const [viewReportDetails, setViewReportDetails] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState();
  const [searchItem, setSearchItem] = useState("");
  const { allInvoices, loading } = useSelector((state: RootState) => state.invoices);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [pageSize,setPageSize]=useState<any>(10);
  const [currentPage,setCurrentPage]=useState<any>(1);

  useEffect(() => {
    dispatch(getAllInvoices({ params: { PageNumber: 1, PageSize: 10 } }));
  }, [dispatch]);

  useEffect(() => {
    if (searchItem === "") {
      dispatch(
        getAllInvoices({
          params: {
            PageNumber: (allInvoices.data && allInvoices.data.pageNumber) || 1,
            PageSize: (allInvoices.data && allInvoices.data.pageSize) || 10,
          },
        })
      );
    }
  }, [searchItem]);

  const handleTableChange = (current: any, pageSize: any) => {
    setPageSize(pageSize);
    setCurrentPage(current);
    dispatch(
      getAllInvoices({
        params: {
          PageNumber: current,
          PageSize: pageSize,
          MentorNameCandidateName: searchItem,
          StartDate: startDate,
          EndDate: endDate,
        },
      })
    );
  };
  const onSearch = (value: any) => {
    dispatch(
      getAllInvoices({
        params: {
          PageNumber: 1,
          PageSize: 10,
          MentorNameCandidateName: value,
        },
      })
    );
  };

  const onChangeSearch = (e: any) => {
    setSearchItem(e.target.value);
  };

  function onDataChange(dates: any) {
    const StartDate = dates[0].format("MM-DD-YYYY");
    const EndDate = dates[1].format("MM-DD-YYYY");
    setStartDate(StartDate);
    setEndDate(EndDate);
    dispatch(
      getAllInvoices({
        params: {
          PageNumber: 1,
          PageSize: 10,
          StartDate,
          EndDate,
          MentorNameCandidateName: searchItem,
        },
      })
    );
  }
  const handleMarkAsPaid = (id: any, isPaid: boolean) => {
    const markAsPaidData: any = { isPaid: isPaid, id: id };
    dispatch(
      invoiceMarkAsPaidRequest({
        markAsPaidData,
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "Invoice marked as paid.",
            duration: 2,
          });
          dispatch(
            getAllInvoices({
              params: {
                PageNumber: currentPage,
                PageSize: pageSize,
                startDate,
                endDate,
                MentorNameCandidateName: searchItem,
              },
            })
          );
        },
      })
    );
  };

  const columns = [
    {
      title: "Invoice Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Report",
      dataIndex: "rid",
      key: "id",
      render: (item: any, record: any) => (
        <StyledPopover placement="bottom" title="" content="Info">
          <ExclamationCircleOutlined
            style={{ color: "#0088E3" }}
            onClick={() => {
              setViewReportDetails(true);
              setPaymentDetails(record);
            }}
          />
        </StyledPopover>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Mentor",
      dataIndex: "mentorName",
      key: "mentor",
    },
    {
      title: "Candidate",
      dataIndex: "candidateName",
      key: "candidate",
    },
    {
      title: "Invoice Date",
      dataIndex: "generateDate",
      key: "generateDate",
      render: (item: any) => (
        <React.Fragment>{item ? new Date(item).toLocaleDateString() : ""}</React.Fragment>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (item: any) => (
        <React.Fragment>{item ? new Date(item).toLocaleDateString() : ""}</React.Fragment>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (item: any) => (
        <React.Fragment>{item ? new Date(item).toLocaleDateString() : ""}</React.Fragment>
      ),
    },
    {
      title: "Total Hours",
      dataIndex: "totalHours",
      key: "totalHours",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (item: any, record: any) => (
        <React.Fragment>${record.totalHours * record.rate}</React.Fragment>
      ),
    },
    {
      title: (
        <Popover
          title=""
          trigger="hover"
          placement="top"
          content={<div>Mark as Paid</div>}
        >
          MAP
        </Popover>
      ),
      dataIndex: "isPaid",
      key: "isPaid",
      render: (item: any, record: any) => {
        return (
          <Checkbox
            onChange={(e: any) => handleMarkAsPaid(record.id, e.target.checked)}
            checked={item}
          ></Checkbox>
        );
      },
    },
    {
      title: "Banking Details",
      dataIndex: "paymentDetails",
      key: "paymentDetails",
      render: (item: any, record: any) => (
        <a
          onClick={() => {
            setViewBankingDetails(true);
            setPaymentDetails(item);
          }}
        >
          {item.bankName}
        </a>
      ),
    },
    {
      title: "Paypal Details",
      dataIndex: "paymentDetails",
      key: "paymentDetails",
      render: (item: any, record: any) => (
        <div
          style={{
            width: "50px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <a
            onClick={() => {
              setViewPaypalDetails(true);
              setPaymentDetails(item);
            }}
          >
            {item.paypalURL}
          </a>
        </div>
      ),
    },
  ];

  const Today = new Date();
  const StringToday = Today.toDateString();

  return (
    <Container>
      <HeaderContainer>
        <LeftHeader>
          <Text style={{ fontSize: "28px", fontWeight: 600, color: "#434343" }}>Invoices</Text>
          <Text style={{ color: "#434343" }}>{StringToday}</Text>
        </LeftHeader>
        <RightHeader />
      </HeaderContainer>
      <BodyContainer>
        {/* <RadioContainer>
          <Radio.Group
            className="timesheetRadiogroup"
            defaultValue="pendingApproval"
            style={{ marginBottom: 8 }}
          >
            <Radio.Button value="pendingApproval" onClick={() => setTypes("pendingapproval")}>
              <Wrapper>
                <CustomBadge style={{ backgroundColor: "#E90036" }}>4</CustomBadge>
                All
              </Wrapper>
            </Radio.Button>
            <Radio.Button value="approved" onClick={() => setTypes("approved")}>
              <Wrapper>
                <CustomBadge style={{ backgroundColor: "#008CE9" }}>0</CustomBadge>
                Edited
              </Wrapper>
            </Radio.Button>
          </Radio.Group>
        </RadioContainer> */}
        <SearchContainer>
          <div style={{ display: "flex", gap: "20px" }}>
            <StyledSearch
              placeholder="Search by Name"
              onChange={onChangeSearch}
              onSearch={onSearch}
            />
          </div>
          <StyledRangePicker
            onChange={onDataChange}
            size="large"
            clearIcon={false}
            format={dateFormat}
          />
        </SearchContainer>
        <TableWrapper>
          <StyledTable
            columns={columns}
            dataSource={allInvoices && allInvoices.data && allInvoices.data.results}
            pagination={false}
            scroll={{ x: "true" }}
            loading={loading}
          />
        </TableWrapper>
        <CustomPagination
          size="small"
          defaultPageSize={10}
          defaultCurrent={1}
          showSizeChanger={true}
          total={allInvoices && allInvoices.data && allInvoices.data.totalCount}
          current={allInvoices && allInvoices.data && allInvoices.data.pageNumber}
          onChange={handleTableChange}
        />

        <StyledDrawer
          title=""
          placement="right"
          closable={true}
          onClose={() => setViewBankingDetails(false)}
          visible={viewBanikgDetails}
          mask={false}
          width={500}
        >
          <BankingDetails paymentDetails={paymentDetails} />
        </StyledDrawer>
        <StyledDrawer
          title=""
          placement="right"
          closable={true}
          onClose={() => setViewPaypalDetails(false)}
          visible={viewPaypalDetails}
          mask={false}
          width={500}
        >
          <PaypalDetails paymentDetails={paymentDetails} />
        </StyledDrawer>
        <StyledDrawer
          title=""
          placement="right"
          closable={true}
          onClose={() => setViewReportDetails(false)}
          visible={viewReportDetails}
          mask={false}
          width={800}
        >
          <ReportDetails paymentDetails={paymentDetails} />
        </StyledDrawer>
      </BodyContainer>
    </Container>
  );
};
export default Invoices;
