import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountries } from "../../../redux/actions/countriesAction";
import { getAllRoles } from "../../../redux/actions/rolesAction";
import { createUser, getAllUsers, updateUser } from "../../../redux/actions/usersAction";
import { RootState } from "../../../redux/store";
import { createFromIconfontCN } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Col, Form, Input, notification, Row, Select, Typography, Upload } from "antd";
import { useForm } from "antd/lib/form/Form";
import Avatar from "antd/lib/avatar/avatar";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2453178_s80bobnfq9p.js",
});

const Container = styled.div`
  background: transparent linear-gradient(98deg, #3bb1ff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  width: 465px;
  height: 94px;
`;
const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 22px 22px 35px;
`;
const ModalBody = styled.div`
  padding: 35px;
  background-color: white;
`;
const PictureConatiner = styled.div`
  margin-top: 16px;
`;
const AddButton = styled(Button)`
  background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 3px;
  color: white;
  width: 100%;
  margin-top: 30px;
  &:hover {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
`;
const StyledInput = styled(Input)`
  font-size: 13px;
`;

const StyledForm = styled(Form)``;
const { Text } = Typography;
const { Option } = Select;

interface UserDataProps {
  editData: any;
  setVisible: (visible: boolean) => void;
  searchData: string;
}

function UserForm(props: UserDataProps) {
  const { editData, setVisible, searchData } = props;
  const [form] = useForm();
  const [uploadItem, setUploadItem]: any = useState("");

  const dispatch = useDispatch();
  const { countries } = useSelector((state: RootState) => state.countries);
  const { roles } = useSelector((state: RootState) => state.roles);
  const { loading, users } = useSelector((state: RootState) => state.users);

  const handleAddSubmit = (value: any) => {
    const data = value;
    data["createrId"] = localStorage.getItem("current");

    dispatch(
      createUser({
        data,
        onSuccess: () => {
          setVisible(false);
          form.resetFields();
          notification.success({
            message: "Success",
            description: "User has been successfully created",
            duration: 2,
          });
          dispatch(
            getAllUsers({
              params: { pageNumber: users.data.pageNumber, pageSize: users.data.pageSize },
            })
          );
        },
      })
    );
  };

  const handleImageChange = async (e: any) => {
    let length = e.fileList.length;
    if (length > 0) {
      setUploadItem(e.fileList[length - 1].originFileObj);
    }
  };

  const handleEditSubmit = (value: any) => {
    const data = value;
    data["id"] = editData.id;
    data["isActive"] = editData.isActive;

    dispatch(
      updateUser({
        data,
        onSuccess: () => {
          setVisible(false);
          notification.success({
            message: "Success",
            description: "User has been successfully updated.",
            duration: 2,
          });
          dispatch(
            getAllUsers({
              params: {
                PageNumber: users.data.pageNumber,
                pageSize: users.data.pageSize,
                firstName: searchData,
              },
            })
          );
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getAllCountries({}));
    dispatch(getAllRoles({}));
  }, [dispatch]);

  return (
    <Container>
      <ModalHeader>
        <Text style={{ color: "white", fontWeight: 300, fontSize: "13px" }}>
          {editData ? "Edit User" : "Add User"}
        </Text>
        <Text style={{ color: "white", fontWeight: 300, marginTop: "5px", fontSize: "16px" }}>
          Enter user details below
        </Text>
      </ModalHeader>
      <ModalBody>
        <StyledForm
          form={form}
          onFinish={editData ? handleEditSubmit : handleAddSubmit}
          name="basic"
          layout="vertical"
        >
          <Row gutter={[16, 4]}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: "Please input firstname!" }]}
                initialValue={editData ? editData.firstName : ""}
                label="First Name"
              >
                <StyledInput placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="middleName"
                initialValue={editData ? editData.middleName : ""}
                label="Middle Name"
              >
                <StyledInput placeholder="Middle Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: "Please input lastname!" }]}
                initialValue={editData ? editData.lastName : ""}
                label="Last Name"
              >
                <StyledInput placeholder="Last Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input email!" },
                  { type: "email", message: "Please input valid email!" },
                ]}
                initialValue={editData ? editData.email : ""}
                label="Email"
              >
                <StyledInput placeholder="Email" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="phone"
                rules={[{ required: true, message: "Please input phone number!" }]}
                initialValue={editData ? editData.phone : ""}
                label="Phone Number"
              >
                <StyledInput placeholder="Phone Number" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              {editData ? (
                <Form.Item
                  name="countryId"
                  initialValue={editData && editData.countryDetail.id}
                  rules={[{ required: true, message: "Please select a country!" }]}
                  label="Country"
                >
                  <Select placeholder="Country" style={{ fontSize: "13px" }} allowClear={true}>
                    {countries &&
                      countries.data &&
                      countries.data.map((country: any) => (
                        <Option key={country.id} value={country.id} style={{ fontSize: "13px" }}>
                          {country.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  name="countryId"
                  rules={[{ required: true, message: "Please select a country!" }]}
                  label="Country"
                >
                  <Select placeholder="Country" style={{ fontSize: "13px" }} allowClear={true}>
                    {countries &&
                      countries.data &&
                      countries.data.map((country: any) => (
                        <Option key={country.id} value={country.id} style={{ fontSize: "13px" }}>
                          {country.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
            {!editData && (
              <React.Fragment>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: "Please input password!" },
                      { min: 8, message: "Min 8 character is required!" },
                      {
                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        message:
                          "Must contain uppercase, lowercase, at least 1 number, and 1 special character",
                      },
                    ]}
                    label="Password"
                  >
                    <StyledInput placeholder="Password" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    name="confirmPassword"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      { required: true, message: "Please confirm the password!" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("The two passwords do not match!"));
                        },
                      }),
                    ]}
                    label="Confirm Password"
                  >
                    <StyledInput placeholder="Confirm Password" />
                  </Form.Item>
                </Col>
              </React.Fragment>
            )}
          </Row>
          {!editData && (
            <Form.Item name="role" required={true} label="Role">
              <Select placeholder="Role" style={{ fontSize: "13px" }} allowClear={true}>
                {roles &&
                  roles.data &&
                  roles.data.map((role: any) => (
                    <Option key={role.id} value={role.normalizedName} style={{ fontSize: "13px" }}>
                      {role.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}

          <PictureConatiner>
            <Text>Picture</Text>
            <Row style={{ marginTop: "12px" }}>
              <Col span={6}>
                <Upload
                  multiple={false}
                  maxCount={1}
                  accept=".jpg,.jpeg,.png"
                  name="File"
                  onChange={handleImageChange}
                  beforeUpload={() => false}
                  listType="picture-card"
                  className="avatar-uploader"
                  style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "50%",
                    border: "2px solid red",
                  }}
                >
                  <div style={{ fontSize: "35px" }}>
                    {editData && editData.imageUrl ? (
                      <>
                        {uploadItem ? (
                          <IconFont type="icon-user" style={{ color: "#9D9D9D" }} />
                        ) : (
                          <Avatar src={editData.imageUrl} size={100} />
                        )}
                      </>
                    ) : (
                      <IconFont type="icon-user" style={{ color: "#9D9D9D" }} />
                    )}
                  </div>
                </Upload>
              </Col>
              <Col span={18}>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "19px" }}>
                  <Text style={{ color: "#008CE9" }}>Upload Picture</Text>
                  <Text style={{ marginTop: "8px", fontSize: "12px", color: "#434343" }}>
                    User profile picture should be in the standard format png,jpg & no more than 2MB
                  </Text>
                </div>
              </Col>
            </Row>
          </PictureConatiner>
          <AddButton loading={loading} htmlType="submit">
            {editData ? "UPDATE" : "CREATE"}
          </AddButton>
        </StyledForm>
      </ModalBody>
    </Container>
  );
}
export default UserForm;
