import types from '../types';

export function getAllRoles(data: any) {
  return {
    type: types.GETALL_ROLES,
    payload: data,
  };
}

export function getAllRolesSuccess(roles: any) {
  return {
    type: types.GETALL_ROLES_SUCCESS,
    payload: roles,
  };
}

export function roleRequestError(data: any) {
  return {
    type: types.ROLE_REQUEST_ERROR,
    payload: data,
  };
}
