import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Typography, Radio, Row, Col } from "antd";
import RightHeader from "../../components/RightHeader";
import MentorCard from "./components/MentorCard";
import TimesheetTable from "./components/TimesheetTable";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { getAllTimesheet, getAllStatus } from "../../redux/actions/timesheetAction";
import LoadingSpinner from "../../components/LoadingSpinner";
const { Text } = Typography;

const Container: any = styled.div`
  padding: 25px 50px 5px 50px;
`;
const HeaderContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BodyContainer: any = styled.div`
  margin-top: 25px;
  padding: 15px 0px 0px 0px;
`;
const LeftHeader: any = styled.div`
  display: flex;
  flex-direction: column;
`;
const RadioContainer = styled.div``;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const CustomBadge = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-left: 10px;
`;
const TimesheetContainer = styled.div`
  padding-top: 30px;
`;
const CardContainer = styled.div`
  padding: 20px 15px 20px 15px;
  max-height:728px;
  min-height: 185px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f9fcff;
  border: 1px solid #008ce94d;
`;

const Timesheets = () => {
  const [types, setTypes] = useState("Pending Approval");
  const dispatch = useDispatch();
  const { allTimesheets, status } = useSelector((state: RootState) => state.timesheets);
  const [timesheetDetails, setTimesheetDetails] = useState([]);
  const [isDefault, setDefault] = useState(true);
  const [hourlogDetails, setHourlogDetails] = useState([]);
  const [projectId, setprojectId] = useState(1);
  const [firstChild, setfirstChild] = useState<number>(0);
  const [activeCard, setActiveCard] = useState();
  const [typeId, setTypeId] = useState<number>(1);

  const defaultMentorDetails =
    allTimesheets && allTimesheets.filter((item: any) => item.timesheetStatusId === 1);

  useEffect(() => {
    dispatch(getAllTimesheet({}));
    dispatch(getAllStatus({}));
  }, [dispatch]);

  useEffect(() => {
    filterMentorData(typeId);
  }, [allTimesheets]);

  const filterMentorData = (id: any) => {
    const filteredData =
      allTimesheets &&
      allTimesheets.length > 0 &&
      allTimesheets.filter((item: any) => item.timesheetStatusId === id);

    setTimesheetDetails(filteredData);
    setDefault(false);
    setprojectId(id);
    if (filteredData && filteredData.length > 0) {
      setActiveCard(filteredData[0].id);
    }
  };

  const filterHourLogData = (id: any) => {
    setActiveCard(id);
    const filteredData = allTimesheets && allTimesheets.filter((item: any) => item.id === id);
    setHourlogDetails(filteredData);
  };

  const Today = new Date();
  const StringToday = Today.toDateString();

  return status && status.data && status.data.length > 0 ? (
    <Container>
      <HeaderContainer>
        <LeftHeader>
          <Text style={{ fontSize: "28px", fontWeight: 600, color: "#434343" }}>Timesheets</Text>
          <Text style={{ color: "#434343" }}>{StringToday}</Text>
        </LeftHeader>
        <RightHeader />
      </HeaderContainer>
      <BodyContainer>
        <RadioContainer>
          <Radio.Group
            className="timesheetRadiogroup"
            defaultValue="pendingApproval"
            style={{ marginBottom: 8 }}
          >
            {status &&
              status.data &&
              status.data.map((status: any) => (
                <Radio.Button
                  value={status.name}
                  onClick={() => {
                    setTypes(status.name);
                    filterMentorData(status.id);
                    setTypeId(status.id);
                  }}
                >
                  <Wrapper>
                    <CustomBadge
                      style={{
                        backgroundColor: `${
                          status.id === 1 || status.id === 2 ? "#008CE9" : "#E90036"
                        }`,
                      }}
                    >
                      {status.count}
                    </CustomBadge>
                    {status.name}
                  </Wrapper>
                </Radio.Button>
              ))}
          </Radio.Group>
        </RadioContainer>
        <TimesheetContainer>
          <Row gutter={24}>
            <Col span={8}>
              {isDefault && defaultMentorDetails.length > 0 && (
                <CardContainer>
                  {defaultMentorDetails.map((item: any, index: any) => (
                    <MentorCard
                      projectDetails={item}
                      filterHourLogData={filterHourLogData}
                      firstChild={index === firstChild ? true : false}
                      setfirstChild={setfirstChild}
                      activeCard={activeCard}
                    />
                  ))}
                </CardContainer>
              )}
              {!isDefault && timesheetDetails.length > 0 && (
                <CardContainer>
                  {timesheetDetails.map((item: any, index: any) => (
                    <MentorCard
                      projectDetails={item}
                      filterHourLogData={filterHourLogData}
                      firstChild={index === firstChild ? true : false}
                      setfirstChild={setfirstChild}
                      activeCard={activeCard}
                    />
                  ))}
                </CardContainer>
              )}
            </Col>
            <Col span={16}>
              <TimesheetTable
                types={types}
                hourlogDetails={hourlogDetails}
                projectId={projectId}
                filterMentorData={filterMentorData}
                filterHourLogData={filterHourLogData}
              />
            </Col>
          </Row>
        </TimesheetContainer>
      </BodyContainer>
    </Container>
  ) : (
    <LoadingSpinner margin={300} />
  );
};
export default Timesheets;
