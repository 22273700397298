import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Typography, Row, Col, Avatar, Button, Form, Input, Select, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { getAllCountries } from '../../../redux/actions/countriesAction';
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser, getIndividualUser } from '../../../redux/actions/usersAction';
import LoadingSpinner from '../../LoadingSpinner';

const { Text } = Typography;

const FormContainer = styled.div`
    margin-top: 60px;
    padding: 20px;
`;
const StyledInput = styled(Input)`
    color: #434343;
    font-size: 13px;
    //   height: 46px;
`;
const SaveButton = styled(Button)`
    background: transparent linear-gradient(103deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    height: 42px;
    width: 112px;
    color: white;
    &:hover {
        background: transparent linear-gradient(103deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat;
        color: white;
    }
    &:focus {
        background: transparent linear-gradient(103deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat;
        color: white;
    }
`;
const StyledSelect = styled(Select)`
    &.ant-select-selector {
        height: 46px !important;
    }
`;

const StyledLoadingSpinner = styled(LoadingSpinner)``;
interface ProfileProps {
    individualUser: any;
}

const EditProfile = (props: ProfileProps) => {
    const { Option } = Select;
    const [form] = useForm();
    const { individualUser } = props;
    const dispatch = useDispatch();
    let userId: any = localStorage.getItem('current');
    const { countries } = useSelector((state: RootState) => state.countries);
    const { loading } = useSelector((state: RootState) => state.users);
    useEffect(() => {
        dispatch(getAllCountries({}));
    }, []);
    const handleEditSubmit = (value: any) => {
        const data = value;
        data['id'] = individualUser.id;
        const formData: any = new FormData();

        for (var key in data) {
            formData.append(key, data[key]);
        }

        dispatch(
            updateUser({
                data: formData,
                onSuccess: () => {
                    notification.success({
                        message: 'Success',
                        description: 'User has been successfully edited.',
                    });
                    dispatch(getIndividualUser({ params: { profileId: userId } }));
                },
                onFailure: () => {
                    notification.error({
                        message: 'Error',
                        description: 'Failed to edit user.',
                    });
                },
            })
        );
    };
    return (
        <>
            <FormContainer>
                {loading ? (
                    <StyledLoadingSpinner />
                ) : (
                    <Form name="basic" layout="vertical" form={form} onFinish={handleEditSubmit}>
                        <Row gutter={[16, 8]}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item name="firstName" initialValue={individualUser ? individualUser.firstName : ''}>
                                    <StyledInput placeholder="First Name" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item name="lastName" initialValue={individualUser ? individualUser.lastName : ''}>
                                    <StyledInput placeholder="Last Name" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item name="email" initialValue={individualUser ? individualUser.email : ''}>
                                    <StyledInput placeholder="Email" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item name="phone" initialValue={individualUser ? individualUser.phone : ''}>
                                    <StyledInput placeholder="Phone Number" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item name="userName" initialValue={individualUser ? individualUser.userName : ''}>
                                    <StyledInput placeholder="Username" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    name="countryId"
                                    rules={[{ required: true, message: 'Please select a country!' }]}
                                    initialValue={individualUser ? individualUser.countryId : ''}
                                >
                                    <StyledSelect placeholder="Country" style={{ fontSize: '13px' }}>
                                        {countries &&
                                            countries.map((country: any) => (
                                                <Option key={country.id} value={country.id} style={{ fontSize: '13px' }}>
                                                    {country.name}
                                                </Option>
                                            ))}
                                    </StyledSelect>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <SaveButton loading={loading} htmlType="submit">
                                    Save
                                </SaveButton>
                            </Col>
                        </Row>
                    </Form>
                )}
            </FormContainer>
        </>
    );
};
export default EditProfile;
