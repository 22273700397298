import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { createFromIconfontCN, LogoutOutlined } from "@ant-design/icons";
import Avatar from "antd/lib/avatar/avatar";
import UserPNG from "../image/avatar.png";
import Text from "antd/lib/typography/Text";
import { Badge, Button, Dropdown, Menu } from "antd";
import { withRouter } from "react-router";
import jwt from "jwt-decode";
import { getIndividualUser } from "../redux/actions/usersAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";

const RightHeaderContainer: any = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
`;

const ProfileDetail: any = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 12px;
  padding-left: 30px;
`;
const ProfileContainer: any = styled.div`
  display: flex;
`;

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2453178_q5r5ntd0lf.js",
});

const RightHeader = (props: any) => {
  const { history } = props;
  const dispatch = useDispatch();
  let userId: any = localStorage.getItem("current");
  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("current");
    localStorage.removeItem("loggedIn");
    history.push("/");
  };

  const individualUser = useSelector((state: RootState) => state.users.individualUser);

  useEffect(() => {
    dispatch(getIndividualUser({ params: { profileId: userId } }));
  }, []);

  const menu = (
    <Menu>
      <Menu.Item>
        <Button type="text" onClick={logOut}>
          <LogoutOutlined />
          Sign Out
        </Button>
      </Menu.Item>
    </Menu>
  );

  let token: any = localStorage.getItem("token");
  const user: any = jwt(token);

  return (
    <RightHeaderContainer>
      <Badge dot>
        <IconFont type="icon-notification" style={{ fontSize: "28px", color: "#169AF4" }} />
      </Badge>
      <ProfileContainer>
        <ProfileDetail>
          <Text style={{ fontSize: "18px", color: "#434343" }}>{user.roles}</Text>
          <div style={{ cursor: "pointer" }}>
            <Text style={{ color: "#169AF4" }}>View Profile</Text>
          </div>
        </ProfileDetail>
        <Dropdown overlay={menu} placement="bottomCenter" trigger={["click"]} arrow>
          <div style={{ cursor: "pointer" }}>
            {individualUser.imageUrl ? (
              <Avatar src={individualUser.imageUrl} size={40} />
            ) : (
              <Avatar src={UserPNG} size={40} />
            )}
          </div>
        </Dropdown>
      </ProfileContainer>
    </RightHeaderContainer>
  );
};

export default withRouter(RightHeader);
