import React from 'react';
import Company from './page/Company';
import MainRoute from './router'

function App() {
  return (
    <MainRoute/>
  );
}

export default App;
