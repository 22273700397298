import { countries } from "./country";
import { roles } from "./roles";
import { mentorTypes } from "./mentorTypes";
import { users } from "./users";
import { candidateTypes } from "./candidateTypes";
import { technologyStackTypes } from "./technologyStackTypes";
import { companyTypes } from "./companyTypes";
import { projects } from "./project";
import { mentorHourLogs } from "./mentorHourLogs";
import { timesheetTypes } from "./timesheetTypes";
import { invociesTypes } from "./invoiceTypes";

const types = {
  ...users,
  ...countries,
  ...candidateTypes,
  ...companyTypes,
  ...roles,
  ...mentorTypes,
  ...technologyStackTypes,
  ...projects,
  ...mentorHourLogs,
  ...timesheetTypes,
  ...invociesTypes,
};

export default types;
