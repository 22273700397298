import types from "../types";

const initialState = {
  loading: false,
  allTimesheets: [],
  status: [],
  error: null,
};

const timesheetReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.CREATE_TIMESHEET:
    case types.GETALL_TIMESHEET:
    case types.GETALL_STATUS:
    case types.APPROVE_TIMESHEET:
    case types.DELETE_TIMESHEET:
      return {
        ...state,
        loading: true,
      };
    case types.GETALL_TIMESHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        allTimesheets: action.payload,
        error: null,
      };

    case types.GETALL_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload,
        error: null,
      };
    case types.CREATE_TIMESHEET_SUCCESS:
    case types.APPROVE_TIMESHEET_SUCCESS:
    case types.DELETE_TIMESHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case types.TIMESHEET_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default timesheetReducer;
