import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Typography, Tabs } from "antd";
import AddCompany from "./AddCompany";
import AddBankingDetails from "./AddBankingDetails";

const { TabPane } = Tabs;

const Container = styled.div`
  background: transparent linear-gradient(98deg, #3bb1ff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  width: 465px;
  height: 94px;
`;
const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 22px 22px 45px;
`;
const ModalBody = styled.div`
  padding: 40px;
  background-color: white;
`;

const { Text } = Typography;

interface ICreateFormProps {
  setVisible: (visible: boolean) => void;
  editItem: any;
  searchItem: any;
  allCompanies: any;
  isDisabled: any;
  setDisabled: any;
}

function CreateForm(props: ICreateFormProps) {
  const { editItem, setVisible, searchItem, allCompanies, isDisabled, setDisabled } = props;

  return (
    <Container>
      <ModalHeader>
        <Text style={{ color: "white", fontWeight: 300, fontSize: "13px" }}>
          {editItem ? "Edit Company" : "Add Company"}
        </Text>
        <Text style={{ color: "white", fontWeight: 300, marginTop: "5px", fontSize: "16px" }}>
          Enter Company details below
        </Text>
      </ModalHeader>
      <ModalBody>
        <Tabs type="card">
          <TabPane tab="Company Details" key="1">
            <AddCompany
              editItem={editItem}
              setVisible={setVisible}
              allCompanies={allCompanies}
              searchItem={searchItem}
              setDisabled={setDisabled}
            />
          </TabPane>
          <TabPane tab="Payment Details" key="2" disabled={isDisabled && !editItem}>
            <AddBankingDetails
              allCompanies={allCompanies}
              editItem={editItem}
              setVisible={setVisible}
            />
          </TabPane>
        </Tabs>
      </ModalBody>
    </Container>
  );
}
export default CreateForm;
