import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import styled from "@emotion/styled";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Typography,
  InputNumber,
  DatePicker,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  createProject,
  getAllProjects,
  updateProject,
  getProjectsById,
} from "../../../redux/actions/projectAction";
import { getAllMentorDetails } from "../../../redux/actions/mentorAction";
import { getAllCandidateDetails } from "../../../redux/actions/candidateActions";
import { getAllTechnologyStackDetails } from "../../../redux/actions/technologyStackAction";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

const Container = styled.div`
  background: transparent linear-gradient(98deg, #3bb1ff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  width: 465px;
  height: 94px;
`;
const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 22px 22px 35px;
`;
const ModalBody = styled.div`
  padding: 35px;
  background-color: white;
`;

const AddButton = styled(Button)`
  background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 3px;
  color: white;
  width: 100%;
  margin-top: 20px;
  &:hover {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
`;
const StyledInput = styled(Input)`
  font-size: 13px;
`;
const StyledInputNumber = styled(InputNumber)`
  font-size: 13px;
  margin-bottom: 5px;
  width: 100%;
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input-wrap {
    height: 27px;
  }
`;

const StyledForm = styled(Form)``;
const { Text } = Typography;
const { Option } = Select;

interface ProjectProps {
  setVisible: (visible: boolean) => void;
  editData: any;
    searchData: any;
    isActive: boolean;
}

function ProjectForm(props: ProjectProps) {
    const { setVisible, editData, searchData, isActive } = props;
  const [form] = useForm();
  const dispatch = useDispatch();
  const { mentors } = useSelector((state: RootState) => state.mentors);
  const { allCandidates } = useSelector((state: RootState) => state.candidates);
  const { projectById, loading, projects } = useSelector((state: RootState) => state.projects);
  const dateFormat = "MM/DD/YYYY";
  const newStartDate =
    editData && editData.startDate && moment(editData.startDate).format("MM/DD/YYYY");
  const newEndDate = editData && editData.endDate && moment(editData.endDate).format("MM/DD/YYYY");

  useEffect(() => {
    form.setFieldsValue({
      startDate:
        editData && editData.startDate && moment(moment(editData.startDate).format("MM/DD/YYYY")),
      endDate:
        editData && editData.endDate && moment(moment(editData.endDate).format("MM/DD/YYYY")),
    });
  }, []);

  const handleAddSubmit = (value: any) => {
    const data = value;
    data["startDate"] = moment(value.startDate).format("YYYY-MM-DD");
    data["endDate"] = value.endDate === null ? null : moment(value.endDate).format("YYYY-MM-DD");
    data["createrId"] = localStorage.getItem("current");
    data["projectStatusId"] = 1;
    dispatch(
      createProject({
        data,
        onSuccess: () => {
          setVisible(false);
          form.resetFields();
          notification.success({
            message: "Success",
            description: "Project has been successfully created",
            duration: 2,
          });
          dispatch(
            getAllProjects({
              params: {
                PageNumber: projects.data.pageNumber,
                    pageSize: projects.data.pageSize,
                isActive:isActive
              },
            })
          );
        },
      })
    );
  };

  const handleEditSubmit = (value: any) => {
    const data = value;
    data["startDate"] = moment(value.startDate).format("YYYY-MM-DD");
    data["endDate"] =
      value.endDate === "" || value.endDate === null
        ? null
        : moment(value.endDate).format("YYYY-MM-DD");
    if (typeof data.candidateId != "number") {
      data.candidateId = editData.candidateId;
    }
    if (data.startDate === undefined) {
      data.startDate = moment(newStartDate);
    }
    data.id = editData.id;
    data.mentorId = editData.mentorId;
    data.projectStatusId = editData.projectStatusId;

    dispatch(
      updateProject({
        data,
        onSuccess: () => {
          setVisible(false);
          form.resetFields();
          notification.success({
            message: "Success",
            description: "Project has been successfully updated",
            duration: 2,
          });
          dispatch(
            getAllProjects({
              params: {
                PageNumber: projects.data.pageNumber,
                pageSize: projects.data.pageSize,
                    Name: searchData,
                isActive:isActive
              },
            })
          );
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getAllMentorDetails({}));
    dispatch(getAllCandidateDetails({}));
    dispatch(getAllTechnologyStackDetails({}));
  }, [dispatch]);

  const disabledDate = (current: any) => {
    const startDate = form.getFieldValue("startDate");
    return current < startDate;
  };

  return (
    <Container>
      <ModalHeader>
        <Text style={{ color: "white", fontWeight: 300, fontSize: "13px" }}>
          {editData ? "Edit Projects" : "Add Projects"}
        </Text>
        <Text style={{ color: "white", fontWeight: 300, marginTop: "5px", fontSize: "16px" }}>
          Enter details below
        </Text>
      </ModalHeader>
      <ModalBody>
        <StyledForm
          form={form}
          onFinish={editData ? handleEditSubmit : handleAddSubmit}
          name="basic"
          layout="vertical"
        >
          <Row gutter={[16, 4]}>
            {!editData && (
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="mentorId"
                  rules={[{ required: true, message: "Please select a mentor!" }]}
                  label="Mentor Name"
                >
                  <Select
                    placeholder="Select a Mentor"
                    style={{ fontSize: "13px" }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {mentors &&
                      mentors.data &&
                      mentors.data.results.filter((mentor:any)=>mentor.isActive).map((mentor: any) => (
                        <Option key={mentor.id} value={mentor.id} style={{ fontSize: "13px" }}>
                          {`${mentor.firstName} ${mentor.middleName} ${mentor.lastName}`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col className="gutter-row" span={24}>
              {editData ? (
                <Form.Item
                  name="candidateId"
                  initialValue={editData ? editData.candidateName : ""}
                  label="Candidate Name"
                >
                  <Select
                    placeholder="Select a Candidate"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ fontSize: "13px" }}
                  >
                    {allCandidates &&
                      allCandidates.data &&
                      allCandidates.data.results.map((candidate: any) => (
                        <Option
                          key={candidate.id}
                          value={candidate.id}
                          style={{ fontSize: "13px" }}
                        >
                          {`${candidate.firstName} ${candidate.middleName} ${candidate.lastName}`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  name="candidateId"
                  rules={[{ required: true, message: "Please select a candidate!" }]}
                  label="Candidate Name"
                >
                  <Select
                    placeholder="Select a Candidate"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ fontSize: "13px" }}
                  >
                    {allCandidates &&
                      allCandidates.data &&
                      allCandidates.data.results.filter((candidate:any)=>candidate.isActive).map((candidate: any) => (
                        <Option
                          key={candidate.id}
                          value={candidate.id}
                          style={{ fontSize: "13px" }}
                        >
                          {`${candidate.firstName} ${candidate.middleName} ${candidate.lastName}`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="rate"
                rules={[
                  { required: true, message: "Please enter rate!" },
                  { type: "number", min: 0, message: "rate cannot be negative" },
                ]}
                initialValue={editData ? editData.rate : ""}
                label="Rate"
              >
                <StyledInputNumber placeholder="Mentor Rate" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="startDate"
                label="Start date"
                rules={[{ required: true, message: "Please enter start date" }]}
              >
                <DatePicker
                  placeholder="mm/dd/yy"
                  // defaultValue={editData && editData.startDate && moment(newStartDate)}
                  format={dateFormat}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item name="endDate" label="End date">
                <DatePicker
                  placeholder="mm/dd/yy"
                  // defaultValue={editData && editData.endDate && moment(newEndDate)}
                  format={dateFormat}
                  style={{ width: "100%" }}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="description"
                initialValue={editData ? editData.description : ""}
                label="Description"
              >
                <TextArea rows={4} placeholder="Add Description" />
              </Form.Item>
            </Col>
          </Row>
          <AddButton loading={loading} htmlType="submit">
            {editData ? "Update" : "Create"}
          </AddButton>
        </StyledForm>
      </ModalBody>
    </Container>
  );
}
export default ProjectForm;
