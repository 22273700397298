import types from "../types";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import instance from "../../lib/axios/axiosInstance";
import { AxiosResponse } from "axios";
import {
  createProjectSuccess,
  deleteProjectSuccess,
  getAllProjectsSuccess,
  projectRequestError,
  updateProjectSuccess,
  getProjectByIdSuccess,
  getAllProjectStatusSuccess,
  updatelProjectStatusSuccess,
  getAllProjectsBymentorIdSuccess,
} from "../actions/projectAction";
import { notification } from "antd";

function* fetchAllProjects(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/Project/GetAll", action.payload)
    );
    yield put(getAllProjectsSuccess(response.data));
  } catch (error) {
    yield put(projectRequestError(error));
  }
}

function* fetchProjectsStatus(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/Project/GetAllProjectStatus", action.payload)
    );
    yield put(getAllProjectStatusSuccess(response.data));
  } catch (error) {
    yield put(projectRequestError(error));
  }
}

function* fetchProjectById(action: any) {
  // try {
  //     const response: AxiosResponse = yield call(() => instance.get('/api/v1.1/Project/GetById', action.payload));
  //     yield put(getProjectByIdSuccess(response.data));
  // } catch (error) {
  //     yield put(projectRequestError(error));
  // }
}

function* fetchAllProjectsBymentorId(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/Project/GetAllProjectByMentorId", action.payload)
    );
    yield put(getAllProjectsBymentorIdSuccess(response.data));
  } catch (error) {
    yield put(projectRequestError(error));
  }
}
function* addProject(action: any) {
  try {
    yield call(() => instance.post("/api/v1.1/Project/Create", action.payload.data));
    yield put(createProjectSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(projectRequestError(error));
    notification.error({
      message: "Create Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* updateProjectDetail(action: any) {
  try {
    yield call(() => instance.put("/api/v1.1/Project/Update", action.payload.data));
    yield put(updateProjectSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(projectRequestError(error));
    notification.error({
      message: "Update Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* updateProjectsStatus(action: any) {
  try {
    yield call(() => instance.put("/api/v1.1/Project/ChangeStatus", action.payload.data));
    yield put(updatelProjectStatusSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(projectRequestError(error));
    notification.error({
      message: "Status Change Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* removeProject(action: any) {
  try {
    yield call(() => instance.delete("/api/v1.1/Project/Delete", action.payload));
    yield put(deleteProjectSuccess());
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(projectRequestError(error));
    notification.error({
      message: "Delete Failed",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

export function* projectSaga() {
  yield takeEvery(types.GETALL_PROJECTS, fetchAllProjects);
  yield takeEvery(types.GET_PROJECTS_BY_MENTORID, fetchAllProjectsBymentorId);
  yield takeEvery(types.GET_PROJECT_STATUS, fetchProjectsStatus);
  yield takeEvery(types.GET_PROJECTS_BYID, fetchProjectById);
  yield takeEvery(types.CREATE_PROJECT, addProject);
  yield takeEvery(types.UPDATE_PROJECT, updateProjectDetail);
  yield takeEvery(types.UPDATE_PROJECT_STATUS, updateProjectsStatus);
  yield takeEvery(types.DELETE_PROJECT, removeProject);
}
