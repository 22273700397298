import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Typography, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";

const { Text } = Typography;
const { TextArea } = Input;

const Header = styled.div`
  padding: 30px 35px;
  background: #f9fcff 0% 0% no-repeat padding-box;
`;
const BodyContainer = styled.div`
  padding: 30px 35px;
`;
const StyledTextArea = styled(TextArea)`
  &.ant-input[disabled] {
    background: #f9fcff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;
    color: black;
  }
`;

const PaypalDetails = (props: any) => {
  const { paymentDetails } = props;
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({ paypalURL: paymentDetails.paypalURL });
  }, [paymentDetails]);

  const emailLabel = <Text style={{ fontSize: "18px" }}>Email</Text>;
  const urlLabel = <Text style={{ fontSize: "18px" }}>URL</Text>;
  return (
    <React.Fragment>
      <Header>
        <Text style={{ fontSize: "20px", fontWeight: 500 }}>Paypal Details</Text>
      </Header>
      <BodyContainer>
        <Form layout="vertical" form={form}>
          <Form.Item name="paypalURL" label={urlLabel}>
            <StyledTextArea disabled={true} rows={4} />
          </Form.Item>
        </Form>
      </BodyContainer>
    </React.Fragment>
  );
};
export default PaypalDetails;
