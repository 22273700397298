export const users = {
  GETALL_USERS: "GETALL_USERS",
  GETALL_USERS_SUCCESS: "GETALL_USERS_SUCCESS",

  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",

  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",

  UPDATE_USER_STATUS: "UPDATE_USER_STATUS",
  UPDATE_USER_STATUS_SUCCESS: "UPDATE_USER_STATUS_SUCCESS",

  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",

  USER_REQUEST_ERROR: "USER_REQUEST_ERROR",

  GET_INDIVIDUAL_USER: "GET_INDIVIDUAL_USER",
  GET_INDIVIDUAL_USER_SUCCESS: "GET_INDIVIDUAL_USER_SUCCESS",
};
