import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCandidateDetail,
  getAllCandidateDetails,
  candidateMentorDetails
} from "../../redux/actions/candidateActions";
import { RootState } from "../../redux/store";
import { useHistory } from "react-router";
import { DeleteOutlined, FormOutlined, EyeOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Input, Popconfirm, Table, Typography, notification, Row, Col, Badge } from "antd";

import AddCandidate from "./components/AddCandidate";
import StatusChange from "./components/StatusChange";
import RightHeader from "../../components/RightHeader";
import jwtDecode from "jwt-decode";
import CustomPagination from "../../components/CustomPagination";
import CustomDrawer from "../../components/CustomDrawer";

const { Text } = Typography;
const { Search } = Input;
const Container: any = styled.div`
  padding: 25px 50px 25px 50px;
`;
const HeaderContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BodyContainer: any = styled.div`
  margin-top: 35px;
  padding: 15px 0px 0px 0px;
`;
const LeftHeader: any = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSearch = styled(Search)`
  .ant-input-wrapper {
    box-shadow: 0px 8px 10px #008ce914;
    width: 280px;
    height: 30px;
    border-radius: 6px;
  }
  .ant-input {
    border: 1px solid #008ce940;
    border-radius: 6px;
    padding-top: 6px;
    color: #434343;
    height: 40px;
  }
  .ant-btn,
  .ant-btn-icon-only,
  .ant-input-search-button {
    border: none;
    box-shadow: none;
    margin-left: 2px;
  }

  .ant-input-group-addon {
    border: 1px solid #008ce940;
    border-left: none;
    border-radius: 6px;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #008ce940 !important ;
    border-left: none !important;
    background-color: white !important;
  }
  .ant-input-group input::placeholder {
    color: #434343;
    opacity: 0.7;
  }
`;
const AddUserButton = styled(Button)`
  background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 8px 10px #008ce914;
  border-radius: 6px;
  height: 40px;
  width: 175px;
  border: none;
  color: white;
  &:hover {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
`;
const EditContainer = styled(FormOutlined)`
  background: #e3f4ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 18px #008ce912;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #008ce9;
`;
const ViewContainer = styled(EyeOutlined)`
  background: #e3f4ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 18px #008ce912;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#AB01FF
`;
const DeleteContainer = styled(DeleteOutlined)`
  background: #ffe9e9 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 7px #ff00000d;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000;
`;

const StyledTable = styled(Table)`
  .ant-table-row,
  .ant-table-row-level-0 {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000008;
    // border: 1px solid #008ce933;
  }
  .ant-table-thead > tr > th {
    background: #f9fcff 0% 0% no-repeat padding-box;
    font-size: 16px;
  }
  .ant-table-tbody > tr > td {
    // border-bottom: 1px solid #008ce930;
  }
  .ant-table-row {
    box-shadow: 1px 1px 10px #1c3faa1a !important;
  }
  .ant-table-tbody > tr:last-child {
    & > td {
      border-bottom: none;
    }
  }
`;
const ButtonWrapper = styled(Row)`
   margin-top:20px;
   .activeBtn{
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
    color: white;
   }`;
const StyledButton = styled(Button)`
background: #80808066;
box-shadow: 0px 8px 10px #008ce914;
border-radius: 6px;
width: 100px;
height: 40px;
`;

function Candidate() {
  const dispatch = useDispatch();
  const [isActive, setIsActive]=useState(true);
  const [editItem, setEditItem] = useState(null);
  const [visible, setVisible] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const { allCandidates, loading } = useSelector((state: RootState) => state.candidates);
  console.log(allCandidates);
  let token: any = localStorage.getItem("token");
  const user: any = jwtDecode(token);
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllCandidateDetails({ params: { PageNumber: 1, pageSize: 10, isActive:isActive } }));
  }, [dispatch]);

  useEffect(() => {
    if (searchItem === "") {
      dispatch(getAllCandidateDetails({ params: { PageNumber: 1, pageSize: 10, isActive:isActive } }));
    }
  }, [searchItem]);

  useEffect(() => {
    dispatch(
      getAllCandidateDetails({
        params: {
          PageNumber: 1,
          PageSize: 10,
          Name: searchItem,
          isActive: isActive
        },
      })
    );
  }, [isActive]);

  const editCandidateDetails = (item: any) => {
    setVisible(true);
    setEditItem(item);
  };

  const handleDeleteCompanyDetails = (id: any) => {
    const isLastElement = allCandidates.data.results.length === 1;
    if (isLastElement && allCandidates.data.totalCount === 1) {
      setSearchItem("");
    }
    dispatch(
      deleteCandidateDetail({
        params: { id },
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "Candidate has been successfully deleted.",
            duration: 2,
          });
          dispatch(
            getAllCandidateDetails({
              params: {
                PageNumber:
                  isLastElement && allCandidates.data.totalCount > 1
                    ? allCandidates.data.pageNumber - 1
                    : allCandidates.data.pageNumber,
                pageSize: allCandidates.data.pageSize,
                Name: allCandidates.data.totalCount === 1 ? null : searchItem,
                isActive:isActive
              },
            })
          );
        },
      })
    );
  };

  const handleTableChange = (current: any, pageSize: any) => {
    dispatch(
      getAllCandidateDetails({
        params: { PageNumber: current, pageSize: pageSize, Name: searchItem, isActive:isActive },
      })
    );
    setSearchItem("");
  };

  const onSearch = (value: any) => {
    dispatch(getAllCandidateDetails({ params: { PageNumber: 1, pageSize: 10, Name: value, isActive:isActive } }));
  };

  const onChangeSearch = (e: any) => {
    setSearchItem(e.target.value);
  };
  const columns = [
    {
      title: "Candidate",
      dataIndex: "firstName",
      key: "firstName",
      render: (firstname: any, record: any) => (
        <span>
          {firstname} {record.middleName} {record.lastName}
        </span>
      ),
    },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone Number", dataIndex: "phoneNumber", key: "phoneNumber" },
    { title: "Support Hours", dataIndex: "supportHours", key: "supportHours" },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "x",
      render: (isActive: any, record: any) => (
        <div>
          <StatusChange item={isActive} record={record} searchItem={searchItem} isActive={isActive} />
        </div>
      ),
    },
    user.roles === "Super Admin"
      ? {}
      : {
          title: "Action",
          dataIndex: "id",
          key: "id",
          render: (item: any, record: any) => (
            <div style={{ display: "flex", alignItems: "center" }}>
               <ViewContainer onClick={()=>{history.push(`/candidate/details/${item}`)}}>
                <EyeOutlined/> 
              </ViewContainer>
              <EditContainer onClick={() => editCandidateDetails(record)}>
                <FormOutlined style={{ color: "red" }} />
              </EditContainer>
              <Popconfirm
                title="Are you sure to delete this Candidate Detail?"
                onConfirm={() => handleDeleteCompanyDetails(item)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteContainer>
                  <DeleteOutlined />
                </DeleteContainer>
              </Popconfirm>
            </div>
          ),
        },
  ];

  const Today = new Date();
  const StringToday = Today.toDateString();

  return (
    <Container>
      <HeaderContainer>
        <LeftHeader>
          <Text style={{ fontSize: "28px", fontWeight: 600, color: "#434343" }}>Candidate</Text>
          <Text style={{ color: "#434343" }}>{StringToday}</Text>
        </LeftHeader>
        <RightHeader />
      </HeaderContainer>
      <BodyContainer>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <StyledSearch
            placeholder="Search by name"
            onChange={onChangeSearch}
            onSearch={onSearch}
          />
          {user.roles === "Super Admin" ? (
            <></>
          ) : (
            <AddUserButton
              style={{ paddingLeft: "18px" }}
              onClick={() => {
                setVisible(!visible);
                setEditItem(null);
              }}
            >
              + Add Candidate
            </AddUserButton>
          )}
        </div>
        <ButtonWrapper>
          <Badge count={allCandidates && allCandidates.data && allCandidates.data.activeCount} overflowCount={1000}>
            <StyledButton className={isActive?"activeBtn":" "} onClick={()=>setIsActive(true)}>
              Active
            </StyledButton>
          </Badge>
          <Badge count={allCandidates && allCandidates.data && allCandidates.data.inactiveCount} overflowCount={1000}>
            <StyledButton  style={{marginLeft:"18px"}} className={!isActive?"activeBtn":" "} onClick={()=>setIsActive(false)}>
              Inactive
            </StyledButton>
          </Badge>
        </ButtonWrapper>
        <div
          style={{
            border: "1px solid #008ce930",
            boxShadow: "0px 20px 22px #008ce905",
            marginTop: "25px",
          }}
        >
          <StyledTable
            columns={columns}
            dataSource={allCandidates && allCandidates.data && allCandidates.data.results}
            pagination={false}
            loading={loading}
          />
        </div>
        <CustomPagination
          size="small"
          total={allCandidates && allCandidates.data && allCandidates.data.totalCount}
          current={allCandidates && allCandidates.data && allCandidates.data.pageNumber}
          defaultPageSize={10}
          defaultCurrent={1}
          showSizeChanger={true}
          onChange={handleTableChange}
        />
        <CustomDrawer
          title=""
          placement="right"
          mask={false}
          onClose={() => setVisible(false)}
          visible={visible}
          width={470}
          destroyOnClose={true}
        >
          <AddCandidate
            editItem={editItem}
            setVisible={setVisible}
            allCandidates={allCandidates}
            searchItem={searchItem}
            isActive={isActive}
          />
        </CustomDrawer>
      </BodyContainer>
    </Container>
  );
}
export default Candidate;
