import React from 'react';
import ReactApexChart from 'react-apexcharts';

function BarChart() {
    const state = {
        options: {
            chart: {
                id: 'Days',
            },
            xaxis: {
                categories: ['Growth'],
            },
        },
        series: [
            {
                name: 'Total Project',
                data: [50],
            },
        ],
    };

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
            <ReactApexChart key="area-chart" options={state.options} series={state.series} type="bar" width="50%" height="320" />
        </div>
    );
}
export default BarChart;
