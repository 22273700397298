import types from '../types';

export function getAllMentorHourLogs(data: any) {
  return {
    type: types.GETALL_MENTORHOURLOG,
    payload: data,
  };
}

export function getAllMentorHourLogsSuccess(mentorHourLogs: any) {
  return {
    type: types.GETALL_MENTORHOURLOG_SUCCESS,
    payload: mentorHourLogs,
  };
}

export function createHourLog(data: any) {
  return {
    type: types.CREATE_HOURLOG,
    payload: data,
  };
}

export function createHourLogSuccess(data: any) {
  return {
    type: types.CREATE_HOURLOG_SUCCESS,
    payload: data,
  };
}

export function updateHourLog(data: any) {
  return {
    type: types.UPDATE_HOURLOG,
    payload: data,
  };
}

export function updateHourLogSuccess(data: any) {
  return {
    type: types.UPDATE_HOURLOG_SUCCESS,
    payload: data,
  };
}

export function deleteHourLog(data: any) {
  return {
    type: types.DELETE_HOURLOG,
    payload: data,
  };
}

export function deleteHourLogSuccess(data: any) {
  return {
    type: types.DELETE_HOURLOG_SUCCESS,
    payload: data,
  };
}

export function approveHourLog(data: any) {
  return {
    type: types.APPROVE_HOURLOG,
    payload: data,
  };
}

export function approveHourLogSuccess(data: any) {
  return {
    type: types.APPROVE_HOURLOG_SUCCESS,
    payload: data,
  };
}


export function saveHourLog(data: any) {
  return {
    type: types.SAVE_HOURLOG,
    payload: data,
  };
}

export function saveHourLogSuccess(data: any) {
  return {
    type: types.SAVE_HOURLOG_SUCCESS,
    payload: data,
  };
}

export function hourLogRequestError(data: any) {
  return {
    type: types.HOURLOG_REQUEST_ERROR,
    payload: data,
  };
}
