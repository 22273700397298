import {
  createHourLogSuccess,
  deleteHourLogSuccess,
  getAllMentorHourLogs,
  getAllMentorHourLogsSuccess,
  hourLogRequestError,
  updateHourLogSuccess,
  approveHourLogSuccess,
  saveHourLogSuccess,
} from "../actions/mentorHourLogAction";
import types from "../types";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import { notification } from "antd";
import instance from "../../lib/axios/axiosInstance";
import { AxiosResponse } from "axios";

function* fetchAllMentorHourLogs(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.get("/api/v1.1/HourLog/GetAllProjectHourLog", action.payload)
    );
    yield put(getAllMentorHourLogsSuccess(response.data));
  } catch (error) {
    yield put(hourLogRequestError(error));
  }
}

function* addHourLog(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.post("/api/v1.1/HourLog/Create", action.payload.data)
    );
    yield put(createHourLogSuccess(response.data));
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(hourLogRequestError(error));
    yield call(action.payload.onFailure);
  }
}

function* editHourLog(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.put("/api/v1.1/HourLog/Update", action.payload.data)
    );
    yield put(updateHourLogSuccess(response.data));
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(hourLogRequestError(error));
    yield call(action.payload.onFailure);
  }
}

function* removeHourLog(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.delete("/api/v1.1/HourLog/Delete", action.payload)
    );
    yield put(deleteHourLogSuccess(response.data));
    notification.success({
      message: "Success",
      description: "Hour entry has been successfully deleted.",
    });
    yield put(getAllMentorHourLogs({}));
  } catch (error) {
    yield put(hourLogRequestError(error));
    notification.error({
      message: "Error",
      description: "Failed to delete hour entry.",
      duration: 2,
    });
  }
}

function* approveHourLog(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.put("/api/v1.1/HourLog/ApprovedByCandidate", action.payload.data)
    );
    yield put(approveHourLogSuccess(response.data));
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(hourLogRequestError(error));
    notification.error({
      message: "Error",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

function* saveHourLog(action: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      instance.put("/api/v1.1/HourLog/AddOrUpdateProjectHourlog", action.payload.data)
    );
    yield put(saveHourLogSuccess(response.data));
    yield call(action.payload.onSuccess);
  } catch (error) {
    yield put(hourLogRequestError(error));
    notification.error({
      message: "Error",
      description: error.response.data.message.description,
      duration: 2,
    });
  }
}

export function* mentorHourLogSaga() {
  yield takeEvery(types.GETALL_MENTORHOURLOG, fetchAllMentorHourLogs);
  yield takeEvery(types.CREATE_HOURLOG, addHourLog);
  yield takeEvery(types.UPDATE_HOURLOG, editHourLog);
  yield takeEvery(types.DELETE_HOURLOG, removeHourLog);
  yield takeEvery(types.APPROVE_HOURLOG, approveHourLog);
  yield takeEvery(types.SAVE_HOURLOG, saveHourLog);
}
