import types from "../types";

export function getAllInvoices(data: any) {
  return {
    type: types.GETALL_INVOICES,
    payload: data,
  };
}

export function getAllInvoicesSuccess(data: any) {
  return {
    type: types.GETALL_INVOICES_SUCCESS,
    payload: data,
  };
}

export function getInvoiceById(data: any) {
  return {
    type: types.GET_INVOICE_BYID,
    payload: data,
  };
}

export function getInvoiceByIdSuccess(data: any) {
  return {
    type: types.GET_INVOICEBYID_SUCCESS,
    payload: data,
  };
}

export function generateInvoices(data: any) {
  return {
    type: types.GENERATE_INVOICE,
    payload: data,
  };
}

export function generateInvoiceSuccess() {
  return {
    type: types.GENERATE_INVOICE_SUTECCESS,
  };
}

export function invoiceDetailsError(data: any) {
  return {
    type: types.INVOICE_REQUEST_ERROR,
    payload: data,
  };
}

export function invoiceMarkAsPaidRequest(data: any) {
  return {
    type: types.INVOICE_MARKASPAID_REQUEST,
    payload: data,
  };
}

export function invoiceMarkAsPaidSuccess() {
  return {
    type: types.INVOICE_MARKASPAID_SUCCESS,
  };
}

export function invoiceMarkAsPaidError(data: any) {
  return {
    type: types.INVOICE_MARKASPAID_ERROR,
    payload: data,
  };
}
