import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import styled from "@emotion/styled";
import {
  Typography,
  Card,
  Dropdown,
  Menu,
  Row,
  Col,
  Tag,
  Checkbox,
} from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { candidateMentorDetails } from "../../../redux/actions/candidateActions";
import RightHeader from "../../../components/RightHeader";
import { ReactComponent as ProjectHourlogIcon } from "../../../image/Project Hourlog.svg";
import { ReactComponent as TechnologyStackIcon } from "../../../image/Technology stack icon.svg";
import moment from "moment";
import LoadingSpinner from "../../../components/LoadingSpinner";

const { Text } = Typography;
const Container: any = styled.div`
  padding: 25px 50px 25px 50px;
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BodyContainer = styled.div`
  margin-top: 35px;
  padding: 15px 15px 15px 20px;
  background: #f5fbff 0% 0% no-repeat padding-box;
`;
const LeftHeader = styled.div`
  display: flex;
  flex-direction: column;
`;
const TotalSupportHourBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  max-width: 250px;
  border: 1px solid #008ce930;
  border-radius: 10px;
  background: #b05fdd 0% 0% no-repeat padding-box;
  padding: 12px;
  svg {
    height: 20px;
    width: auto;
  }
`;
const BodyHeader = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
`;
const BodyMainContent = styled.div`
  margin: 0 0 0 45px;
`;
const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownButton = styled.div`
  max-width: 120px;
  max-height: 40px;
  padding: 10px;
  background: #eceaf3;
  border-radius: 10px;
  margin-right: 12px;
`;
const TechnologyStackBox = styled(Tag)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 5px 0 0;
  max-height: 40px;
  max-width: 300px;
  border-radius: 10px;
  background: #2ed6a3 0% 0% no-repeat padding-box;
  padding: 12px;
  overflow-wrap: break;
  hyphens: auto;
  svg {
    height: 20px;
    width: auto;
  }
`;
const HourLogBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 12px;
  max-height: 40px;
  max-width: 100px;
  border-radius: 10px;
  border: 1px solid #008ce930;
  background: transparent
    linear-gradient(110deg, #60c0ff 0%, #65c2ff 0%, #20a6ff 100%) 0% 0%
    no-repeat padding-box;
  padding: 12px;
  svg {
    height: 20px;
    width: auto;
  }
`;
const DataNotFoundBox = styled.div`
  height: 120px;
  width: 150px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CardBox = styled(Card)`
  width: 100%;
  margin-top: 10px;
  border-radius: 20px;
`;
const CandidateNameStyle = styled(Text)`
  font-size: 25px;
  font-weight: 600;
  color: #434343;
  margin-right: 20px;
`;
const MentorNameStyle = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  color: #434343;
  margin: 0 0 0 10px;
`;

const CandidateDetails = (props: any) => {
  const technologyStackMenu = (
    <Menu>
      <Menu.Item key="1">
        <Checkbox defaultChecked>Technology Stack A</Checkbox>
      </Menu.Item>
      <Menu.Item key="1">
        <Checkbox>Technology Stack B</Checkbox>
      </Menu.Item>
      <Menu.Item key="1">
        <Checkbox>Technology Stack C</Checkbox>
      </Menu.Item>
      <Menu.Item key="1">
        <Checkbox>Technology Stack D</Checkbox>
      </Menu.Item>
      <Menu.Item key="1">
        <Checkbox>Technology Stack E</Checkbox>
      </Menu.Item>
      <Menu.Item key="1">
        <Checkbox>Technology Stack F</Checkbox>
      </Menu.Item>
    </Menu>
  );

  const sortingMenu = (
    <Menu>
      <Menu.Item key="1">
        <Checkbox defaultChecked>Newest</Checkbox>
      </Menu.Item>
      <Menu.Item key="1">
        <Checkbox>Oldest</Checkbox>
      </Menu.Item>
    </Menu>
  );

  const emptyDataPage = () => (
    <DataNotFoundBox>
      <h5>No Data</h5>
    </DataNotFoundBox>
  );

  const candidateId = parseInt(props.match.params.id);
  const dispatch = useDispatch();
  const { allcandidateMentorDetails, loading } = useSelector(
    (state: RootState) => state.candidates
  );
  const { data } = allcandidateMentorDetails && allcandidateMentorDetails;
  console.log(allcandidateMentorDetails);
  useEffect(() => {
    dispatch(candidateMentorDetails({ params: { id: candidateId } }));
  }, [dispatch, candidateId]);

  const Today = new Date();
  const StringToday = Today.toDateString();

    return loading ? (
    <LoadingSpinner margin={400} />
  ) : (
    <Container>
      <HeaderContainer>
        <LeftHeader>
          <Text style={{ fontSize: "28px", fontWeight: 600, color: "#434343" }}>
            Candidate Details
          </Text>
          <Text style={{ color: "#434343" }}>{StringToday}</Text>
        </LeftHeader>
        <RightHeader />
      </HeaderContainer>
      <BodyContainer>
        <HeaderContainer>
          <RowContainer>
            <CandidateNameStyle>
              {data && data.candidateName}
            </CandidateNameStyle>
            <TotalSupportHourBox>
              <ProjectHourlogIcon />
              <h5
                style={{
                  color: "white",
                  margin: "1px 0 0 8px",
                  fontSize: "14px",
                }}
              >
                {data && data.supportHours + " Hrs"}
              </h5>
            </TotalSupportHourBox>
          </RowContainer>
          <RowContainer>
            <DropdownButton>
              <Dropdown overlay={technologyStackMenu}>
                <h5 style={{ color: "black" }}>
                  Technology
                  <span style={{ marginLeft: "15px" }}>
                    <DownOutlined style={{ color: "black" }} />
                  </span>
                </h5>
              </Dropdown>
            </DropdownButton>
            <DropdownButton>
              <Dropdown overlay={emptyDataPage}>
                <h5 style={{ color: "black" }}>
                  Project
                  <span style={{ marginLeft: "15px" }}>
                    <DownOutlined style={{ color: "black" }} />
                  </span>
                </h5>
              </Dropdown>
            </DropdownButton>
            <DropdownButton>
              <Dropdown overlay={sortingMenu}>
                <h5 style={{ color: "black" }}>
                  Newest
                  <span style={{ marginLeft: "15px" }}>
                    <DownOutlined style={{ color: "black" }} />
                  </span>
                </h5>
              </Dropdown>
            </DropdownButton>
          </RowContainer>
        </HeaderContainer>
        {data &&
          data.projectDetails?.map((items: any) => {
            return (
              <Col>
                <BodyHeader>
                  <RowContainer>
                    <UserOutlined style={{ fontSize: 32 }} />
                    <MentorNameStyle> {items.mentorName} </MentorNameStyle>
                    <HourLogBox>
                      <ProjectHourlogIcon />
                      <p style={{ margin: "0 0 0 8px", fontSize: "14px" }}>
                        {items.totalHours + " Hrs"}
                      </p>
                    </HourLogBox>
                  </RowContainer>

                  {moment(items.projectStartDate).format("MMMM Do YYYY")}
                </BodyHeader>
                <BodyMainContent>
                  <CardBox>
                    {/* <p style={{ color: "#AB54DB" }}>projectName</p> */}
                    <p>{items.projectDescription}</p>
                    <Row style={{ display: "flex" }}>
                      {items.technologyStacks?.length <= 0
                        ? items.technologyStacks
                        : items.technologyStacks.map(
                            (tech: any, index: number) => {
                              return (
                                <TechnologyStackBox>
                                  <TechnologyStackIcon />
                                  <p
                                    style={{
                                      margin: "0 0 0 8px",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {tech}
                                  </p>
                                </TechnologyStackBox>
                              );
                            }
                          )}
                    </Row>
                  </CardBox>
                </BodyMainContent>
              </Col>
            );
          })}
      </BodyContainer>
    </Container>
  );
};

export default CandidateDetails;
