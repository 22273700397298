import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects, deleteProject } from "../../redux/actions/projectAction";
import { RootState } from "../../redux/store";
import styled from "@emotion/styled";
import { Button, Input, Modal, Typography, Table, Popconfirm, Popover, notification,Row,Col, Badge } from "antd";
import ProjectForm from "./components/ProjectForm";
import RightHeader from "../../components/RightHeader";
import jwtDecode from "jwt-decode";
import CustomPagination from "../../components/CustomPagination";
import CustomDrawer from "../../components/CustomDrawer";
import { DeleteOutlined, FormOutlined, createFromIconfontCN } from "@ant-design/icons";
import StatusChange from "./components/StatusChange";
import Description from "./components/Description";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2604097_58tg0wbo7wd.js",
});

const { Text } = Typography;
const { Search } = Input;
const Container: any = styled.div`
  padding: 25px 50px 25px 50px;
`;
const HeaderContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BodyContainer: any = styled.div`
  margin-top: 35px;
  padding: 15px 0px 0px 0px;
`;
const LeftHeader: any = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledSearch = styled(Search)`
  .ant-input-wrapper {
    box-shadow: 0px 8px 10px #008ce914;
    width: 280px;
    height: 30px;
    border-radius: 6px;
  }
  .ant-input {
    border: 1px solid #008ce940;
    border-radius: 6px;
    padding-top: 6px;
    color: #434343;
    height: 40px;
  }
  .ant-btn,
  .ant-btn-icon-only,
  .ant-input-search-button {
    border: none;
    box-shadow: none;
    margin-left: 2px;
  }

  .ant-input-group-addon {
    border: 1px solid #008ce940;
    border-left: none;
    border-radius: 6px;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #008ce940 !important ;
    border-left: none !important;
    background-color: white !important;
  }
  .ant-input-group input::placeholder {
    color: #434343;
    opacity: 0.7;
  }
`;
const AddUserButton = styled(Button)`
  background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 8px 10px #008ce914;
  border-radius: 6px;
  height: 40px;
  width: 175px;
  border: none;
  color: white;
  &:hover {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close-x {
    color: white;
  }
`;

const DeleteContainer = styled(DeleteOutlined)`
  background: #ffe9e9 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 7px #ff00000d;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000;
  cursor: pointer;
`;

const StyledTable = styled(Table)`
  .ant-table-row,
  .ant-table-row-level-0 {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000008;
    // border: 1px solid #008ce933;
  }
  .ant-table-thead > tr > th {
    background: #f9fcff 0% 0% no-repeat padding-box;
    font-size: 14px;
  }
  .ant-table-tbody > tr > td {
    // border-bottom: 1px solid #008ce930;
  }
  .ant-table-row {
    box-shadow: 1px 1px 10px #1c3faa1a !important;
  }
  .ant-table-tbody > tr:last-child {
    & > td {
      border-bottom: none;
    }
  }
`;
const EditContainer = styled(FormOutlined)`
  background: #e3f4ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 18px #008ce912;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #008ce9;
`;
const DescriptionWrapper = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #eff9ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ButtonWrapper = styled(Row)` 
  margin-top:20px;
  .activeBtn {
   background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
  color: white;
  }`;

const StyledButton = styled(Button)`
  background: #80808066;
  box-shadow: 0px 8px 10px #008ce914;
  border-radius: 6px;
  width: 100px;
  height: 40px;`;



function Project() {
    const [visible, setVisible] = useState(false);
    const [isActive, setIsActive] = useState(true);
  const [searchData, setSearchData] = useState<any>("");
  const [editData, setEditdata] = useState(null);
  const dispatch = useDispatch();
  const { projects, loading } = useSelector((state: RootState) => state.projects);
  const [isViewMore, setViewMore] = useState(false);

  let token: any = localStorage.getItem("token");
  const user: any = jwtDecode(token);

  const onChangeSearch = (e: any) => {
    setSearchData(e.target.value);
  };

  const handleTableChange = (current: any, pageSize: any) => {
    dispatch(
        getAllProjects({
            params: { PageNumber: current, PageSize: pageSize, Name: searchData, isActive: isActive },
      })
    );
  };

  const onSearch = (value: any) => {
    dispatch(getAllProjects({ params: { PageNumber: 1, PageSize: 10, Name: value, isActive:isActive } }));
  };

    useEffect(() => {
        dispatch(getAllProjects({ params: { PageNumber: 1, PageSize: 10, isActive: isActive } }));
  }, [dispatch]);

  useEffect(() => {
    if (searchData === "") {
      dispatch(
        getAllProjects({
          params: {
            PageNumber: (projects.data && projects.data.pageNumber) || 1,
                PageSize: (projects.data && projects.data.pageSize) || 10,
                isActive: isActive
          },
        })
      );
    }
  }, [searchData]);

    useEffect(() => {
        dispatch(
            getAllProjects({
                params: {
                    PageNumber: 1,
                    PageSize: 10,
                    Name: searchData,
                    isActive: isActive
                },
            })
        );
    }, [isActive]);

  const handleEditProject = (data: any) => {
    setVisible(true);
    setEditdata(data);
  };

  const handleVisibleChange = () => {
    setViewMore(false);
  };

  const columns = [
    {
      title: "Mentor",
      dataIndex: "mentorName",
      key: "name",
    },
    {
      title: "Candidate",
      dataIndex: "candidateName",
      key: "name",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (item: any) => (
        <React.Fragment>{item ? new Date(item).toLocaleDateString() : ""}</React.Fragment>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (item: any) => (
        <React.Fragment>{item ? new Date(item).toLocaleDateString() : ""}</React.Fragment>
      ),
    },
    { title: "Total Hours", dataIndex: "totalHours", key: "totalHours" },
    { title: "Rates", dataIndex: "rate", key: "rate" },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description: any) => (
        <Popover
          overlayClassName="projectPopover"
          placement="bottom"
          title=""
          content={
            <div style={{ width: "350px" }}>
              <Description description={description} />
            </div>
          }
          trigger="click"
          onVisibleChange={handleVisibleChange}
        >
          <DescriptionWrapper>
            <IconFont type="icon-email" style={{ color: "#059BFF", fontSize: "18px" }} />
          </DescriptionWrapper>
        </Popover>
      ),
    },
    {
      title: "Invoice Amount",
      dataIndex: "id",
      key: "id",
      render: (id: any, record: any) => <div>${record.rate * record.totalHours}</div>,
    },
    {
      title: "Status",
      dataIndex: "projectStatus",
      key: "x",
      render: (projectStatus: any, record: any) => (
        <>
          <StatusChange item={projectStatus} record={record} searchData={searchData} isActive={isActive}/>
        </>
      ),
    },
    user.roles === "Super Admin"
      ? {}
      : {
          title: "Action",
          dataIndex: "id",
          key: "id",
          render: (item: any, record: any) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <EditContainer onClick={() => handleEditProject(record)}>
                <FormOutlined style={{ color: "red" }} />
              </EditContainer>
              <Popconfirm
                title="Are you sure to delete this project?"
                onConfirm={() => onConfirm(item)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteContainer>
                  <DeleteOutlined style={{ color: "blue" }} />
                </DeleteContainer>
              </Popconfirm>
            </div>
          ),
        },
  ];

  function onConfirm(id: any) {
    const isLastElement = projects.data.results.length === 1;
    if (isLastElement && projects.data.totalCount === 1) {
      setSearchData("");
    }
    dispatch(
      deleteProject({
        params: { id },
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "Project has been successfully deleted.",
            duration: 2,
          });
          dispatch(
            getAllProjects({
              params: {
                PageNumber:
                  isLastElement && projects.data.totalCount > 1
                    ? projects.data.pageNumber - 1
                    : projects.data.pageNumber,
                PageSize: projects.data.pageSize,
                    Name: projects.data.totalCount === 1 ? null : searchData,
                    isActive: isActive
              },
            })
          );
        },
      })
    );
  }

  const Today = new Date();
  const StringToday = Today.toDateString();

  return (
    <Container>
      <HeaderContainer>
        <LeftHeader>
          <Text style={{ fontSize: "28px", fontWeight: 600, color: "#434343" }}>Project</Text>
          <Text style={{ color: "#434343" }}>{StringToday}</Text>
        </LeftHeader>
        <RightHeader />
      </HeaderContainer>
      <BodyContainer>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <StyledSearch
            onSearch={onSearch}
            name="search"
            placeholder="Search by name"
            onChange={onChangeSearch}
          />
          {user.roles === "Super Admin" ? (
            <></>
          ) : (
            <AddUserButton
              style={{ paddingLeft: "18px" }}
              onClick={() => {
                setEditdata(null);
                setVisible(!visible);
              }}
            >
              + Add Project
            </AddUserButton>
          )}
              </div>

              <ButtonWrapper>
                  <Col>
                  <Badge count={projects && projects.data && projects.data.activeCount}>
                  <StyledButton
                          className={isActive ? "activeBtn" : " "}
                          onClick={() => setIsActive(true)}
                      >
                          Active
                      </StyledButton>
                  </Badge>
                     <Badge count={projects && projects.data && projects.data.inactiveCount}> 
                       <StyledButton
                          style={{ marginLeft: "18px" }}
                          className={!isActive ? "activeBtn" : " "}
                          onClick={() => setIsActive(false)}
                      >
                          Inactive
                          </StyledButton>
                      </Badge>
                     
                  </Col>
              </ButtonWrapper>

        <div
          style={{
            border: "1px solid #008ce930",
            boxShadow: "0px 20px 22px #008ce905",
            marginTop: "25px",
          }}
        >
          <StyledTable
            columns={columns}
            dataSource={projects && projects.data && projects.data.results}
            pagination={false}
            loading={loading}
          />
        </div>
        <CustomPagination
          size="small"
          total={projects && projects.data && projects.data.totalCount}
          current={projects && projects.data && projects.data.pageNumber}
          defaultPageSize={10}
          defaultCurrent={1}
          showSizeChanger={true}
          onChange={handleTableChange}
        />

        <CustomDrawer
          title=""
          placement="right"
          mask={false}
          onClose={() => setVisible(false)}
          visible={visible}
          width={470}
          destroyOnClose={true}
        >
                  <ProjectForm setVisible={setVisible} editData={editData} searchData={searchData} isActive={isActive}/>
        </CustomDrawer>
      </BodyContainer>
    </Container>
  );
}
export default Project;
