import React from "react";
import styled from "@emotion/styled";
import { Typography } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import moment from "moment";
import { createFromIconfontCN } from "@ant-design/icons";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2646627_en735g9v9b.js",
});

const { Text } = Typography;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const DateWrapper = styled.div`
  height: 28px;
  width: 125px;
  background-color: #dcf1ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MentorCard = (props: any) => {
  const { projectDetails, filterHourLogData, firstChild, setfirstChild, activeCard } = props;
  const startDate = moment(projectDetails.startDate).format("MM/DD/YY");
  const endDate = moment(projectDetails.endDate).format("MM/DD/YY");

  const today = new Date();
  const timesheetCreatedDate = new Date(projectDetails && projectDetails.createdDate);
  const difference = today.getTime() - timesheetCreatedDate.getTime();
  const differenceInDays = difference / (1000 * 60 * 60 * 24);

  const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    border: 1px solid #008ce930;
    border-radius: 4px;
    padding: 6px;
    background: ${activeCard === projectDetails.id || firstChild
      ? "transparent linear-gradient(114deg, #66C2FF 0%, #008CE9 100%) 0% 0% no-repeat padding-box"
      : "transparent linear-gradient(114deg, #FFFFFF 0%, #EAF7FF 100%) 0% 0% no-repeat padding-box"};
  `;

  const StyledText = styled(Text)`
    color: ${activeCard === projectDetails.id || firstChild ? "#ffffff" : "#434343"};
  `;
  return (
    <React.Fragment>
      <StyledCard
        onClick={() => {
          filterHourLogData(projectDetails.id);
          setfirstChild(undefined);
        }}
        style={{ cursor: "pointer" }}
      >
        <Wrapper>
          <StyledText style={{ fontSize: "19px", fontWeight: 500 }}>
            {projectDetails.name}
          </StyledText>
          <DateWrapper>
            <Text style={{ fontSize: "11px" }}>{startDate}</Text>
            <Text style={{ fontSize: "11px" }}>-{endDate}</Text>
          </DateWrapper>
        </Wrapper>
        <StyledText style={{ fontSize: "15px" }}>
          <IconFont type="icon-company" style={{ fontSize: "13px", paddingRight: "7px" }} />
          {projectDetails.company}
        </StyledText>
        <StyledText style={{ fontSize: "15px" }}>
          <ClockCircleOutlined style={{ fontSize: "13px", paddingRight: "7px" }} />
          {Math.round(differenceInDays)} {Math.round(differenceInDays) > 1 ? "days" : "day"} ago/
          {projectDetails.totalHours} hours
        </StyledText>
      </StyledCard>
    </React.Fragment>
  );
};
export default MentorCard;
