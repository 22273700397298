export const technologyStackTypes = {
  GETALL_TECHNOLOGYSTACKS: 'GETALL_TECHNOLOGYSTACKS',
  GETALL_TECHNOLOGYSTACKS_SUCCESS: 'GETALL_TECHNOLOGYSTACKS_SUCCESS',

  CREATE_TECHNOLOGYSTACK: 'CREATE_TECHNOLOGYSTACK',
  CREATE_TECHNOLOGYSTACK_SUCCESS: 'CREATE_TECHNOLOGYSTACK_SUCCESS',

  UPDATE_TECHNOLOGYSTACK: 'UPDATE_TECHNOLOGYSTACK',
  UPDATE_TECHNOLOGYSTACK_SUCCESS: 'UPDATE_TECHNOLOGYSTACK_SUCCESS',

  DELETE_TECHNOLOGYSTACK: 'DELETE_TECHNOLOGYSTACK',
  DELETE_TECHNOLOGYSTACK_SUCCESS: 'DELETE_TECHNOLOGYSTACK_SUCCESS',

  TECHNOLOGYSTACK_REQUEST_ERROR: 'TECHNOLOGYSTACK_REQUEST_ERROR',

};
