import types from "../types";

const initialState = {
  loading: false,
  mentors: [],
  error: null,
  banks: [],
  mentorIdForPayment: null,
};

const mentorReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.UPDATE_MENTOR:
    case types.DELETE_MENTOR:
    case types.CREATE_MENTOR:
    case types.GETALL_MENTORS:
    case types.CREATE_PAYMENT_DETAILS:
    case types.GETALL_BANKS:
    case types.UPDATE_PAYMENT_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case types.GETALL_MENTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        mentors: action.payload,
        error: null,
      };
    case types.GETALL_BANKS_SUCCESS:
      return {
        ...state,
        loading: false,
        banks: action.payload,
        error: null,
      };
    case types.CREATE_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        mentorIdForPayment: action.payload,
      };
    case types.CREATE_PAYMENTDETAILS_SUCCESS:
    case types.UPDATE_PAYMENTDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.DELETE_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.UPDATE_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.MENTOR_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default mentorReducer;
