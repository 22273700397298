import types from '../types';

const initialState = {
  loading: false,
  countries: [],
  error: null,
};

const countriesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GETALL_COUNTRIES:
      return {
        ...state,
        loading: true,
      };
    case types.GETALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload,
        error: null,
      };
    case types.COUNTRY_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default countriesReducer;
