import styled from "@emotion/styled";
import { Spin } from "antd";
import React from "react";

const StyledSpinner = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingSpinner = (props: any) => {
  const { margin } = props;
  return <StyledSpinner style={{ marginTop: `${margin}px` }} />;
};
export default LoadingSpinner;
