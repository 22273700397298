export const mentorHourLogs = {
    GETALL_MENTORHOURLOG: "GETALL_MENTORHOURLOG",
    GETALL_MENTORHOURLOG_SUCCESS: "GETALL_MENTORHOURLOG_SUCCESS",
  
    CREATE_HOURLOG: "CREATE_HOURLOG",
    CREATE_HOURLOG_SUCCESS: "CREATE_HOURLOG_SUCCESS",
  
    UPDATE_HOURLOG: "UPDATE_HOURLOG",
    UPDATE_HOURLOG_SUCCESS: "UPDATE_HOURLOG_SUCCESS",
  
    DELETE_HOURLOG: "DELETE_HOURLOG",
    DELETE_HOURLOG_SUCCESS: "DELETE_HOURLOG_SUCCESS",

    APPROVE_HOURLOG: "APPROVE_HOURLOG",
    APPROVE_HOURLOG_SUCCESS: "APPROVE_HOURLOG_SUCCESS",

    SAVE_HOURLOG: "SAVE_HOURLOG",
    SAVE_HOURLOG_SUCCESS: "SAVE_HOURLOG_SUCCESS",
  
    HOURLOG_REQUEST_ERROR: "HOURLOG_REQUEST_ERROR",
  };