import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCompanyDetail,
  getAllCompanyDetails,
  getAllCompanyUsers,
} from "../../redux/actions/companyAction";
import { RootState } from "../../redux/store";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Input, notification, Pagination, Popconfirm, Table, Typography } from "antd";
import RightHeader from "../../components/RightHeader";
import CompanyCandidates from "./components/CompanyCandidates";
import jwt from "jwt-decode";
import CustomPagination from "../../components/CustomPagination";
import CustomDrawer from "../../components/CustomDrawer";
import CreateForm from "./components/CreateForm";

const { Text } = Typography;
const { Search } = Input;

const Container: any = styled.div`
  padding: 25px 50px 25px 50px;
`;
const HeaderContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BodyContainer: any = styled.div`
  margin-top: 35px;
  padding: 15px 0px 0px 0px;
`;
const LeftHeader: any = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSearch = styled(Search)`
  .ant-input-wrapper {
    box-shadow: 0px 8px 10px #008ce914;
    width: 280px;
    height: 30px;
    border-radius: 6px;
  }
  .ant-input {
    border: 1px solid #008ce940;
    border-radius: 6px;
    padding-top: 6px;
    color: #434343;
    height: 40px;
  }
  .ant-btn,
  .ant-btn-icon-only,
  .ant-input-search-button {
    border: none;
    box-shadow: none;
    margin-left: 2px;
  }

  .ant-input-group-addon {
    border: 1px solid #008ce940;
    border-left: none;
    border-radius: 6px;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #008ce940 !important ;
    border-left: none !important;
    background-color: white !important;
  }
  .ant-input-group input::placeholder {
    color: #434343;
    opacity: 0.7;
  }
`;
const AddCompanyButton = styled(Button)`
  background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 8px 10px #008ce914;
  border-radius: 6px;
  height: 40px;
  width: 175px;
  border: none;
  color: white;
  &:hover {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(100deg, #44b5ff 0%, #0099ff 100%) 0% 0% no-repeat
      padding-box;
    color: white;
  }
`;
const EditContainer = styled(FormOutlined)`
  background: #e3f4ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 18px #008ce912;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #008ce9;
`;
const DeleteContainer = styled(DeleteOutlined)`
  background: #ffe9e9 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 7px #ff00000d;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000;
`;

const StyledTable = styled(Table)`
  .ant-table-row,
  .ant-table-row-level-0 {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000008;
    // border: 1px solid #008ce933;
  }
  .ant-table-thead > tr > th {
    background: #f9fcff 0% 0% no-repeat padding-box;
    font-size: 16px;
  }
  .ant-table-tbody > tr > td {
    // border-bottom: 1px solid #008ce930;
  }
  .ant-table-row {
    box-shadow: 1px 1px 10px #1c3faa1a !important;
  }
  .ant-table-tbody > tr:last-child {
    & > td {
      border-bottom: none;
    }
  }
`;

const TableWrapper = styled.div`
  border: 1px solid #008ce930;
  box-shadow: 0px 20px 22px #008ce905;
  margin-top: 25px;
`;

function Company() {
  const [companyDetail, setCompanyDetail] = useState({});
  const [isCompanyCandidates, setisCompanyCandidates] = useState<boolean>(false);
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [editItem, setEditItem] = useState(null);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [searchItem, setSearchItem] = useState("");
  const { allCompanies, loading } = useSelector((state: RootState) => state.companies);
  let token: any = localStorage.getItem("token");
  const user: any = jwt(token);
  const newUser = user.roles.split(" ").join("").toUpperCase();

  useEffect(() => {
    dispatch(getAllCompanyDetails({ params: { role: newUser, PageNumber: 1, pageSize: 10 } }));
  }, [dispatch]);

  useEffect(() => {
    if (searchItem === "") {
      dispatch(getAllCompanyDetails({ params: { role: newUser, PageNumber: 1, pageSize: 10 } }));
    }
  }, [searchItem]);

  const editCompanyDetails = (item: any) => {
    setVisible(true);
    setEditItem(item);
  };

  const handleDeleteCompanyDetails = (id: any) => {
    const isLastElement = allCompanies.data.results.length === 1;
    if (isLastElement && allCompanies.data.totalCount === 1) {
      setSearchItem("");
    }
    dispatch(
      deleteCompanyDetail({
        params: { id: id },
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "Company has been successfully deleted.",
            duration: 2,
          });
          dispatch(
            getAllCompanyDetails({
              params: {
                role: newUser,
                PageNumber:
                  isLastElement && allCompanies.data.totalCount > 1
                    ? allCompanies.data.pageNumber - 1
                    : allCompanies.data.pageNumber,
                pageSize: allCompanies.data.pageSize,
                CompanyName: allCompanies.data.totalCount === 1 ? null : searchItem,
              },
            })
          );
        },
        onFailure: () => {},
      })
    );
  };

  const handleTableChange = (current: any, pageSize: any) => {
    dispatch(
      getAllCompanyDetails({
        params: { role: newUser, PageNumber: current, pageSize: pageSize, CompanyName: searchItem },
      })
    );
  };

  const onSearch = (value: any) => {
    dispatch(
      getAllCompanyDetails({
        params: { role: newUser, PageNumber: 1, pageSize: 10, CompanyName: value },
      })
    );
  };

  const onChangeSearch = (e: any) => {
    setSearchItem(e.target.value);
  };

  const handleClickCompany = (record: any) => {
    setisCompanyCandidates(true);
    setCompanyDetail(record);
  };

  const columns = [
    {
      title: "Company",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <div
          onClick={() => handleClickCompany(record)}
          style={{ cursor: "pointer", color: "#169AF4" }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Country",
      dataIndex: "countryInfo",
      key: "countryInfo",
      render: (item: any) => item.name && <span>{item.name}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    user.roles === "Super Admin"
      ? {}
      : {
          title: "Action",
          dataIndex: "id",
          key: "id",
          render: (item: any, record: any) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <EditContainer onClick={() => editCompanyDetails(record)}>
                <FormOutlined />
              </EditContainer>
              <Popconfirm
                title="Are you sure to delete this Company Detail?"
                onConfirm={() => handleDeleteCompanyDetails(item)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteContainer>
                  <DeleteOutlined />
                </DeleteContainer>
              </Popconfirm>
            </div>
          ),
        },
  ];

  const Today = new Date();
  const StringToday = Today.toDateString();

  return (
    <>
      {isCompanyCandidates ? (
        <CompanyCandidates
          setisCompanyCandidates={setisCompanyCandidates}
          role={newUser}
          companyDetail={companyDetail}
        />
      ) : (
        <Container>
          <HeaderContainer>
            <LeftHeader>
              <Text style={{ fontSize: "28px", fontWeight: 600, color: "#434343" }}>Company</Text>
              <Text style={{ color: "#434343" }}>{StringToday}</Text>
            </LeftHeader>
            <RightHeader />
          </HeaderContainer>
          <BodyContainer>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <StyledSearch
                placeholder="Search by company name"
                onChange={onChangeSearch}
                onSearch={onSearch}
              />
              {user.roles === "Super Admin" ? (
                <></>
              ) : (
                <AddCompanyButton
                  style={{ paddingLeft: "18px" }}
                  onClick={() => {
                    setVisible(!visible);
                    setEditItem(null);
                  }}
                >
                  + Add Company
                </AddCompanyButton>
              )}
            </div>
            <TableWrapper>
              <StyledTable
                columns={columns}
                dataSource={
                  allCompanies &&
                  allCompanies.data &&
                  allCompanies.data.results &&
                  allCompanies.data.results
                }
                pagination={false}
                loading={loading}
              />
            </TableWrapper>
            <CustomPagination
              size="small"
              total={allCompanies && allCompanies.data && allCompanies.data.totalCount}
              current={allCompanies && allCompanies.data && allCompanies.data.pageNumber}
              defaultPageSize={10}
              defaultCurrent={1}
              showSizeChanger={true}
              onChange={handleTableChange}
            />
            <CustomDrawer
              title=""
              placement="right"
              mask={false}
              onClose={() => {
                setVisible(false);
                setDisabled(true);
              }}
              visible={visible}
              width={470}
              destroyOnClose={true}
            >
              <CreateForm
                editItem={editItem}
                setVisible={setVisible}
                allCompanies={allCompanies}
                searchItem={searchItem}
                isDisabled={isDisabled}
                setDisabled={setDisabled}
              />
            </CustomDrawer>
          </BodyContainer>
        </Container>
      )}
    </>
  );
}
export default Company;
