import React from "react";
import styled from "@emotion/styled";
import { Button, Col, Form, Input, Row, Typography, Upload, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  createTechnologyStackDetail,
  editTechnologyStackDetail,
  getAllTechnologyStackDetails,
} from "../../../redux/actions/technologyStackAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const Container = styled.div`
  background: transparent linear-gradient(98deg, #3bb1ff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  width: 465px;
  height: 94px;
`;
const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 22px 22px 35px;
`;
const ModalBody = styled.div`
  padding: 35px;
  background-color: white;
`;

const AddButton = styled(Button)`
  background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 3px;
  color: white;
  width: 100%;
  margin-top: 30px;
  &:hover {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat;
    color: white;
  }
  &:focus {
    background: transparent linear-gradient(94deg, #36afff 0%, #0099ff 100%) 0% 0% no-repeat;
    color: white;
  }
`;
const StyledInput = styled(Input)`
  color: #434343;
  font-size: 13px;
`;
const { Text } = Typography;

interface IAddTechnologyStackProps {
  editItem: any;
  setVisible: (visible: boolean) => void;
  searchItem: any;
}
function AddTechnologyStack(props: IAddTechnologyStackProps) {
  const { editItem, setVisible, searchItem } = props;
  const [form] = useForm();
  const dispatch = useDispatch();
  const { allTechnologyStacks, loading } = useSelector(
    (state: RootState) => state.technologyStacks
  );

  const handleAddSubmit = (value: any) => {
    const data = value;
    data["createrId"] = localStorage.getItem("current");
    dispatch(
      createTechnologyStackDetail({
        data,
        onSuccess: () => {
          setVisible(false);
          notification.success({
            message: "Success",
            description: "Technologystack has been successfully created.",
            duration: 2,
          });
          dispatch(
            getAllTechnologyStackDetails({
              params: {
                PageNumber: allTechnologyStacks.data.pageNumber,
                PageSize: allTechnologyStacks.data.pageSize,
              },
            })
          );
        },
        onFailure: () => {},
      })
    );
  };

  const handleEditSubmit = (value: any) => {
    const data = value;
    data.id = editItem.id;
    data["createrId"] = localStorage.getItem("current");
    dispatch(
      editTechnologyStackDetail({
        data,
        onSuccess: () => {
          setVisible(false);
          notification.success({
            message: "Success",
            description: "Technologystack has been successfully updated.",
            duration: 2,
          });
          dispatch(
            getAllTechnologyStackDetails({
              params: {
                PageNumber: allTechnologyStacks.data.pageNumber,
                PageSize: allTechnologyStacks.data.pageSize,
                TechStackName: searchItem,
              },
            })
          );
        },
        onFailure: () => {},
      })
    );
  };
  return (
    <Container>
      <ModalHeader>
        <Text style={{ color: "white", fontWeight: 300, fontSize: "13px" }}>
          {editItem ? "Edit TechnologyStack" : "Add TechnologyStack"}
        </Text>
        <Text style={{ color: "white", fontWeight: 300, marginTop: "5px", fontSize: "16px" }}>
          Enter TechnologyStack details below
        </Text>
      </ModalHeader>
      <ModalBody>
        <Form
          name="basic"
          form={form}
          onFinish={editItem ? handleEditSubmit : handleAddSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 4]}>
            <Col className="gutter-row" span={24}>
              <Form.Item name="createrId" hidden>
                <StyledInput placeholder="Id" />
              </Form.Item>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please input name!" }]}
                initialValue={editItem ? editItem.name : ""}
                label="TechnologyStack Name"
              >
                <StyledInput placeholder="TechnologyStack Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="description"
                initialValue={editItem ? editItem.description : ""}
                rules={[
                  {
                    pattern: /^.{0,1000}$/,
                    message: "Description cannot contain more than 1000 characters",
                  },
                ]}
                label="Description"
              >
                <Input.TextArea placeholder="Description" rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <AddButton htmlType="submit">{editItem ? "UPDATE" : "CREATE"}</AddButton>
        </Form>
      </ModalBody>
    </Container>
  );
}
export default AddTechnologyStack;
