import React, { useEffect, useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Avatar, Breadcrumb, Col, Input, Row, Table, Typography } from "antd";

import StyledCard from "../../../components/card/index";
import CompanyVector from "../../../image/company-avatar.jpg";
import RightHeader from "../../../components/RightHeader";
import { getAllCompanyUsers } from "../../../redux/actions/companyAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CustomPagination from "../../../components/CustomPagination";

const { Text } = Typography;
const { Search } = Input;
const Container: any = styled.div`
  padding: 25px 60px 25px 60px;
`;
const HeaderContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BodyContainer: any = styled.div`
  margin-top: 35px;
  padding: 15px 0px 0px 0px;
`;
const LeftHeader: any = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSearch = styled(Search)`
  .ant-input-wrapper {
    box-shadow: 0px 8px 10px #008ce91a;
    width: 280px;
    height: 30px;
    border-radius: 6px;
  }
  .ant-input {
    border: 1px solid #008ce940;
    border-radius: 6px;
    padding-top: 6px;
    color: #434343;
    height: 40px;
  }
  .ant-btn,
  .ant-btn-icon-only,
  .ant-input-search-button {
    border: none;
    box-shadow: none;
  }

  .ant-input-group-addon {
    border: 1px solid #008ce940;
    border-left: none;
    border-radius: 6px;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #008ce940 !important ;
    border-left: none !important;
    background-color: white !important;
  }
  .ant-input-group input::placeholder {
    color: #434343;
    opacity: 0.7;
  }
`;

const StyledTable = styled(Table)`
  .ant-table-row,
  .ant-table-row-level-0 {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000008;
    border: 1px solid #008ce933;
  }
  .ant-table-thead > tr > th {
    background: #f9fcff 0% 0% no-repeat padding-box;
    font-size: 16px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #008ce930;
  }
  .ant-table-row {
    box-shadow: 1px 1px 10px #1c3faa1a !important;
  }
  .ant-table-tbody > tr:last-child {
    & > td {
      border-bottom: none;
    }
  }
`;
const CompanyProfileCard = styled(StyledCard)`
  height: 520px;
  background: transparent linear-gradient(143deg, #ffffff 0%, #eaf7ff 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 20px 22px #008ce90a;
  border: 1px solid #008ce94d;
`;
const CompanyProfileContainer = styled.div`
  height: 520px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;
const LeftOutlinedContainer = styled.div`
  font-size: 11px;
  padding-right: 3px;
  cursor: pointer;
`;
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SearchPlusPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const OuterImageConatiner = styled.div`
  height: 210px;
  width: 210px;
  border-radius: 50%;
  border: 3px dashed #3cb1ffbf;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InnerImageContainer = styled.div`
  height: 190px;
  width: 190px;
  border-radius: 50%;
  border: 3px solid #3cb1ffbf;
`;

interface CompanyCandidateProps {
  setisCompanyCandidates: any;
  companyDetail: any;
  role: any;
}

function CompanyCandidates(props: CompanyCandidateProps) {
  const { setisCompanyCandidates, companyDetail, role } = props;
  const [searchItem, setSearchItem] = useState("");

  const { loading, companyUsers } = useSelector((state: RootState) => state.companies);
  const dispatch = useDispatch();

  const handleTableChange = (current: any, pageSize: any) => {
    dispatch(
      getAllCompanyUsers({
        params: {
          role: role,
          PageNumber: current,
          pageSize: pageSize,
          UserName: searchItem,
          CompanyId: companyDetail.id,
        },
      })
    );
    setSearchItem("");
  };

  const onSearch = (value: any) => {
    dispatch(
      getAllCompanyUsers({
        params: { PageNumber: 1, pageSize: 8, CompanyId: companyDetail.id, UserName: value },
      })
    );
  };

  const onChangeSearch = (e: any) => {
    setSearchItem(e.target.value);
  };

  useEffect(() => {
    dispatch(
      getAllCompanyUsers({ params: { PageNumber: 1, pageSize: 8, CompanyId: companyDetail.id } })
    );
  }, [dispatch]);

  useEffect(() => {
    if (searchItem === "") {
      dispatch(
        getAllCompanyUsers({ params: { PageNumber: 1, pageSize: 8, CompanyId: companyDetail.id } })
      );
    }
  }, [searchItem]);

  const columns = [
    {
      title: "User Name",
      dataIndex: "firstName",
      key: "name",
      render: (item: any, record: any) => (
        <span>
          {item} {record.lastName}
        </span>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
  ];
  return (
    <Container>
      <HeaderContainer>
        <LeftHeader>
          <Text style={{ fontSize: "30px", fontWeight: 600, color: "#434343" }}>Company</Text>
          <Text style={{ color: "#434343" }}>Monday,June16,2020</Text>
        </LeftHeader>
        <RightHeader />
      </HeaderContainer>
      <BodyContainer>
        <BreadcrumbContainer>
          <LeftOutlinedContainer onClick={() => setisCompanyCandidates(false)}>
            <LeftOutlined />
          </LeftOutlinedContainer>
          <Breadcrumb>
            <span onClick={() => setisCompanyCandidates(false)} style={{ cursor: "pointer" }}>
              <Breadcrumb.Item>Company</Breadcrumb.Item>
            </span>
            <span style={{ color: "#008CE9" }}>
              <Breadcrumb.Item>{companyDetail.name}</Breadcrumb.Item>
            </span>
          </Breadcrumb>
        </BreadcrumbContainer>
        <Col xl={16} xxl={18}>
          <SearchPlusPaginationWrapper>
            <SearchWrapper>
              <StyledSearch
                placeholder="Search by user"
                onChange={onChangeSearch}
                onSearch={onSearch}
              />
            </SearchWrapper>
          </SearchPlusPaginationWrapper>
        </Col>
        <Row gutter={32} style={{ marginTop: "25px" }}>
          <Col xl={16} xxl={18}>
            <div
              style={{
                background: "#ffffff",
                boxShadow: "0px 20px 22px #008ce905",
                border: "1px solid #008ce930",
              }}
            >
              <StyledTable
                columns={columns}
                dataSource={
                  companyUsers.data && companyUsers.data.results && companyUsers.data.results
                }
                pagination={false}
                loading={loading}
              />
            </div>
            <CustomPagination
              size="small"
              total={companyUsers && companyUsers.data && companyUsers.data.totalCount}
              current={companyUsers && companyUsers.data && companyUsers.data.pageNumber}
              defaultPageSize={8}
              defaultCurrent={1}
              showSizeChanger={false}
              onChange={handleTableChange}
            />
          </Col>
          <Col xl={8} xxl={6}>
            <CompanyProfileCard>
              <CompanyProfileContainer>
                <OuterImageConatiner>
                  <InnerImageContainer>
                    <Avatar src={CompanyVector} size={184} />
                  </InnerImageContainer>
                </OuterImageConatiner>
                <Text
                  style={{
                    color: "#363636",
                    fontSize: "18px",
                    letterSpacing: "0.18px",
                    fontWeight: 600,
                    paddingTop: "15px",
                  }}
                >
                  {companyDetail.name}
                </Text>
                <Text
                  style={{
                    color: "#008CE9",
                    fontSize: "12px",
                    letterSpacing: "0.12px",
                    paddingTop: "9px",
                  }}
                >
                  {companyDetail.country}
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#3636363",
                    letterSpacing: "0.14px",
                    paddingTop: "9px",
                  }}
                >
                  {companyDetail.phone}
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#3636363",
                    letterSpacing: "0.14px",
                    paddingTop: "9px",
                  }}
                >
                  {companyDetail.email}
                </Text>
              </CompanyProfileContainer>
            </CompanyProfileCard>
          </Col>
        </Row>
      </BodyContainer>
    </Container>
  );
}
export default CompanyCandidates;
