import types from "../types";

export function getAllMentorDetails(data: any) {
  return {
    type: types.GETALL_MENTORS,
    payload: data,
  };
}

export function getAllMentorDetailsSuccess(allMentorsData: any) {
  return {
    type: types.GETALL_MENTORS_SUCCESS,
    payload: allMentorsData,
  };
}

export function getAllBankDetails(data: any) {
  return {
    type: types.GETALL_BANKS,
    payload: data,
  };
}

export function getAllBankDetailsSuccess(data: any) {
  return {
    type: types.GETALL_BANKS_SUCCESS,
    payload: data,
  };
}

export function createMentorDetail(data: any) {
  return {
    type: types.CREATE_MENTOR,
    payload: data,
  };
}

export function createMentorDetailSuccess(id: any) {
  return {
    type: types.CREATE_MENTOR_SUCCESS,
    payload: id,
  };
}

export function createMentorPaymentDetail(data: any) {
  return {
    type: types.CREATE_PAYMENT_DETAILS,
    payload: data,
  };
}

export function createMentorPaymentDetailSuccess() {
  return {
    type: types.CREATE_PAYMENTDETAILS_SUCCESS,
  };
}

export function updateMentorPaymentDetail(data: any) {
  return {
    type: types.UPDATE_PAYMENT_DETAILS,
    payload: data,
  };
}

export function updateMentorPaymentDetailSuccess() {
  return {
    type: types.UPDATE_PAYMENTDETAILS_SUCCESS,
  };
}

export function deleteMentorDetail(data: any) {
  return {
    type: types.DELETE_MENTOR,
    payload: data,
  };
}

export function deleteMentorDetailSuccess() {
  return {
    type: types.DELETE_MENTOR_SUCCESS,
  };
}

export function editMentorDetail(data: any) {
  return {
    type: types.UPDATE_MENTOR,
    payload: data,
  };
}

export function editMentorDetailSuccess() {
  return {
    type: types.UPDATE_MENTOR_SUCCESS,
  };
}

export function mentorDetailsError(data: any) {
  return {
    type: types.MENTOR_REQUEST_ERROR,
    payload: data,
  };
}

export function mentorStatusChange(data: any) {
  return {
    type: types.MENTOR_STATUS_CHANGE,
    payload: data,
  };
}

export function mentorStatusChangeSuccess() {
  return {
    type: types.MENTOR_STATUSCHANGE_SUCCESS,
  };
}
