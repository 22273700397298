export const invociesTypes = {
  GETALL_INVOICES: "GETALL_INVOICES",
  GETALL_INVOICES_SUCCESS: "GETALL_INVOICES_SUCCESS",

  GET_INVOICE_BYID: "GET_INVOICE_BYID",
  GET_INVOICEBYID_SUCCESS: "GET_INVOICEBYID_SUCCESS",

  GENERATE_INVOICE: "GENERATE_INVOICE",
  GENERATE_INVOICE_SUTECCESS: "GENERATE_INVOICE_SUTECCESS",

  INVOICE_REQUEST_ERROR: "INVOICE_REQUEST_ERROR",

  INVOICE_MARKASPAID_REQUEST: "INVOICE_MARKASPAID_REQUEST",
  INVOICE_MARKASPAID_SUCCESS: "INVOICE_MARKASPAID_SUCCESS",
  INVOICE_MARKASPAID_ERROR: "INVOICE_MARKASPAID_ERROR",
};
