import React, { createContext, useState } from "react";

export const ProjectHourLogContext: any = createContext(null);

const ContextProvider: React.FC = ({ children }) => {
  const [record, setRecord] = useState([]);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [searchValue, setSearchValue] = useState();

  return (
    <ProjectHourLogContext.Provider
      value={{
        record,
        setRecord,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        searchValue,
        setSearchValue,
      }}
    >
      {children}
    </ProjectHourLogContext.Provider>
  );
};
export default ContextProvider;
