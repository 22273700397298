import { combineReducers } from "redux";

import countriesReducer from "./countriesReducer";
import rolesReducer from "./rolesReducer";
import mentorReducer from "./mentorReducer";
import usersReducer from "./usersReducer";
import candidateReducer from "./candidateReducers";
import technologyStackReducer from "./technologySatckReducer";
import companyReducer from "./companyReducer";
import projectReducer from "./projectReducers";
import mentorHourLogsReducer from "./mentorHourLogsReducers";
import timesheetReducer from "./timesheetreducer";
import invoiceReducer from "./invoiceReducer";

const RootReducer = combineReducers({
  users: usersReducer,
  countries: countriesReducer,
  candidates: candidateReducer,
  companies: companyReducer,
  roles: rolesReducer,
  mentors: mentorReducer,
  technologyStacks: technologyStackReducer,
  projects: projectReducer,
  mentorHourLogs: mentorHourLogsReducer,
  timesheets: timesheetReducer,
  invoices: invoiceReducer,
});

export default RootReducer;
