import React from "react";
import styled from "@emotion/styled";
import { Col, Row, Typography } from "antd";

import BannerImage from "../image/croods.png";
import jwt from "jwt-decode";

const { Text } = Typography;

const StyledWelcome = styled(Row)`
  background: #2ab471;
  color: white;
  height: 225px;
  position: relative;
  overflow: hidden;
  background: transparent linear-gradient(97deg, #f0faff73 0%, #76bbff 100%) 0% 0% no-repeat
    padding-box;
  ::before {
    content: "";
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 150px;
    background: white;
    bottom: -79px;
    left: -69px;
    opacity: 0.19;
  }
  ::after {
    content: "";
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 150px;
    background: white;
    top: -79px;
    right: -69px;
    opacity: 0.19;
  }
`;
const StyledImage = styled("img")`
  position: absolute;
  bottom: 0;
  right: 70px;
  max-width: 100%;
  width: 338px;
  @media (max-width: 1600px) {
    width: 270px;
    bottom: 0;
    right: 0px;
  }

  @media (max-width: 1300px) {
    width: 200px;
    bottom: 10px;
  }
  @media (max-width: 1200px) {
    width: 270px;
    bottom: 0px;
  }
`;
const ParagraphText = styled(Text)`
  font-size: 17px;
  color: #2e2e2e;
  margin-bottom: 0px;
  display: block;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;
const MainText = styled(Text)`
  font-size: 27px;
  letter-spacing: 0.27px;
  color: #008ce9;
  margin-bottom: 0px;
  display: block;
  font-weight: 600;
  @media (max-width: 1600px) {
    font-size: 23px;
  }
`;

function WelcomeBanner() {
  let token: any = localStorage.getItem("token");
  const user: any = jwt(token);

  return (
    <div>
      <StyledWelcome justify="start" align="middle">
        <Col offset={2}>
          <MainText>Hi, {user.roles}</MainText>
          {/* <ParagraphText>Respond to All Request, You've 5 unread notification</ParagraphText> */}
          <ParagraphText>Have a great day</ParagraphText>
        </Col>
      </StyledWelcome>
      <StyledImage src={BannerImage} />
    </div>
  );
}

export default WelcomeBanner;
