import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Typography, Tabs } from "antd";
import AddMentor from "./AddMentor";
import AddBankingDetails from "./AddBankingDetails";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;

const Container = styled.div`
  background: transparent linear-gradient(98deg, #3bb1ff 0%, #0099ff 100%) 0% 0% no-repeat
    padding-box;
  width: 465px;
  height: 94px;
`;
const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 22px 22px 45px;
`;
const ModalBody = styled.div`
  padding: 40px;
  background-color: white;
`;

const { Text } = Typography;

interface ICreateFormProps {
  setVisible: (visible: boolean) => void;
  editItem: any;
  searchItem: any;
  allMentors: any;
  isDisabled: any;
    setDisabled: any;
    isActive: boolean;
}

function CreateForm(props: ICreateFormProps) {
    const { editItem, setVisible, searchItem, allMentors, isDisabled, setDisabled, isActive } = props;
  const { mentorIdForPayment } = useSelector((state: RootState) => state.mentors);
  const isAssociatedWithCompany =
    editItem != null
      ? editItem.isAssociatedWithCompany
      : mentorIdForPayment === null
      ? true
      : mentorIdForPayment.isAssociatedWithCompany;

  return (
    <Container>
      <ModalHeader>
        <Text style={{ color: "white", fontWeight: 300, fontSize: "13px" }}>
          {editItem ? "Edit Mentor" : "Add Mentor"}
        </Text>
        <Text style={{ color: "white", fontWeight: 300, marginTop: "5px", fontSize: "16px" }}>
          Enter mentor details below
        </Text>
      </ModalHeader>
      <ModalBody>
        <Tabs type="card">
          <TabPane tab="Personal Details" key="1">
            <AddMentor
                          editItem={editItem}
                          setVisible={setVisible}
                          searchItem={searchItem}
                          allMentors={allMentors}
                          setDisabled={setDisabled}
                          isActive={isActive }
            />
          </TabPane>
          <TabPane tab="Payment Details" key="2" disabled={isDisabled && isAssociatedWithCompany}>
            <AddBankingDetails
              allMentors={allMentors}
              editItem={editItem}
              setVisible={setVisible}
            />
          </TabPane>
        </Tabs>
      </ModalBody>
    </Container>
  );
}
export default CreateForm;
